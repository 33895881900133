"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-manage/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.dataManipulation = dataManipulation;
exports.getDeploy = getDeploy;
exports.getFlash = getFlash;
exports.getFlashAll = getFlashAll;
exports.getModel = getModel;
exports.getPurpose = getPurpose;
exports.getRepeal = getRepeal;
exports.getTask = getTask;
exports.getVersion = getVersion;
var _request = _interopRequireDefault(require("@/utils/request"));
//查询固件任务信息
function getTask(data) {
  return (0, _request.default)({
    url: 'system/business/firmware/task/query',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//查询版本列表
function getVersion(data) {
  return (0, _request.default)({
    url: 'system/business/firmware/info/version',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//根据固件版本号查询设备型号
function getModel(data) {
  return (0, _request.default)({
    url: 'system/business/firmware/equipment/query',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//根据设备型号查询设备用途
function getPurpose(equipmentModel) {
  return (0, _request.default)({
    url: 'system/business/stock/purpose/query/' + equipmentModel,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//发布
function getDeploy(data) {
  return (0, _request.default)({
    url: 'system/business/firmware/task/deploy',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//撤销
function getRepeal(id) {
  return (0, _request.default)({
    url: 'system/business/firmware/task/undo/' + id,
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//单刷
function getFlash(data) {
  return (0, _request.default)({
    url: 'system/business/firmware/task/flash',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//批刷
function getFlashAll(data) {
  return (0, _request.default)({
    url: 'system/business/firmware/task/flash/batch',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

/**
 * 前端刷新公共方法
 */
function dataManipulation(tableData, rowData, status, opTime, isHistory) {
  tableData.map(function (element) {
    //如果是单选的直接用if判断id是否相等就可以了,如果是复选框的就需要嵌套for循环判断!
    if (element.id == rowData.id) {
      if (status != null && status != "") {
        element.status = status;
      }
      element.revokeUser = window.localStorage.getItem("username");
      if (opTime != null && opTime != "") {
        element.revokeTime = opTime;
      }
      if (isHistory != null && isHistory != "") {
        element.isHistory = isHistory;
      }
    }
  });
  return tableData;
}