var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "firwarerepair" }, [
    _c("div", { staticClass: "firwarerepair-head" }, [
      _c("ul", [
        _c(
          "li",
          [
            _c("h5", [_vm._v("版本号")]),
            _vm._v(" "),
            _c("el-input", {
              model: {
                value: _vm.form.firmwareVersion,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "firmwareVersion", $$v)
                },
                expression: "form.firmwareVersion",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [
            _c("h5", [_vm._v("语言")]),
            _vm._v(" "),
            _c(
              "el-select",
              {
                attrs: { placeholder: "请选择" },
                on: {
                  focus: function ($event) {
                    return _vm.getLan()
                  },
                },
                model: {
                  value: _vm.form.lan,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "lan", $$v)
                  },
                  expression: "form.lan",
                },
              },
              _vm._l(_vm.lanData, function (item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.valueCn, value: item.value },
                })
              }),
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [
            _c("h5", [_vm._v("任务类型")]),
            _vm._v(" "),
            _c(
              "el-select",
              {
                attrs: { placeholder: "请选择" },
                model: {
                  value: _vm.form.taskType,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "taskType", $$v)
                  },
                  expression: "form.taskType",
                },
              },
              _vm._l(_vm.taskTypes, function (item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.valueCn, value: item.value },
                })
              }),
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [
            _c("h5", [_vm._v("系统")]),
            _vm._v(" "),
            _c(
              "el-select",
              {
                attrs: { placeholder: "请选择" },
                model: {
                  value: _vm.form.type,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "type", $$v)
                  },
                  expression: "form.type",
                },
              },
              _vm._l(_vm.system, function (item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.valueCn, value: item.value },
                })
              }),
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.search } },
              [_vm._v("查询")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.empty } },
              [_vm._v("清空")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "permit",
                    rawName: "v-permit",
                    value: "/firmware/fixPoint/add",
                    expression: "'/firmware/fixPoint/add'",
                  },
                ],
                attrs: { type: "primary" },
                on: { click: _vm.add },
              },
              [_vm._v("添加")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "permit",
                    rawName: "v-permit",
                    value: "/firmware/fixPoint/update/part",
                    expression: "'/firmware/fixPoint/update/part'",
                  },
                ],
                attrs: { type: "primary" },
                on: { click: _vm.updateVersion },
              },
              [_vm._v("批修")]
            ),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "tab" },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.tableData,
              border: "",
              "highlight-current-row": "",
            },
            on: {
              "current-change": _vm.currentChange,
              "row-click": _vm.getRow,
            },
          },
          [
            _c("el-table-column", {
              attrs: { type: "index", index: _vm.indexMethod },
            }),
            _vm._v(" "),
            _vm.show
              ? _c("el-table-column", { attrs: { prop: "id" } })
              : _vm._e(),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "type",
                formatter: _vm.types,
                label: "系统",
                width: "150px",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "firmwareVersion",
                label: "版本号",
                "show-overflow-tooltip": "",
                "min-width": "25%",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "taskType",
                width: "150px",
                "show-overflow-tooltip": "",
                formatter: _vm.istaskType,
                label: "任务类型",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "fixPoint",
                "show-overflow-tooltip": "",
                label: "修复点",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "lan",
                label: "语言",
                "show-overflow-tooltip": "",
                "min-width": "10%",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "opUser",
                label: "上传人员",
                "show-overflow-tooltip": "",
                "min-width": "10%",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "opTime",
                label: "上传时间",
                "show-overflow-tooltip": "",
                "min-width": "20%",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { label: "操作", "min-width": "10%" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "div",
                        { staticClass: "function-icon" },
                        [
                          _c(
                            "el-tooltip",
                            {
                              directives: [
                                {
                                  name: "permit",
                                  rawName: "v-permit",
                                  value: "/firmware/fixPoint/update",
                                  expression: "'/firmware/fixPoint/update'",
                                },
                              ],
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: "修改",
                                placement: "bottom",
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("../../assets/iconfont/amend.png"),
                                  alt: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.change(scope.row.id)
                                  },
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            {
                              directives: [
                                {
                                  name: "permit",
                                  rawName: "v-permit",
                                  value: "/firmware/fixPoint/delete",
                                  expression: "'/firmware/fixPoint/delete'",
                                },
                              ],
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: "删除",
                                placement: "bottom",
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("../../assets/iconfont/delete.png"),
                                  alt: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.dele(scope.row.id)
                                  },
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: {
              title: "批修",
              visible: _vm.dialogVersionVisible,
              "close-on-click-modal": false,
            },
            on: {
              "update:visible": function ($event) {
                _vm.dialogVersionVisible = $event
              },
              close: function ($event) {
                return _vm.cancel("versionForm")
              },
            },
          },
          [
            _c(
              "el-form",
              {
                ref: "versionForm",
                attrs: {
                  model: _vm.versionForm,
                  rules: _vm.updateVersionRule,
                  "status-icon": true,
                  "show-message": false,
                  "hide-required-asterisk": "",
                },
              },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "旧版本号",
                      "label-width": _vm.formLabelWidth,
                      prop: "oldFirmwareVersion",
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: { autocomplete: "off" },
                      model: {
                        value: _vm.versionForm.oldFirmwareVersion,
                        callback: function ($$v) {
                          _vm.$set(_vm.versionForm, "oldFirmwareVersion", $$v)
                        },
                        expression: "versionForm.oldFirmwareVersion",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "新版本号",
                      "label-width": _vm.formLabelWidth,
                      prop: "firmwareVersion",
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: { autocomplete: "off" },
                      model: {
                        value: _vm.versionForm.firmwareVersion,
                        callback: function ($$v) {
                          _vm.$set(_vm.versionForm, "firmwareVersion", $$v)
                        },
                        expression: "versionForm.firmwareVersion",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "任务类型",
                      "label-width": _vm.formLabelWidth,
                      prop: "taskType",
                    },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { placeholder: "请选择" },
                        model: {
                          value: _vm.versionForm.taskType,
                          callback: function ($$v) {
                            _vm.$set(_vm.versionForm, "taskType", $$v)
                          },
                          expression: "versionForm.taskType",
                        },
                      },
                      _vm._l(_vm.taskTypes, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.valueCn, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.cancel("versionForm")
                      },
                    },
                  },
                  [_vm._v("取 消")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", disabled: _vm.isVersionDisable },
                    on: { click: _vm.updateVersionSure },
                  },
                  [_vm._v("保存")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "page" },
      [
        _c("el-pagination", {
          attrs: {
            "hide-on-single-page": _vm.value,
            "current-page": _vm.currentPage4,
            "page-size": 10,
            layout: "total,prev, pager, next",
            total: _vm.total,
            background: "",
          },
          on: {
            "size-change": _vm.handleSizeChange,
            "current-change": _vm.handleCurrentChange,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }