var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "log" },
    [
      _c("div", { staticClass: "log-head" }, [
        _c("ul", [
          _c(
            "li",
            [
              _c("h5", [_vm._v("设备号")]),
              _vm._v(" "),
              _c("el-input", {
                model: {
                  value: _vm.param.equipmentNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.param, "equipmentNumber", $$v)
                  },
                  expression: "param.equipmentNumber",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("固件版本号")]),
              _vm._v(" "),
              _c("el-input", {
                model: {
                  value: _vm.param.firmwareVersion,
                  callback: function ($$v) {
                    _vm.$set(_vm.param, "firmwareVersion", $$v)
                  },
                  expression: "param.firmwareVersion",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("日志名")]),
              _vm._v(" "),
              _c("el-input", {
                model: {
                  value: _vm.param.logName,
                  callback: function ($$v) {
                    _vm.$set(_vm.param, "logName", $$v)
                  },
                  expression: "param.logName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("日志类型")]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.param.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.param, "type", $$v)
                    },
                    expression: "param.type",
                  },
                },
                _vm._l(_vm.options, function (item) {
                  return _c("el-option", {
                    key: item.type,
                    attrs: { label: item.label, value: item.type },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("下载标识")]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.param.isDownload,
                    callback: function ($$v) {
                      _vm.$set(_vm.param, "isDownload", $$v)
                    },
                    expression: "param.isDownload",
                  },
                },
                _vm._l(_vm.downloads, function (item) {
                  return _c("el-option", {
                    key: item.isDownload,
                    attrs: { label: item.label, value: item.isDownload },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("标识")]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.param.flag,
                    callback: function ($$v) {
                      _vm.$set(_vm.param, "flag", $$v)
                    },
                    expression: "param.flag",
                  },
                },
                _vm._l(_vm.flags, function (item) {
                  return _c("el-option", {
                    key: item.flag,
                    attrs: { label: item.label, value: item.flag },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("li", [
            _c("h5", [_vm._v("起始时间")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "block" },
              [
                _c("el-date-picker", {
                  attrs: { type: "date", "value-format": "yyyy-MM-dd" },
                  model: {
                    value: _vm.param.startDate,
                    callback: function ($$v) {
                      _vm.$set(_vm.param, "startDate", $$v)
                    },
                    expression: "param.startDate",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("h5", [_vm._v("截止时间")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "block" },
              [
                _c("el-date-picker", {
                  attrs: { type: "date", "value-format": "yyyy-MM-dd" },
                  model: {
                    value: _vm.param.endDate,
                    callback: function ($$v) {
                      _vm.$set(_vm.param, "endDate", $$v)
                    },
                    expression: "param.endDate",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "function" }, [
        _c("ol", [
          _c("li", { on: { click: _vm.search } }, [
            _c("img", {
              attrs: {
                src: require("../../assets/iconfont/find.png"),
                alt: "",
              },
            }),
            _vm._v(" "),
            _c("span", [_vm._v("查询")]),
          ]),
          _vm._v(" "),
          _c("li", { on: { click: _vm.empty } }, [
            _c("img", {
              attrs: {
                src: require("../../assets/iconfont/empty.png"),
                alt: "",
              },
            }),
            _vm._v(" "),
            _c("span", [_vm._v("清空")]),
          ]),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/equipment/log/delete",
                  expression: "'/equipment/log/delete'",
                },
              ],
              on: { click: _vm.delet },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/delete.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("删除")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/equipment/log/download",
                  expression: "'/equipment/log/download'",
                },
              ],
              on: { click: _vm.download },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/down.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("日志下载")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/equipment/log/remark",
                  expression: "'/equipment/log/remark'",
                },
              ],
              on: { click: _vm.remarks },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/remark.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("备注")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/equipment/log/passed",
                  expression: "'/equipment/log/passed'",
                },
              ],
              on: { click: _vm.review },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/approve.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("审阅")]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tab" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                border: "",
                "highlight-current-row": "",
              },
              on: { "row-click": _vm.getRow },
            },
            [
              _c("el-table-column", {
                attrs: { type: "index", index: _vm.indexMethod },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "equipmentNumber", label: "设备号" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "firmwareVersion",
                  label: "固件版本号",
                  width: "300px",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "logName", label: "日志名", width: "350px" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "type",
                  label: "日志类型",
                  formatter: _vm.typeFormatter,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "createTime", label: "创建时间" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "isDownload",
                  label: "下载标识",
                  formatter: _vm.isDownloadFormatter,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "flag",
                  label: "标识",
                  formatter: _vm.flagFormatter,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "备注" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row["remark"]
                          ? _c("el-button", {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-tickets",
                                circle: "",
                              },
                              on: { click: _vm.tickets },
                            })
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, prev, pager, next",
              "page-size": 10,
              total: _vm.total,
              "current-page": _vm.currentPage,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.changepage,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "备注",
            visible: _vm.dialogVisible1,
            width: "40%",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible1 = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                margin: "left",
                width: "400px",
                "margin-top": "-20px",
              },
            },
            [_c("el-tag", [_vm._v("日志名: " + _vm._s(_vm.delParam.logName))])],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-upload", {
                staticClass: "avatar-uploader",
                attrs: {
                  action: _vm.serverUrl,
                  name: "img",
                  headers: _vm.header,
                  "show-file-list": false,
                  "on-success": _vm.uploadSuccess,
                  "on-error": _vm.uploadError,
                  "before-upload": _vm.beforeUpload,
                },
              }),
              _vm._v(" "),
              _c("quill-editor", {
                ref: "myQuillEditor",
                attrs: { id: "ql-editor", options: _vm.editorOption },
                on: {
                  blur: function ($event) {
                    return _vm.onEditorBlur($event)
                  },
                  change: function ($event) {
                    return _vm.onEditorChange($event)
                  },
                },
                model: {
                  value: _vm.content,
                  callback: function ($$v) {
                    _vm.content = $$v
                  },
                  expression: "content",
                },
              }),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "text-align": "right" } },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogVisible1 = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", disabled: _vm.isDisable },
                      on: { click: _vm.save },
                    },
                    [_vm._v("保存")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "备注",
            visible: _vm.dialogVisible3,
            width: "40%",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible3 = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                margin: "left",
                width: "400px",
                "margin-top": "-20px",
              },
            },
            [_c("el-tag", [_vm._v("日志名: " + _vm._s(_vm.delParam.logName))])],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("quill-editor", {
                ref: "myQuillEditor",
                attrs: {
                  id: "ql-editor",
                  options: _vm.editorOption,
                  "show-word-limit": "",
                },
                on: {
                  focus: function ($event) {
                    return _vm.onEditorFocus($event)
                  },
                },
                model: {
                  value: _vm.delParam.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.delParam, "remark", $$v)
                  },
                  expression: "delParam.remark",
                },
              }),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "text-align": "right" } },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogVisible3 = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "审阅",
            visible: _vm.dialogVisible2,
            width: "40%",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible2 = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                margin: "left",
                width: "400px",
                "margin-top": "-20px",
              },
            },
            [_c("el-tag", [_vm._v("日志名: " + _vm._s(_vm.delParam.logName))])],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("quill-editor", {
                ref: "myQuillEditor",
                attrs: {
                  id: "ql-editor1",
                  options: _vm.editorOption,
                  "show-word-limit": "",
                },
                on: {
                  focus: function ($event) {
                    return _vm.onEditorFocus($event)
                  },
                },
                model: {
                  value: _vm.delParam.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.delParam, "remark", $$v)
                  },
                  expression: "delParam.remark",
                },
              }),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "text-align": "right" } },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogVisible2 = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", disabled: _vm.isDisable },
                      on: { click: _vm.trial },
                    },
                    [_vm._v("审阅")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }