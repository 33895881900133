var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "addapp" },
    [
      _c(
        "el-form",
        {
          ref: "data",
          attrs: {
            model: _vm.data,
            rules: _vm.rulesadd,
            "status-icon": true,
            "show-message": false,
          },
        },
        [
          _c(
            "el-form-item",
            {
              staticStyle: { "padding-right": "8px" },
              attrs: {
                label: "语言: ",
                "label-width": _vm.formLabelWidth,
                prop: "lan",
              },
            },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { placeholder: "请选择" },
                  on: {
                    focus: function ($event) {
                      return _vm.getLan()
                    },
                  },
                  model: {
                    value: _vm.data.lan,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "lan", $$v)
                    },
                    expression: "data.lan",
                  },
                },
                _vm._l(_vm.lanData, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.valueCn, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticStyle: { "padding-right": "4px", "padding-left": "4px" },
              attrs: {
                label: "应用名称: ",
                "label-width": _vm.formLabelWidth,
                prop: "appName",
              },
            },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { placeholder: "请选择" },
                  on: {
                    focus: function ($event) {
                      return _vm.getFirst()
                    },
                    change: _vm.handleChangeFirst,
                  },
                  model: {
                    value: _vm.data.appName,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "appName", $$v)
                    },
                    expression: "data.appName",
                  },
                },
                _vm._l(_vm.appNameData, function (item) {
                  return _c("el-option", {
                    key: item,
                    attrs: { label: item, value: item },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.data.appName != ""
            ? _c(
                "el-form-item",
                {
                  staticStyle: { "padding-left": "8px" },
                  attrs: {
                    label: "版本号: ",
                    "label-width": _vm.formLabelWidth,
                    prop: "appVersion",
                  },
                },
                [
                  _vm.data.appName != ""
                    ? _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请选择" },
                          on: {
                            focus: function ($event) {
                              return _vm.getSecond()
                            },
                          },
                          model: {
                            value: _vm.data.appVersion,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "appVersion", $$v)
                            },
                            expression: "data.appVersion",
                          },
                        },
                        _vm._l(_vm.appNameData, function (item) {
                          return _c("el-option", {
                            key: item,
                            attrs: { label: item, value: item },
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "updateEditor",
              attrs: { "label-width": _vm.formLabelWidth, prop: "fixPoint" },
            },
            [
              _c(
                "el-upload",
                {
                  staticClass: "avatar-uploader",
                  staticStyle: { display: "none" },
                  attrs: {
                    action: "",
                    "http-request": _vm.to_upload_img,
                    "show-file-list": false,
                  },
                },
                [
                  _vm._v("\n        >\n        "),
                  _c("i", {
                    staticClass: "el-icon-plus avatar-uploader-icon",
                    attrs: { id: "imgInput" },
                  }),
                ]
              ),
              _vm._v(" "),
              _c("quill-editor", {
                ref: "myQuillEditor",
                attrs: { options: _vm.editorOption, "show-word-limit": "" },
                model: {
                  value: _vm.data.fixPoint,
                  callback: function ($$v) {
                    _vm.$set(_vm.data, "fixPoint", $$v)
                  },
                  expression: "data.fixPoint",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "btn" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", disabled: _vm.isDisable },
              on: { click: _vm.submit },
            },
            [_vm._v("提交")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }