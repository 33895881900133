var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "issue" },
    [
      _c("div", { staticClass: "issue-head" }, [
        _c("ul", [
          _c(
            "li",
            [
              _c("h5", [_vm._v("设备型号")]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.equipmentModel,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "equipmentModel", $$v)
                    },
                    expression: "form.equipmentModel",
                  },
                },
                _vm._l(_vm.type, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.valueCn, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("固件版本号")]),
              _vm._v(" "),
              _c("el-input", {
                model: {
                  value: _vm.form.firmwareVersion,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "firmwareVersion", $$v)
                  },
                  expression: "form.firmwareVersion",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("历史")]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.isHistory,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "isHistory", $$v)
                    },
                    expression: "form.isHistory",
                  },
                },
                _vm._l(_vm.options, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("跳过")]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.isStride,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "isStride", $$v)
                    },
                    expression: "form.isStride",
                  },
                },
                _vm._l(_vm.isStrides, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("li", [
            _c("h5", [_vm._v("起始时间")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "block" },
              [
                _c("el-date-picker", {
                  attrs: { type: "date", "value-format": "yyyy-MM-dd" },
                  model: {
                    value: _vm.form.beginTime,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "beginTime", $$v)
                    },
                    expression: "form.beginTime",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("h5", [_vm._v("截止时间")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "block" },
              [
                _c("el-date-picker", {
                  attrs: { type: "date", "value-format": "yyyy-MM-dd" },
                  model: {
                    value: _vm.form.endTime,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "endTime", $$v)
                    },
                    expression: "form.endTime",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "function" }, [
        _c("ol", [
          _c("li", { on: { click: _vm.search } }, [
            _c("img", {
              attrs: {
                src: require("../../assets/iconfont/find.png"),
                alt: "",
              },
            }),
            _vm._v(" "),
            _c("span", [_vm._v("查询")]),
          ]),
          _vm._v(" "),
          _c("li", { on: { click: _vm.empty } }, [
            _c("img", {
              attrs: {
                src: require("../../assets/iconfont/empty.png"),
                alt: "",
              },
            }),
            _vm._v(" "),
            _c("span", [_vm._v("清空")]),
          ]),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/firmware/task/deploy",
                  expression: "'/firmware/task/deploy'",
                },
              ],
              on: { click: _vm.issue },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/issue.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("发布")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/firmware/task/flash",
                  expression: "'/firmware/task/flash'",
                },
              ],
              on: { click: _vm.roots },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/unload.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("刷机")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/firmware/task/undo",
                  expression: "'/firmware/task/undo'",
                },
              ],
              on: { click: _vm.repeal },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/repeal.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("撤销")]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tab" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                border: "",
                "highlight-current-row": "",
              },
              on: { "row-click": _vm.getRow },
            },
            [
              _c("el-table-column", {
                attrs: { type: "index", index: _vm.indexMethod },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "equipmentModel",
                  label: "设备型号",
                  width: "150px",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "area", label: "区域", width: "100px" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "custom", label: "定制", width: "100px" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "configuration", label: "配置", width: "70px" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "equipmentNumber",
                  width: "150px",
                  label: "设备号",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "firmwareVersion",
                  label: "发布版本",
                  width: "280px",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "type",
                  label: "任务类型",
                  formatter: _vm.stateFormat,
                  width: "80px",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "status",
                  label: "状态",
                  formatter: _vm.statusFormat,
                  width: "70px",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "equipmentPurpose",
                  label: "设备用途",
                  width: "90px",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "isStride",
                  label: "跳过",
                  formatter: _vm.isStrideFormat,
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "createUser", label: "操作员" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "操作时间",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "revokeUser", label: "撤销人员" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "revokeTime",
                  label: "撤销时间",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "isHistory",
                  label: "历史",
                  formatter: _vm.historyFormat,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, prev, pager, next",
              "page-size": 10,
              total: _vm.total,
              "current-page": _vm.currentPage,
            },
            on: { "current-change": _vm.changepage },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "发布",
            visible: _vm.dialogFromVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFromVisible = $event
            },
            close: _vm.close,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "publice",
              attrs: {
                model: _vm.publice,
                rules: _vm.publiceRules,
                "status-icon": true,
                "show-message": false,
                "hide-required-asterisk": "",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "发布版本：",
                    "label-width": _vm.formLabelWidth,
                    prop: "firmwareVersion",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择" },
                      on: {
                        focus: function ($event) {
                          return _vm.getFirst()
                        },
                        change: _vm.handleFirst,
                      },
                      model: {
                        value: _vm.publice.firmwareVersion,
                        callback: function ($$v) {
                          _vm.$set(_vm.publice, "firmwareVersion", $$v)
                        },
                        expression: "publice.firmwareVersion",
                      },
                    },
                    _vm._l(_vm.version, function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.publice.firmwareVersion != ""
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "设备型号：",
                        "label-width": _vm.formLabelWidth,
                        prop: "equipmentModel",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请选择", filterable: "" },
                          on: {
                            focus: function ($event) {
                              return _vm.getSecond()
                            },
                            change: _vm.handleSecond,
                          },
                          model: {
                            value: _vm.publice.equipmentModel,
                            callback: function ($$v) {
                              _vm.$set(_vm.publice, "equipmentModel", $$v)
                            },
                            expression: "publice.equipmentModel",
                          },
                        },
                        _vm._l(_vm.model, function (item) {
                          return _c("el-option", {
                            key: item.equipmentModel,
                            attrs: {
                              label: item.equipmentModelName,
                              value: item.equipmentModel,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.publice.equipmentModel != ""
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "设备用途：",
                        "label-width": _vm.formLabelWidth,
                        prop: "equipmentPurpose",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请选择" },
                          on: {
                            focus: function ($event) {
                              return _vm.getThree()
                            },
                            change: _vm.handleTree,
                          },
                          model: {
                            value: _vm.publice.equipmentPurpose,
                            callback: function ($$v) {
                              _vm.$set(_vm.publice, "equipmentPurpose", $$v)
                            },
                            expression: "publice.equipmentPurpose",
                          },
                        },
                        _vm._l(_vm.purpose, function (item) {
                          return _c("el-option", {
                            key: item.equipmentPurposeValue,
                            attrs: {
                              label: item.equipmentPurposeName,
                              value: item.equipmentPurposeValue,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: { click: _vm.release },
                },
                [_vm._v("发布")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "刷机",
            visible: _vm.dialogFromsVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFromsVisible = $event
            },
            close: _vm.flagclose,
          },
        },
        [
          _c(
            "el-form",
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "刷机类型：",
                    "label-width": _vm.formLabelWidth,
                    prop: "firmwareVersion",
                  },
                },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "1" },
                      on: { change: _vm.changeRadio },
                      model: {
                        value: _vm.radio,
                        callback: function ($$v) {
                          _vm.radio = $$v
                        },
                        expression: "radio",
                      },
                    },
                    [_vm._v("单刷")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "2" },
                      on: { change: _vm.changeRadio },
                      model: {
                        value: _vm.radio,
                        callback: function ($$v) {
                          _vm.radio = $$v
                        },
                        expression: "radio",
                      },
                    },
                    [_vm._v("批刷")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isFlag,
                  expression: "isFlag",
                },
              ],
            },
            [
              _c(
                "el-form",
                {
                  ref: "alonePublice",
                  attrs: {
                    model: _vm.alonePublice,
                    rules: _vm.aloneRules,
                    "status-icon": true,
                    "show-message": false,
                    "hide-required-asterisk": "",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "发布版本：",
                        "label-width": _vm.formLabelWidth,
                        prop: "firmwareVersion",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请选择" },
                          on: {
                            focus: function ($event) {
                              return _vm.getFirst()
                            },
                            change: _vm.handleFirst,
                          },
                          model: {
                            value: _vm.alonePublice.firmwareVersion,
                            callback: function ($$v) {
                              _vm.$set(_vm.alonePublice, "firmwareVersion", $$v)
                            },
                            expression: "alonePublice.firmwareVersion",
                          },
                        },
                        _vm._l(_vm.version, function (item) {
                          return _c("el-option", {
                            key: item,
                            attrs: { label: item, value: item },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "设备号：",
                        "label-width": _vm.formLabelWidth,
                        prop: "equipmentNumber",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { type: "text", maxlength: "100" },
                        model: {
                          value: _vm.alonePublice.equipmentNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.alonePublice, "equipmentNumber", $$v)
                          },
                          expression: "alonePublice.equipmentNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "flex-end",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.dialogFromsVisible = false
                            },
                          },
                        },
                        [_vm._v("取 消")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", disabled: _vm.isDisable },
                          on: { click: _vm.aloneRoot },
                        },
                        [_vm._v("刷机")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.flag,
                  expression: "flag",
                },
              ],
            },
            [
              _c(
                "el-form",
                {
                  ref: "publice",
                  attrs: {
                    model: _vm.publice,
                    rules: _vm.publiceRules,
                    "status-icon": true,
                    "show-message": false,
                    "hide-required-asterisk": "",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "发布版本：",
                        "label-width": _vm.formLabelWidth,
                        prop: "firmwareVersion",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请选择" },
                          on: {
                            focus: function ($event) {
                              return _vm.getFirst()
                            },
                            change: _vm.handleFirst,
                          },
                          model: {
                            value: _vm.publice.firmwareVersion,
                            callback: function ($$v) {
                              _vm.$set(_vm.publice, "firmwareVersion", $$v)
                            },
                            expression: "publice.firmwareVersion",
                          },
                        },
                        _vm._l(_vm.version, function (item) {
                          return _c("el-option", {
                            key: item,
                            attrs: { label: item, value: item },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.publice.firmwareVersion != ""
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "设备型号：",
                            "label-width": _vm.formLabelWidth,
                            prop: "equipmentModel",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请选择", filterable: "" },
                              on: {
                                focus: function ($event) {
                                  return _vm.getSecond()
                                },
                                change: _vm.handleSecond,
                              },
                              model: {
                                value: _vm.publice.equipmentModel,
                                callback: function ($$v) {
                                  _vm.$set(_vm.publice, "equipmentModel", $$v)
                                },
                                expression: "publice.equipmentModel",
                              },
                            },
                            _vm._l(_vm.model, function (item) {
                              return _c("el-option", {
                                key: item.equipmentModel,
                                attrs: {
                                  label: item.equipmentModelName,
                                  value: item.equipmentModel,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.publice.equipmentModel != ""
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "设备用途：",
                            "label-width": _vm.formLabelWidth,
                            prop: "equipmentPurpose",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请选择" },
                              on: {
                                focus: function ($event) {
                                  return _vm.getThree()
                                },
                                change: _vm.handleTree,
                              },
                              model: {
                                value: _vm.publice.equipmentPurpose,
                                callback: function ($$v) {
                                  _vm.$set(_vm.publice, "equipmentPurpose", $$v)
                                },
                                expression: "publice.equipmentPurpose",
                              },
                            },
                            _vm._l(_vm.purpose, function (item) {
                              return _c("el-option", {
                                key: item.equipmentPurposeValue,
                                attrs: {
                                  label: item.equipmentPurposeName,
                                  value: item.equipmentPurposeValue,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "flex-end",
                  },
                },
                [
                  _c("el-button", { on: { click: _vm.flagclose } }, [
                    _vm._v("取 消"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.root } },
                    [_vm._v("刷机")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }