var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "changeapp" },
    [
      _c(
        "el-form",
        {
          ref: "updateData",
          attrs: {
            model: _vm.updateData,
            "status-icon": true,
            "show-message": false,
          },
        },
        [
          _c(
            "el-form-item",
            {
              staticStyle: { "padding-right": "8px" },
              attrs: { label: "语言: ", "label-width": _vm.formLabelWidth },
            },
            [
              _c("el-input", {
                staticStyle: { width: "100%" },
                attrs: { disabled: true },
                model: {
                  value: _vm.updateData.lan,
                  callback: function ($$v) {
                    _vm.$set(_vm.updateData, "lan", $$v)
                  },
                  expression: "updateData.lan",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticStyle: { "padding-right": "4px", "padding-left": "4px" },
              attrs: { label: "应用名称: ", "label-width": _vm.formLabelWidth },
            },
            [
              _c("el-input", {
                attrs: { disabled: true },
                model: {
                  value: _vm.updateData.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.updateData, "name", $$v)
                  },
                  expression: "updateData.name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticStyle: { "padding-left": "8px" },
              attrs: { label: "版本号: ", "label-width": _vm.formLabelWidth },
            },
            [
              _c("el-input", {
                attrs: { disabled: true },
                model: {
                  value: _vm.updateData.version,
                  callback: function ($$v) {
                    _vm.$set(_vm.updateData, "version", $$v)
                  },
                  expression: "updateData.version",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticClass: "updateEditor",
              attrs: { "label-width": _vm.formLabelWidth, prop: "fixPoint" },
            },
            [
              _c(
                "el-upload",
                {
                  staticClass: "avatar-uploader",
                  staticStyle: { display: "none" },
                  attrs: {
                    action: "",
                    "http-request": _vm.to_upload_img,
                    "show-file-list": false,
                  },
                },
                [
                  _vm._v("\n        >\n        "),
                  _c("i", {
                    staticClass: "el-icon-plus avatar-uploader-icon",
                    attrs: { id: "imgInput" },
                  }),
                ]
              ),
              _vm._v(" "),
              _c("quill-editor", {
                ref: "myQuillEditor",
                attrs: { options: _vm.editorOption, "show-word-limit": "" },
                model: {
                  value: _vm.updateData.fixPoint,
                  callback: function ($$v) {
                    _vm.$set(_vm.updateData, "fixPoint", $$v)
                  },
                  expression: "updateData.fixPoint",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "btn" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", disabled: _vm.isDisable },
              on: { click: _vm.submit },
            },
            [_vm._v("提交")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }