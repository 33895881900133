"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-manage/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.notFound = exports.default = exports.constantRoutes = exports.asyncRoutes = void 0;
exports.resetRouter = resetRouter;
var _interopRequireWildcard2 = _interopRequireDefault(require("/data/www/prod-cn-snote-manage/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _layout = _interopRequireDefault(require("@/layout"));
_vue.default.use(_vueRouter.default);

/* Layout */

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
var constantRoutes = exports.constantRoutes = [{
  path: '/login',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/login/index'));
    });
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/404'));
    });
  },
  hidden: true
}, {
  path: '/',
  component: _layout.default,
  redirect: '/dashboard',
  hidden: true,
  children: [{
    path: 'dashboard',
    name: 'Dashboard',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/dashboard/index'));
      });
    },
    meta: {
      title: '主页',
      icon: 'dashboard',
      affix: true
    }
  }]
}];
var asyncRoutes = exports.asyncRoutes = [
//系统管理
{
  path: '/systems',
  component: _layout.default,
  redirect: '/systems/resource',
  alwaysShow: true,
  // will always show the root menu
  name: 'systems',
  meta: {
    title: '系统管理',
    icon: 'dashboard' // you can set roles in root nav
  },
  children: [{
    path: 'resource',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/systems/resource.vue'));
      });
    },
    name: 'Resourc',
    meta: {
      title: '资源管理'
    }
  }, {
    path: 'role',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/systems/role.vue'));
      });
    },
    name: 'RolePermission',
    meta: {
      title: '角色管理'
    }
  }, {
    path: 'systemuser',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/systems/systemuser.vue'));
      });
    },
    name: 'Systemuser',
    meta: {
      title: '系统用户管理'
    }
  }, {
    path: 'dispatcher',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/systems/dispatcher.vue'));
      });
    },
    name: 'Dispatche',
    meta: {
      title: '调度任务'
    }
  }]
},
//参数管理
{
  path: '/parameter',
  component: _layout.default,
  redirect: '/parameter/dictionaries',
  alwaysShow: true,
  // will always show the root menu
  name: 'Paramete',
  meta: {
    title: '参数管理',
    icon: 'guide' // you can set roles in root nav
  },
  children: [{
    path: 'dictionaries',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/parameter/dictionaries.vue'));
      });
    },
    name: 'Dictionarie',
    meta: {
      title: '数据字典管理'
    }
  }, {
    path: 'configuration',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/parameter/configuration.vue'));
      });
    },
    name: 'Configuratio',
    meta: {
      title: '参数配置'
    }
  }]
},
//用户管理
{
  path: '/user',
  component: _layout.default,
  redirect: '/user/enter',
  alwaysShow: true,
  // will always show the root menu
  name: 'User',
  meta: {
    title: '用户管理',
    icon: 'lock' // you can set roles in root nav
  },
  children: [{
    path: 'enter',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/user/enter.vue'));
      });
    },
    name: 'Enter',
    meta: {
      title: '登录记录'
    }
  }, {
    path: 'userControl',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/user/userControl.vue'));
      });
    },
    name: 'UserControl',
    meta: {
      title: '用户信息'
    }
  }]
},
//业务管理
{
  path: '/business',
  component: _layout.default,
  redirect: '/business/dealer',
  alwaysShow: true,
  // will always show the root menu
  name: 'Business',
  meta: {
    title: '业务管理',
    icon: 'component' // you can set roles in root nav
  },
  children: [{
    path: 'dealer',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/business/dealer.vue'));
      });
    },
    name: 'Deale',
    meta: {
      title: '经销商信息'
    }
  }]
},
// // {
// //     path: '/icon',
// //     component: Layout,
// //     hidden: true,
// //     children: [{
// //         path: 'index',
// //         component: () =>
// //             import ('@/views/svg-icons/index'),
// //         name: 'Icons',
// //         meta: { title: 'icons', icon: 'icon', noCache: true }
// //     }]
// // },
//设备管理
{
  path: '/equipment',
  component: _layout.default,
  redirect: '/equipment/equiinfo',
  alwaysShow: true,
  // will always show the root menu
  name: 'Equipment',
  meta: {
    title: '设备管理',
    icon: 'nested' // you can set roles in root nav
  },
  children: [{
    path: 'equiinfo',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/equipment/equiinfo.vue'));
      });
    },
    name: 'Equiinfo',
    meta: {
      title: '库存信息'
    }
  }, {
    path: 'inoutrecord',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/equipment/inoutrecord.vue'));
      });
    },
    name: 'Inoutrecord',
    meta: {
      title: '出入库记录'
    }
  }, {
    path: 'exchange',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/equipment/exchange.vue'));
      });
    },
    name: 'Exchange',
    meta: {
      title: '退换货管理'
    }
  }, {
    path: 'information',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/equipment/information.vue'));
      });
    },
    name: ' information',
    meta: {
      title: '设备信息'
    }
  }, {
    path: 'equimentVersion',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/equipment/equimentVersion.vue'));
      });
    },
    name: ' equimentVersion',
    meta: {
      title: '设备版本'
    }
  }, {
    path: 'modelProperty',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/equipment/modelProperty.vue'));
      });
    },
    name: 'modelProperty',
    meta: {
      title: '设备更新报表'
    }
  }]
},
//固件管理
{
  path: '/firmware',
  component: _layout.default,
  redirect: '/firmware/firwareupload',
  alwaysShow: true,
  // will always show the root menu
  name: 'Firmware',
  meta: {
    title: '固件管理',
    icon: 'example' // you can set roles in root nav
  },
  children: [{
    path: 'firwareupload',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/firmware/firwareupload.vue'));
      });
    },
    name: 'Firwareupload',
    meta: {
      title: '固件版本信息'
    }
  }, {
    path: 'firwarerepair',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/firmware/firwarerepair.vue'));
      });
    },
    name: 'Firwarerepair',
    meta: {
      title: '固件版本修复点'
    }
  }, {
    path: 'addfirware',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/firmware/addfirware.vue'));
      });
    },
    name: 'Addfirware',
    meta: {
      title: '添加固件版本修复点页面'
    }
  }, {
    path: 'changefirware',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/firmware/changefirware.vue'));
      });
    },
    name: 'Changefirware',
    meta: {
      title: '修改固件版本修复点页面'
    }
  }, {
    path: 'firwareissue',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/firmware/firwareissue.vue'));
      });
    },
    name: 'Firwareissue',
    meta: {
      title: '固件版本发布'
    }
  }, {
    path: 'androidinfo',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/firmware/androidinfo.vue'));
      });
    },
    name: 'Androidinfo',
    meta: {
      title: 'Android版本信息'
    }
  }, {
    path: 'androidtask',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/firmware/androidtask.vue'));
      });
    },
    name: 'Androidtask',
    meta: {
      title: 'Android版本发布'
    }
  }, {
    path: 'applicationStore',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/firmware/applicationStore.vue'));
      });
    },
    name: 'ApplicationStore',
    meta: {
      title: '应用商店'
    }
  }, {
    path: 'applicationState',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/firmware/applicationState.vue'));
      });
    },
    name: 'ApplicationState',
    meta: {
      title: '应用商店说明'
    }
  }, {
    path: 'addapplicationState',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/firmware/addapplicationState.vue'));
      });
    },
    name: 'AddapplicationState',
    meta: {
      title: '添加应用商店说明页面'
    }
  }, {
    path: 'changeapplicationState',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/firmware/changeapplicationState.vue'));
      });
    },
    name: 'ChangeapplicationState',
    meta: {
      title: '修改应用商店说明页面'
    }
  }, {
    path: 'storerecord',
    // hidden:true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/firmware/storerecord.vue'));
      });
    },
    name: 'storerecord',
    meta: {
      title: '应用商店安装卸载记录'
    }
  }]
},
//终端管理
{
  path: '/terminal',
  component: _layout.default,
  redirect: '/terminal/log',
  alwaysShow: true,
  // will always show the root menu
  name: 'Terminal',
  meta: {
    title: '终端管理',
    icon: 'tab' // you can set roles in root nav
  },
  children: [{
    path: 'log',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/terminal/log.vue'));
      });
    },
    name: 'Log',
    meta: {
      title: '终端日志管理'
    }
  }]
},
//反馈管理
{
  path: '/feedback',
  component: _layout.default,
  redirect: '/feedback/feedbacktype',
  alwaysShow: true,
  // will always show the root menu
  name: 'Feedback',
  meta: {
    title: '反馈管理',
    icon: 'clipboard' // you can set roles in root nav
  },
  children: [{
    path: 'feedbacktype',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/feedback/feedbacktype.vue'));
      });
    },
    name: 'Feedbacktype',
    meta: {
      title: '反馈问题类型'
    }
  }, {
    path: 'feedbackrecord',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/feedback/feedbackrecord.vue'));
      });
    },
    name: 'Feedbackrecord',
    meta: {
      title: '反馈问题记录'
    }
  }]
},
//应用管理
{
  path: '/apply',
  component: _layout.default,
  redirect: '/apply/appupload',
  alwaysShow: true,
  // will always show the root menu
  name: 'Apply',
  meta: {
    title: '应用管理',
    icon: 'link' // you can set roles in root nav
  },
  children: [{
    path: 'appupload',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/apply/appupload.vue'));
      });
    },
    name: 'Appupload',
    meta: {
      title: 'app版本信息'
    }
  }, {
    path: 'apprepair',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/apply/apprepair.vue'));
      });
    },
    name: 'Apprepair',
    meta: {
      title: 'app版本修复点'
    }
  }, {
    path: 'addapp',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/apply/addapp.vue'));
      });
    },
    name: 'Addapp',
    meta: {
      title: '新增app修复点页面'
    }
  }, {
    path: 'changeapp',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/apply/changeapp.vue'));
      });
    },
    name: 'Changeapp',
    meta: {
      title: '修改app修复点页面'
    }
  }]
}

// 404 page must be placed at the end !!!
];
var notFound = exports.notFound = [{
  path: '*',
  redirect: '/404',
  hidden: true
}];
var createRouter = function createRouter() {
  return new _vueRouter.default({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
var _default = exports.default = router;