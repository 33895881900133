"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _firwareupload = require("../../api/firwareupload.js");
var _dictionary = require("../../api/dictionary.js");
var _equipment = require("../../api/equipment.js");
var _firwaretask = require("../../api/firwaretask.js");
var _firmwarepair = require("../../api/firmwarepair.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      isDisable: false,
      form: {
        firmwareVersion: "",
        status: "",
        beginTime: "",
        endTime: "",
        isStride: "",
        pageNo: 1,
        pageSize: 10
      },
      options: [{
        value: 0,
        label: "未审核"
      }, {
        value: 1,
        label: "审核测试中"
      }, {
        value: 2,
        label: "已审核"
      }],
      isStrides: [{
        value: 0,
        label: "否"
      }, {
        value: 1,
        label: "是"
      }],
      tableData: [],
      rowData: [],
      rowid: "",
      buttonUpload: "点击上传",
      saveButton: "保存",
      disab: false,
      savedisab: true,
      loading: false,
      isRepeat: false,
      passdis: false,
      testloading: false,
      dialogStride: false,
      saveButtonDisable: false,
      //展开行数据
      tableDatas: [],
      //版本信息
      info: {
        firmwareVersion: "",
        impactScope: "",
        modifyPoint: "",
        upgrageScope: "",
        versionPurpose: "",
        packagingTime: "",
        version: ""
      },
      strideForm: {
        firmwareVersion: "",
        isStride: "否"
      },
      //版本详细信息
      dialogVisible: false,
      formLabelWidth: "100px",
      infoTable: [],
      system_lib: "",
      app: "",
      app_lib: "",
      system: "",
      //子版本详细信息
      dialogVisibles: false,
      //上传固件包
      checkList: [],
      oldCheckList: [],
      password: "",
      dialogFormVisible: false,
      fileName: "",
      type: [],
      uploads: {
        password: "",
        isStride: ""
      },
      typeVal: [],
      uploadRules: {
        password: [{
          required: true,
          trigger: "blur"
        }]
      },
      firmwareVersion: "",
      //修改固件设备
      dialogFromsVisible: false,
      total: 0,
      currentPage: 1,
      //审核测试
      dialogTest: false,
      test: {
        equipmentNumberList: [],
        batchNo: "",
        deployVersion: "",
        endTime: "",
        equipmentModel: "",
        equipmentNumber: "",
        equipmentPurpose: "",
        firmwareVersion: "",
        flag: "",
        healthyState: "",
        pageNo: 1,
        pageSize: 10,
        startTime: ""
      },
      model: [],
      purpose: [],
      testData: [],
      testRow: "",
      flag: false,
      testDialog: false,
      //审核
      testTable: [],
      tests: {
        auditFlag: "同意",
        auditInfo: ""
      },
      testOption: [{
        label: "同意",
        value: "Y"
      }, {
        label: "不同意",
        value: "N"
      }]
    };
  },
  created: function created() {
    var _this = this;
    this.getList();
    (0, _firmwarepair.getLanList)({
      name: "EQUIPMENT_MODEL",
      value: ""
    }).then(function (res) {
      res.dictionaryVOList.forEach(function (v) {
        v.value = v.value * 1;
      });
      _this.type = res.dictionaryVOList;
    });
  },
  methods: {
    getList: function getList() {
      var _this2 = this;
      this.loading = true;
      setTimeout(function () {
        (0, _firwareupload.getFirware)(_this2.form).then(function (res) {
          _this2.tableData = res.voList;
          _this2.total = res.total;
          _this2.loading = false;
          _this2.isRepeat = false;
        });
      }, 500);
    },
    changepage: function changepage(currentPage) {
      this.currentPage = currentPage;
      this.form.pageNo = currentPage;
      this.getList();
    },
    rowExpand: function rowExpand(row, expandedRows) {
      var _this3 = this;
      this.rowid = row.id;
      if (!row.children) {
        (0, _firwareupload.getFirwarechildren)({
          id: this.rowid,
          pageNo: 1,
          pageSize: 10
        }).then(function (res) {
          _this3.tableDatas = res.voList;
          _this3.tableData.map(function (item) {
            if (row.id == item.id) {
              item.children = res.voList;
            }
          });
        });
      }
    },
    handleClick: function handleClick(val) {
      this.rowData = val;
      this.current = val;
      this.strideForm.firmwareVersion = val.firmwareVersion;
      if (val.isStride == 1 || val.isStride == "1") {
        this.strideForm.isStride = "是";
      }
    },
    stateFormat: function stateFormat(row, column) {
      if (row.auditFlag === "Y") {
        return "同意";
      } else if (row.auditFlag === "N") {
        return "不同意";
      }
    },
    isStrideFormat: function isStrideFormat(row) {
      if (row.isStride === "0" || row.isStride === 0) {
        return "否";
      } else if (row.isStride === "1" || row.isStride === 1) {
        return "是";
      }
    },
    //信息提示框
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    },
    //查询
    search: function search() {
      var _this4 = this;
      this.loading = true;
      setTimeout(function () {
        (0, _firwareupload.getFirware)({
          firmwareVersion: _this4.form.firmwareVersion,
          status: _this4.form.status,
          beginTime: _this4.form.beginTime,
          endTime: _this4.form.endTime,
          isStride: _this4.form.isStride,
          pageNo: 1,
          pageSize: 10
        }).then(function (res) {
          _this4.tableData = res.voList;
          _this4.total = res.total;
          _this4.currentPage = 1;
          _this4.loading = false;
        });
      }, 500);
    },
    //清空
    empty: function empty() {
      this.form.firmwareVersion = "";
      this.form.status = "";
      this.form.beginTime = "";
      this.form.endTime = "";
      this.form.isStride = "";
      this.current = [];
      this.getList();
    },
    uploadClose: function uploadClose() {
      this.dialogFormVisible = false;
      this.checkList = [];
      this.uploads.isStride = "";
      this.$refs.uploads.resetFields();
    },
    infoClose: function infoClose() {
      this.dialogFromsVisible = false;
      this.checkList = [];
    },
    //多选框
    typeChange: function typeChange(val) {},
    //上传
    upload: function upload() {
      this.buttonUpload = "点击上传";
      this.saveButton = "保存";
      this.disab = false;
      this.savedisab = true;
      this.passdis = false;
      this.dialogFormVisible = true;
      this.isDisable = false;
    },
    submitUpload: function submitUpload() {
      var _this5 = this;
      var fileData = this.$refs.uploadFile.files[0];
      var formFile = new FormData(); //加入文件对象
      formFile.append("file", fileData);
      if (this.uploads.password === "") {
        this.msgTip("error", "请输入密码!");
      } else {
        if (this.buttonUpload != "点击上传") {
          return;
        }
        var index = fileData.name.lastIndexOf(".");
        console.log(fileData.name, '1212313');
        var str = fileData.name.substring(index + 1, fileData.name.length);
        var extension = str === "zip";
        if (!extension) {
          this.msgTip("error", "上传固件只能是zip格式!");
          return;
        } else {
          this.loading = true;
          (0, _firwareupload.checkValidity)(this.uploads.password, formFile).then(function (res) {
            if (res.success) {
              _this5.firmwareVersion = res.voT.firmwareVersion;
              _this5.buttonUpload = res.voT.firmwareVersion;
              _this5.disab = true;
              _this5.loading = false;
              _this5.passdis = true;
              _this5.savedisab = false;
              _this5.$refs.uploadFile.value = null;
            } else {
              _this5.loading = false;
              _this5.msgTip("error", res.errorMsg);
              _this5.$refs.uploadFile.value = null;
            }
          });
        }
      }
    },
    save: function save() {
      var _this6 = this;
      if (this.checkList == null || this.checkList == "" || this.checkList.length < 1) {
        this.msgTip("error", "设备型号不能为空!");
        return;
      }
      if (this.uploads.password === "") {
        this.msgTip("error", "请输入密码!");
      } else {
        if (this.uploads.isStride === "") {
          this.msgTip("error", "请选择是否跳过");
          return;
        }
        if (this.savedisab == true) {
          return;
        }
        this.savedisab = true;
        this.saveButton = "正在保存";
        this.isRepeat = true;
        this.loading = true;
        (0, _firwareupload.getUpload)({
          equipmentModel: this.checkList,
          firmwareVersion: this.firmwareVersion,
          password: this.uploads.password,
          isStride: this.uploads.isStride
        }).then(function (res) {
          if (res.success) {
            _this6.msgTip("success", "上传成功");
            _this6.$refs.uploadFile.value = [];
            _this6.dialogFormVisible = false;
            _this6.uploadClose();
            _this6.empty();
          } else {
            _this6.msgTip("error", res.errorMsg);
            _this6.$refs.uploadFile.value = [];
            _this6.buttonUpload = "点击上传";
            _this6.saveButton = "保存";
            _this6.isRepeat = false;
            _this6.loading = false;
          }
        });
      }
    },
    //跳过
    stride: function stride() {
      if (this.current == undefined || this.current == "" || this.current.length < 1) {
        this.msgTip("error", "请选择一条数据！");
      } else {
        this.saveButtonDisable = false;
        this.dialogStride = true;
      }
    },
    strideSave: function strideSave() {
      var _this7 = this;
      if (this.strideForm.isStride == "否") {
        this.strideForm.isStride = "0";
      }
      if (this.strideForm.isStride == "是") {
        this.strideForm.isStride = "1";
      }
      this.saveButtonDisable = true;
      (0, _firwareupload.stride)({
        firmwareVersion: this.strideForm.firmwareVersion,
        isStride: this.strideForm.isStride
      }).then(function (res) {
        if (res.success) {
          _this7.dialogStride = false;
          // 获取当前日期时间
          (0, _firwareupload.dataManipulation)(_this7.tableData, _this7.rowData, "", "", "", _this7.strideForm.isStride, "");
          _this7.msgTip("success", "修改成功");
        } else {
          _this7.msgTip("error", res.errorMsg);
          _this7.isRepeat = false;
          _this7.saveButtonDisable = false;
        }
      });
    },
    //删除
    dele: function dele() {
      var _this8 = this;
      if (this.current == undefined || this.current.length < 1) {
        this.msgTip("error", "请选择需要删除的数据！");
      } else {
        this.$confirm("您是否要删除当前选中的记录?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function () {
          _this8.isRepeat = true;
          (0, _firwareupload.deleteFirware)(_this8.current.id).then(function (res) {
            if (res.success) {
              _this8.$message({
                type: "success",
                message: "删除成功",
                center: true
              });
              _this8.getList();
            } else {
              _this8.$message({
                type: "warning",
                message: res.errorMsg,
                center: true
              });
              _this8.isRepeat = false;
            }
          });
        });
      }
    },
    //修改固件设备
    change: function change() {
      var _this9 = this;
      if (this.current == undefined || this.current == "" || this.current.length < 1) {
        this.msgTip("error", "请选择需要修改的数据！");
      } else {
        this.info.version = this.current.firmwareVersion;
        this.dialogFromsVisible = true;
        this.isDisable = false;
        (0, _firwareupload.searchEquipment)({
          equipmentModel: "",
          firmwareVersion: this.current.firmwareVersion,
          pageNo: "",
          pageSize: ""
        }).then(function (res) {
          _this9.checkList = res.voList.map(function (v) {
            return v.equipmentModel;
          });
          _this9.oldCheckList = _this9.checkList;
        });
      }
    },
    //修改保存
    exchangeSave: function exchangeSave() {
      var _this10 = this;
      this.isRepeat = true;
      this.isDisable = true;
      (0, _firwareupload.changeFirware)({
        firmwareVersion: this.current.firmwareVersion,
        equipmentModelList: this.checkList
      }).then(function (res) {
        if (res.success) {
          _this10.dialogFromsVisible = false;
          _this10.msgTip("success", "修改成功");
        } else {
          _this10.msgTip("error", res.errorMsg);
          _this10.isRepeat = false;
          _this10.isDisable = false;
        }
      });
    },
    //查看父元素详细信息
    detailed: function detailed(row) {
      var _this11 = this;
      this.dialogVisible = true;
      (0, _firwareupload.getDetailed)(row.id).then(function (res) {
        var list = res.voT;
        _this11.info.firmwareVersion = list.firmwareVersion;
        _this11.info.modifyPoint = list.modifyPoint;
        _this11.info.impactScope = list.impactScope;
        _this11.info.versionPurpose = list.versionPurpose;
        _this11.info.upgrageScope = list.upgrageScope;
        _this11.info.packagingTime = list.packagingTime;
        _this11.infoTable = list.mergeVersion;
        _this11.app = list.mergeVersion.app;
        _this11.app_lib = list.mergeVersion.app_lib;
        _this11.system = list.mergeVersion.system;
        _this11.system_lib = list.mergeVersion.system_lib;
        _this11.strideForm.firmwareVersion = list.firmwareVersion;
        _this11.strideForm.isStride = list.isStride;
      });
    },
    childrenDetailed: function childrenDetailed(row) {
      var _this12 = this;
      this.dialogVisibles = true;
      (0, _firwareupload.getChildDetailed)(row.id).then(function (res) {
        var list = res.voT;
        _this12.info.version = list.version;
        _this12.info.modifyPoint = list.modifyPoint;
        _this12.info.impactScope = list.impactScope;
        _this12.info.versionPurpose = list.versionPurpose;
        _this12.info.upgrageScope = list.upgrageScope;
        _this12.info.packagingTime = list.packagingTime;
        _this12.infoTable = list.mergeVersion;
      });
    },
    //审核测试
    checkTest: function checkTest() {
      if (this.current == undefined || this.current == null || this.current.length < 1) {
        this.msgTip("error", "请选择需要审核测试的固件！");
      } else {
        if (this.current.status == "已审核") {
          this.msgTip("warning", "已审核，不允许再次被审核测试和审核！");
        } else {
          this.getTestlist();
          this.dialogTest = true;
        }
      }
    },
    testClose: function testClose() {
      this.dialogTest = false;
      this.testData = [];
      this.test.equipmentModel = "";
      this.test.equipmentPurpose = "";
      this.test.equipmentNumber = "";
      this.testTable = [];
    },
    getFirst: function getFirst() {
      var _this13 = this;
      (0, _firwaretask.getModel)({
        firmwareVersion: this.current.firmwareVersion
      }).then(function (res) {
        _this13.test.equipmentPurpose = "";
        _this13.model = res.voList;
      });
    },
    handleFirst: function handleFirst(val) {
      this.test.equipmentModel = val;
    },
    getSecond: function getSecond() {
      var _this14 = this;
      (0, _firwaretask.getPurpose)(this.test.equipmentModel).then(function (res) {
        _this14.purpose = res.voList;
      });
    },
    testSearch: function testSearch() {
      var _this15 = this;
      this.test.equipmentNumberList = [];
      if (this.testTable != null && this.testTable != "") {
        this.testTable.forEach(function (element) {
          _this15.test.equipmentNumberList.push(element.equipmentNumber);
        });
      }
      if (this.test.equipmentModel == "" && this.test.equipmentPurpose == "") {
        this.msgTip("warning", "设备用途和设备型号必须同时勾选");
      } else if (this.test.equipmentModel == "" || this.test.equipmentPurpose == "") {
        this.msgTip("warning", "设备用途和设备型号必须同时勾选");
      } else {
        (0, _equipment.getStockInfo)(this.test).then(function (res) {
          _this15.testData = res.voList;
        });
      }
    },
    testEmpty: function testEmpty() {
      this.test.equipmentModel = "";
      this.test.equipmentPurpose = "";
      this.test.equipmentNumber = "";
      this.testData = [];
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.testRow = val;
    },
    addTest: function addTest() {
      var _this16 = this;
      var arr = [];
      this.testRow.map(function (res) {
        arr.push(res.equipmentNumber);
        _this16.test.equipmentNumberList.push(res.equipmentNumber);
      });
      if (this.testRow == "") {
        this.msgTip("warning", "请选择设备序列号");
      } else {
        this.testloading = true;
        (0, _firwareupload.getTest)({
          equipmentModel: this.test.equipmentModel,
          equipmentNumber: this.test.equipmentNumber,
          equipmentNumberList: arr,
          equipmentPurpose: this.test.equipmentPurpose,
          firmwareVersion: this.current.firmwareVersion
        }).then(function (res) {
          if (res.success) {
            _this16.msgTip("success", "添加成功");
            _this16.testloading = false;
            _this16.isRepeat = false;
            _this16.testData = _this16.testData.filter(function (v) {
              return !_this16.testRow.includes(v);
            });
            _this16.getTestlist();
            _this16.getList();
          } else {
            _this16.testloading = false;
            _this16.isRepeat = false;
            _this16.msgTip("error", res.errorMsg);
          }
        });
      }
    },
    testDele: function testDele(val) {
      var _this17 = this;
      var equipment = val.equipmentNumber;
      (0, _firwareupload.testDelete)(equipment).then(function (res) {
        if (res.success) {
          _this17.getTestlist();
          _this17.msgTip("success", "删除成功");
          _this17.getList();
          _this17.test.equipmentNumberList = [];
          if (_this17.testTable != null && _this17.testTable != "") {
            _this17.testTable.forEach(function (element) {
              if (element.equipmentNumber !== val.equipmentNumber) {
                _this17.test.equipmentNumberList.push(element.equipmentNumber);
              }
            });
          }
          if (_this17.testData && _this17.testData.length > 0) {
            (0, _equipment.getStockInfo)(_this17.test).then(function (res) {
              if (res.data.success) {
                _this17.testData = res.voList;
              }
            });
          }
        }
      });
    },
    getTestlist: function getTestlist() {
      var _this18 = this;
      (0, _firwareupload.getTestlists)({
        firmwareVersion: this.current.firmwareVersion,
        pageNo: 1,
        pageSize: 10
      }).then(function (res) {
        _this18.testTable = res.voList;
      });
    },
    statusData: function statusData(row) {
      if (row.updateStatus == 0) {
        this.flag = false;
        return "初始版本";
      } else if (row.updateStatus == 1) {
        this.flag = true;
        return "未更新";
      } else if (row.updateStatus == 2) {
        this.flag = false;
        return "已更新";
      }
    },
    //审核
    check: function check() {
      if (this.current == undefined || this.current == null || this.current.length < 1) {
        this.msgTip("error", "请选择需要审核测试的固件！");
      } else {
        if (this.current.status == "已审核") {
          this.msgTip("warning", "已审核，不允许再次被审核测试和审核！");
        } else {
          this.tests.auditFlag = "Y";
          this.testDialog = true;
          this.isDisable = false;
        }
      }
    },
    close: function close() {
      this.testDialog = false;
      this.tests.auditInfo = "";
    },
    strideClose: function strideClose() {
      this.dialogStride = false;
    },
    testSave: function testSave() {
      var _this19 = this;
      this.isDisable = true;
      (0, _firwareupload.finishTest)({
        auditFlag: this.tests.auditFlag,
        auditInfo: this.tests.auditInfo,
        id: this.current.id
      }).then(function (res) {
        if (res.success) {
          _this19.msgTip("success", "审核通过");
          _this19.testDialog = false;
          // 获取当前日期 时间
          (0, _firwareupload.dataManipulation)(_this19.tableData, _this19.rowData, "已审核", _this19.tests.auditFlag, _this19.tests.auditInfo, "", getDate());
        } else {
          _this19.msgTip("error", res.errorMsg);
          _this19.isDisable = false;
        }
      });
    },
    indexMethod: function indexMethod(index) {
      return index + 1;
    }
  }
};
/**
 * 获取当前时间.
 */
function getDate() {
  var date = new Date();
  var seperator1 = "-";
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var strDate = date.getDate();
  var hours = date.getHours(); //获取当前小时数(0-23)
  var minutes = date.getMinutes(); //获取当前分钟数(0-59)
  var seconds = date.getSeconds();
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = "0" + strDate;
  }
  var currentdate = year + seperator1 + month + seperator1 + strDate + " " + hours + ":" + minutes + ":" + seconds;
  return currentdate;
}