"use strict";

var _interopRequireWildcard = require("/data/www/prod-cn-snote-manage/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.array.find-index");
var _user = require("@/api/user");
var _auth = require("@/utils/auth");
var _router = require("@/router");
var _router2 = _interopRequireWildcard(require("../../router"));
var state = {
  token: (0, _auth.getToken)(),
  name: '',
  routers: _router2.constantRoutes,
  permission: ''
};
var mutations = {
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_NAME: function SET_NAME(state, name) {
    state.name = name;
  },
  SET_ROUTERS: function SET_ROUTERS(state, routers) {
    state.routers = routers;
  },
  SET_PERMISSION: function SET_PERMISSION(state, permission) {
    state.permission = permission;
  }
};
var actions = {
  // user login
  login: function login(_ref, userInfo) {
    var commit = _ref.commit;
    var username = userInfo.username,
      password = userInfo.password;
    return new Promise(function (resolve, reject) {
      (0, _user.login)({
        username: username.trim(),
        password: password
      }).then(function (response) {
        commit('SET_TOKEN', response.token);
        (0, _auth.setToken)(response.token);
        resolve(response);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // get user info
  getInfo: function getInfo(_ref2) {
    var commit = _ref2.commit,
      state = _ref2.state;
    return new Promise(function (resolve, reject) {
      (0, _user.getInfo)(0).then(function (response) {
        // console.log(response)
        var arr = [];
        var permission = [];
        _router2.asyncRoutes.map(function (v, i) {
          var idx = response.findIndex(function (val) {
            return val.label === v.meta.title;
          });
          if (idx === -1) {
            arr.push(i);
          }
          var arr1 = [];
          var arr2 = [];
          response.map(function (val) {
            v.children.map(function (item) {
              val.children.map(function (key) {
                if (key.label == item.meta.title) {
                  item.meta.attributes = key.children;
                  permission = permission.concat(key.children);
                  arr2.push(item);
                }
              });
            });
          });
          v.children = arr2;
        });
        for (var i = arr.length - 1; i >= 0; i--) {
          _router2.asyncRoutes.splice(arr[i], 1);
        }
        commit('SET_PERMISSION', permission);
        commit('SET_NAME', response.name);
        commit('SET_ROUTERS', _router2.asyncRoutes);
        _router2.default.addRoutes(_router2.asyncRoutes);
        resolve(response);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // user logout
  // logout({ commit, state }) {
  //     return new Promise((resolve, reject) => {
  //         logout(state.token).then(() => {
  //             commit('SET_TOKEN', '')
  //             removeToken()
  //             resetRouter()
  //             resolve()
  //         }).catch(error => {
  //             reject(error)
  //         })
  //     })
  // },
  // remove token
  resetToken: function resetToken(_ref3) {
    var commit = _ref3.commit;
    return new Promise(function (resolve) {
      commit('SET_TOKEN', '');
      (0, _auth.removeToken)();
      resolve();
    });
  }
};
var _default = exports.default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};