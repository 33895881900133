"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-manage/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addFirmwareFixpoint = addFirmwareFixpoint;
exports.dataManipulation = dataManipulation;
exports.deleFirmwareFixpoint = deleFirmwareFixpoint;
exports.getFirmwareFixpointList = getFirmwareFixpointList;
exports.getFirmwareVersionList = getFirmwareVersionList;
exports.getLanList = getLanList;
exports.searchFirmwareFixpoint = searchFirmwareFixpoint;
exports.updateFirmwareFixpoint = updateFirmwareFixpoint;
exports.updateVersion = updateVersion;
var _request = _interopRequireDefault(require("@/utils/request"));
//查询所有固件修复点信息列表
function getFirmwareFixpointList(data) {
  return (0, _request.default)({
    url: 'system/business/firmware/fixpoint/query',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//查询语言列表
function getLanList(data) {
  return (0, _request.default)({
    url: 'system/base/dictionary/param',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//查询固件版本号列表
function getFirmwareVersionList(data) {
  return (0, _request.default)({
    url: 'system/business/firmware/info/version',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//新增固件修复点信息
function addFirmwareFixpoint(data) {
  return (0, _request.default)({
    url: 'system/business/firmware/fixpoint/save',
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
function updateVersion(data) {
  return (0, _request.default)({
    url: 'system/business/firmware/fixpoint/update/part',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//修改固件修复点信息
function updateFirmwareFixpoint(data, id) {
  return (0, _request.default)({
    url: 'system/business/firmware/fixpoint/update/' + id,
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
//查询单个修复点信息
function searchFirmwareFixpoint(id) {
  return (0, _request.default)({
    url: 'system/business/firmware/fixpoint/query/' + id,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}
//删除
function deleFirmwareFixpoint(id) {
  return (0, _request.default)({
    url: 'system/business/firmware/fixpoint/delete/' + id,
    method: 'delete',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

/**
 * 前端刷新公共方法
 */
function dataManipulation(tableData, rowData, fixPoint, opTime) {
  tableData.map(function (element) {
    //如果是单选的直接用if判断id是否相等就可以了,如果是复选框的就需要嵌套for循环判断
    if (element.id == rowData.id) {
      if (fixPoint != null && fixPoint != "") {
        element.fixPoint = fixPoint;
      }
      element.opUser = window.localStorage.getItem("username");
      if (opTime != null && opTime != "") {
        element.opTime = opTime;
      }
    }
  });
  return tableData;
}