"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vueQuillEditor = require("vue-quill-editor");
var _firmwarepair = require("../../api/firmwarepair.js");
var _fs = require("fs");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//调用编辑器
var _default = exports.default = {
  components: {
    quillEditor: _vueQuillEditor.quillEditor
  },
  data: function data() {
    return {
      isDisable: false,
      value: "",
      value1: "",
      value2: "",
      currentPage4: 0,
      total: 0,
      id: "",
      tableData: [],
      rowData: [],
      lanData: [],
      versionData: [],
      dialogFormVisible: false,
      dialogVisible: false,
      dialogVersionVisible: false,
      isVersionDisable: false,
      formLabelWidth: "100px",
      loading: false,
      isRepeat: false,
      updateVersionRule: {
        oldFirmwareVersion: [{
          required: true,
          trigger: "blur"
        }],
        firmwareVersion: [{
          required: true,
          trigger: "blur"
        }],
        taskType: [{
          required: true,
          trigger: "blur"
        }]
      },
      form: {
        pageNo: 1,
        pageSize: 10,
        lan: "",
        firmwareVersion: "",
        type: ''
      },
      versionForm: {
        taskType: "",
        oldFirmwareVersion: "",
        firmwareVersion: ''
      },
      updateData: {
        firmwareVersion: "",
        fixPoint: "",
        lan: ""
      },
      updateRowData: {
        firmwareVersion: "",
        fixPoint: "",
        lan: ""
      },
      show: false,
      system: [{
        value: '1',
        valueCn: 'Linux'
      }, {
        value: '2',
        valueCn: 'Android'
      }],
      taskTypes: [{
        value: '1',
        valueCn: '测试'
      }, {
        value: '2',
        valueCn: '发布'
      }]
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.loading = true;
      setTimeout(function () {
        (0, _firmwarepair.getFirmwareFixpointList)(_this.form).then(function (res) {
          _this.tableData = res.voList;
          _this.total = res.total;
          _this.loading = false;
          _this.isRepeat = false;
        });
      }, 500);
    },
    //添加
    add: function add() {
      this.$router.push({
        path: "addfirware"
      });
    },
    //批量修改
    updateVersion: function updateVersion() {
      this.dialogVersionVisible = true;
      this.isVersionDisable = false;
    },
    //修改
    change: function change(id) {
      this.$router.push({
        path: "changefirware",
        query: {
          id: id
        }
      });
    },
    //查询
    search: function search() {
      var _this2 = this;
      this.loading = true;
      setTimeout(function () {
        (0, _firmwarepair.getFirmwareFixpointList)(_this2.form).then(function (res) {
          _this2.tableData = res.voList;
          _this2.total = res.total;
          _this2.loading = false;
          _this2.currentPage4 = 1;
          _this2.isRepeat = false;
        });
      }, 500);
    },
    getLan: function getLan() {
      var _this3 = this;
      (0, _firmwarepair.getLanList)({
        name: "lan"
      }).then(function (res) {
        _this3.lanData = res.dictionaryVOList;
      });
    },
    //清空
    empty: function empty() {
      this.form.pageNo = 1;
      this.currentPage4 = 1;
      this.form.firmwareVersion = "";
      this.form.lan = "";
      this.form.type = "";
      this.form.taskType = "";
      this.getList();
    },
    //取消
    cancel: function cancel(formName) {
      var _this4 = this;
      this.$nextTick(function () {
        if (_this4.$refs[formName]) {
          _this4.$refs[formName].resetFields();
        }
      });
      this.dialogFormVisible = false;
      this.dialogVisible = false;
      this.dialogVersionVisible = false;
    },
    //获取每页条数
    handleSizeChange: function handleSizeChange(val) {
      this.form.pageSize = val;
      this.getList();
    },
    //获取页码
    handleCurrentChange: function handleCurrentChange(val) {
      this.form.pageNo = val;
      this.currentPage4 = val;
      this.getList();
    },
    //行选择
    currentChange: function currentChange(val) {
      this.currentRow = val;
    },
    //点击获取这行数据
    getRow: function getRow(row) {
      this.rowData = row;
      this.id = row.id;
      this.updateRowData.firmwareVersion = row.firmwareVersion;
      this.updateRowData.fixPoint = row.fixPoint;
      this.updateRowData.lan = row.lan;
    },
    //判断系统
    types: function types(row) {
      if (row.type == "1") {
        return "Linux";
      } else if (row.type == "2") {
        return "Android";
      }
    },
    //判断任务类型
    istaskType: function istaskType(row) {
      if (row.taskType == "1") {
        return "测试";
      } else if (row.taskType == "2") {
        return "发布";
      }
    },
    //删除
    dele: function dele(id) {
      var _this5 = this;
      this.$confirm("您是否要删除当前选中的记录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _firmwarepair.deleFirmwareFixpoint)(id).then(function (res) {
          if (res.success) {
            _this5.$message({
              type: "success",
              message: "删除成功",
              center: true
            });
            _this5.getList();
          } else {
            _this5.$message({
              type: "error",
              message: res.errorMsg,
              center: true
            });
          }
        });
      });
    },
    updateVersionSure: function updateVersionSure() {
      var _this6 = this;
      (0, _firmwarepair.updateVersion)(this.versionForm).then(function (res) {
        if (res.success) {
          _this6.$message({
            type: "success",
            message: "修改成功",
            center: true
          });
          _this6.dialogVersionVisible = false;
          _this6.cancel(_this6.versionForm);
          _this6.getList();
        } else {
          _this6.$message({
            type: "warning",
            message: res.errorMsg,
            center: true
          });
          _this6.isVersionDisable = false;
        }
      });
    },
    indexMethod: function indexMethod(index) {
      return index + 1;
    },
    handleClick: function handleClick(row) {
      console.log(row);
    }
  }
};