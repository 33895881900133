"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-manage/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addList = addList;
exports.changeList = changeList;
exports.dataManipulation = dataManipulation;
exports.deleFixpoint = deleFixpoint;
exports.getList = getList;
exports.searchAlone = searchAlone;
var _request = _interopRequireDefault(require("@/utils/request"));
//查询所有修复点列表
function getList(data) {
  return (0, _request.default)({
    url: 'system/business/android/store/fixpoint/query',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//查询单个修复点信息
function searchAlone(id) {
  return (0, _request.default)({
    url: 'system/business/android/store/fixpoint/query/' + id,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//新增修复点信息
function addList(data) {
  return (0, _request.default)({
    url: 'system/business/android/store/fixpoint/save',
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//删除
function deleFixpoint(id) {
  return (0, _request.default)({
    url: 'system/business/android/store/fixpoint/delete/' + id,
    method: 'delete',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//修改修复点信息
function changeList(data, id) {
  return (0, _request.default)({
    url: 'system/business/android/store/fixpoint/update/' + id,
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

/**
 * 前端刷新公共方法
 */
function dataManipulation(tableData, rowData, fixPoint, opTime) {
  tableData.map(function (element) {
    //如果是单选的直接用if判断id是否相等就可以了,如果是复选框的就需要嵌套for循环判断
    if (element.id == rowData.id) {
      if (name != null && name != "") {
        element.name = name;
      }
      if (fixPoint != null && fixPoint != "") {
        element.fixPoint = fixPoint;
      }
      element.opUser = window.localStorage.getItem("username");
      if (opTime != null && opTime != "") {
        element.opTime = opTime;
      }
    }
  });
  return tableData;
}