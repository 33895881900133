"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-manage/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _defineProperty2 = _interopRequireDefault(require("/data/www/prod-cn-snote-manage/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _androidtask = require("../../api/androidtask.js");
var _firmwarepair = require("../../api/firmwarepair.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({
      isDisable: false,
      value: "",
      loading: false,
      tableData: [],
      multipleSelection: [],
      form: {
        equipmentPurpose: "",
        logicVersion: "",
        status: "",
        pageNo: 1,
        pageSize: 20,
        type: "",
        version: ''
      },
      types: [{
        value: "1",
        label: "系统"
      }, {
        value: "2",
        label: "应用"
      }],
      Isstatus: [{
        value: "0",
        label: "正常"
      }, {
        value: "1",
        label: "撤销"
      }],
      currentPage: 1,
      total: 0,
      dialogFromVisible: false,
      taskTypes: [{
        value: "1",
        valueCn: "测试"
      }, {
        value: "2",
        valueCn: "发布"
      }],
      searchform: {
        area: '',
        custom: '',
        platform: '',
        taskType: ''
      },
      formLabelWidth: '120px',
      areas: [],
      customs: [],
      platforms: [],
      dependencySystems: [],
      equipmentPurposes: [],
      dependencySystem: "",
      searchformRules: {
        area: [{
          required: true,
          trigger: "change"
        }],
        custom: [{
          required: true,
          trigger: "change"
        }],
        platform: [{
          required: true,
          trigger: "change"
        }]
      },
      tableDatas: [],
      sysflag: false,
      deployList: []
    }, "multipleSelection", []), "equipmentPurposeList", ''), "equipmentPurpose", []), "logicVersion", ''), "current", null), "titletype", ''), "buttontext", '');
  },
  created: function created() {
    this.getList();
  },
  mounted: function mounted() {
    var _this = this;
    (0, _firmwarepair.getLanList)({
      name: "EQUIPMENT_PURPOSE"
    }).then(function (res) {
      if (res.success) {
        _this.equipmentPurposes = res.dictionaryVOList;
      }
    });
  },
  methods: (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({
    getList: function getList() {
      var _this2 = this;
      this.loading = true;
      setTimeout(function () {
        (0, _androidtask.getAndroidTask)(_this2.form).then(function (res) {
          _this2.tableData = res.voList;
          _this2.total = res.total;
          _this2.loading = false;
          _this2.isRepeat = false;
        });
      }, 500);
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(this.multipleSelection);
    },
    // 查找有没有选中数据，没有则返回false
    findSelectData: function findSelectData() {
      if (!this.multipleSelection.length) {
        // 无选中的数据
        return false;
      } else {
        // 有选中的数据
        return true;
      }
    },
    //分页
    changepage: function changepage(currentPage) {
      this.currentPage = currentPage;
      this.form.pageNo = currentPage;
      this.getList();
    },
    //查询
    search: function search() {
      var _this3 = this;
      this.loading = true;
      setTimeout(function () {
        (0, _androidtask.getAndroidTask)({
          equipmentPurpose: _this3.form.equipmentPurpose,
          logicVersion: _this3.form.logicVersion,
          status: _this3.form.status,
          pageNo: 1,
          pageSize: 20,
          type: _this3.form.type,
          version: _this3.form.version
        }).then(function (res) {
          _this3.tableData = res.voList;
          _this3.total = res.total;
          _this3.currentPage = 1;
          _this3.loading = false;
          _this3.isRepeat = false;
        });
      }, 500);
    },
    //清空
    empty: function empty() {
      this.form.equipmentPurpose = "";
      this.form.logicVersion = "";
      this.form.version = "";
      this.form.type = "";
      this.form.status = "";
      this.currentPage = 1;
      this.form.pageNo = 1;
      this.getList();
    },
    //判断类型
    equimentType: function equimentType(row) {
      if (row.type == "1") {
        return '系统';
      } else if (row.type == "2") {
        return '应用';
      }
    },
    //判断版本状态
    statusInfo: function statusInfo(row) {
      if (row.status == "0") {
        return "正常";
      } else if (row.status == "1") {
        return "撤销";
      }
    },
    //信息提示框
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    },
    handleClick: function handleClick(val) {
      this.current = val;
    },
    //撤销
    repeal: function repeal() {
      var _this4 = this;
      if (!this.findSelectData()) {
        this.msgTip("error", "至少选择一条数据");
      } else {
        this.$confirm("您是否要撤销当前选中的记录？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function () {
          var arr = [];
          _this4.multipleSelection.map(function (res) {
            arr.push(JSON.stringify(res.id));
          });
          (0, _androidtask.undoAndroidTask)({
            idList: arr
          }).then(function (res) {
            if (res.success) {
              _this4.msgTip("success", "撤销成功");
              _this4.getList();
            } else {
              _this4.msgTip("error", res.errorMsg);
            }
          });
        });
      }
    },
    test: function test() {
      this.titletype = '测试';
      this.dialogFromVisible = true;
      this.buttontext = '保存';
    },
    //发布
    issue: function issue() {
      this.titletype = '发布';
      this.dialogFromVisible = true;
      this.buttontext = '发布';
    },
    area: function area() {
      var _this5 = this;
      (0, _firmwarepair.getLanList)({
        name: "AREA"
      }).then(function (res) {
        if (res.success) {
          _this5.areas = res.dictionaryVOList;
        }
      });
    },
    handletask: function handletask(val) {
      this.dependencySystem = "";
      this.tableDatas = [];
      this.sysflag = false;
    },
    handlearea: function handlearea(val) {
      this.searchform.area = val;
      this.dependencySystem = "";
      this.tableDatas = [];
      this.sysflag = false;
    },
    custom: function custom() {
      var _this6 = this;
      (0, _firmwarepair.getLanList)({
        name: "CUSTOM"
      }).then(function (res) {
        if (res.success) {
          _this6.customs = res.dictionaryVOList;
        }
      });
    },
    hanledcustom: function hanledcustom(val) {
      this.searchform.custom = val;
      this.dependencySystem = "";
      this.tableDatas = [];
      this.sysflag = false;
    },
    platform: function platform() {
      var _this7 = this;
      (0, _firmwarepair.getLanList)({
        name: "PLATFORM"
      }).then(function (res) {
        if (res.success) {
          _this7.platforms = res.dictionaryVOList;
        }
      });
    },
    hanledplatform: function hanledplatform(val) {
      this.searchform.platform = val;
      this.dependencySystem = "";
      this.tableDatas = [];
      this.sysflag = false;
    },
    isdependencySystem: function isdependencySystem() {
      var _this8 = this;
      if (this.searchform.area == "") {
        this.msgTip("warning", "请选择区域");
      } else if (this.searchform.custom == "") {
        this.msgTip("warning", "请选择定制");
      } else if (this.searchform.platform == "") {
        this.msgTip("warning", "请选择平台");
      } else {
        if (this.titletype == '测试') {
          (0, _androidtask.searchsystem)({
            area: this.searchform.area,
            platform: this.searchform.platform,
            custom: this.searchform.custom,
            taskType: '1'
          }).then(function (res) {
            if (res.success) {
              _this8.dependencySystems = res.voList;
            }
          });
        } else if (this.titletype == '发布') {
          (0, _androidtask.searchsystem)({
            area: this.searchform.area,
            platform: this.searchform.platform,
            custom: this.searchform.custom,
            taskType: '2'
          }).then(function (res) {
            if (res.success) {
              _this8.dependencySystems = res.voList;
            }
          });
        }
      }
    },
    hanleddependencySystems: function hanleddependencySystems() {
      var _this9 = this;
      if (this.dependencySystem == "") {
        this.msgTip("warning", "请选择系统版本");
      } else {
        if (this.titletype == "测试") {
          (0, _androidtask.searchapkversion)({
            dependencySystem: this.dependencySystem,
            taskType: "1"
          }).then(function (res) {
            if (res.success) {
              _this9.sysflag = true;
              _this9.tableDatas = res.voList;
            }
          });
        } else if (this.titletype == "发布") {
          (0, _androidtask.searchapkversion)({
            dependencySystem: this.dependencySystem,
            taskType: "2"
          }).then(function (res) {
            if (res.success) {
              _this9.sysflag = true;
              _this9.tableDatas = res.voList;
            }
          });
        }
      }
    }
  }, "handleSelectionChange", function handleSelectionChange(val) {
    var _this10 = this;
    this.deployList = [];
    this.multipleSelection = val;
    val.map(function (res) {
      var obj = {
        name: res.name,
        // equipmentNumber: res.equipmentNumber,
        packageName: res.packageName,
        type: res.type,
        version: res.version,
        versionNo: res.versionNo
      };
      _this10.deployList.push(obj);
    });
  }), "isequipmentPurpose", function isequipmentPurpose() {
    var _this11 = this;
    (0, _firmwarepair.getLanList)({
      name: "EQUIPMENT_PURPOSE"
    }).then(function (res) {
      if (res.success) {
        _this11.equipmentPurposes = res.dictionaryVOList;
      }
    });
  }), "hanledequipmentPurposes", function hanledequipmentPurposes(val) {
    this.equipmentPurpose = val;
  }), "release", function release() {
    var _this12 = this;
    if (this.deployList.length < 1) {
      this.msgTip("warning", "请选择发布版本");
      return;
    }
    if (this.equipmentPurpose.length < 1) {
      this.msgTip("warning", "请选择设备用途");
      return;
    }
    if (this.logicVersion == "") {
      this.msgTip("warning", "请输入逻辑版本号");
      return;
    }
    if (this.dependencySystem == "") {
      this.msgTip("warning", "请选择系统版本");
      return;
    }
    this.$refs.searchform.validate(function (valid) {
      if (valid) {
        _this12.isDisable = true;
        if (_this12.titletype == "测试") {
          (0, _androidtask.deployAndroidTask)({
            taskType: "1",
            area: _this12.searchform.area,
            custom: _this12.searchform.custom,
            dependencySystem: _this12.dependencySystem,
            deployList: _this12.deployList,
            equipmentPurpose: _this12.equipmentPurpose,
            logicVersion: _this12.logicVersion,
            platform: _this12.searchform.platform
          }).then(function (res) {
            if (res.success) {
              _this12.msgTip("success", "测试通过");
              _this12.dialogFromVisible = false;
              _this12.isDisable = false;
              _this12.getList();
            } else {
              _this12.msgTip("error", res.errorMsg);
              _this12.isDisable = false;
            }
          });
        } else if (_this12.titletype == "发布") {
          (0, _androidtask.deployAndroidTask)({
            taskType: "2",
            area: _this12.searchform.area,
            custom: _this12.searchform.custom,
            dependencySystem: _this12.dependencySystem,
            deployList: _this12.deployList,
            equipmentPurpose: _this12.equipmentPurpose,
            logicVersion: _this12.logicVersion,
            platform: _this12.searchform.platform
          }).then(function (res) {
            if (res.success) {
              _this12.msgTip("success", "发布通过");
              _this12.dialogFromVisible = false;
              _this12.isDisable = false;
              _this12.getList();
            } else {
              _this12.msgTip("error", res.errorMsg);
              _this12.isDisable = false;
            }
          });
        }
      }
    });
  }), "close", function close() {
    this.dialogFromVisible = false;
    this.$refs.searchform.resetFields();
    this.dependencySystem = "";
    this.deployList = [];
    this.equipmentPurpose = [];
    this.logicVersion = "";
    this.tableDatas = [];
    this.sysflag = false;
  }), "indexMethod", function indexMethod(index) {
    return index + 1;
  })
};
/**
 * 获取当前时间
 */
function getDate() {
  var date = new Date();
  var seperator1 = "-";
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var strDate = date.getDate();
  var hours = date.getHours(); //获取当前小时数(0-23)
  var minutes = date.getMinutes(); //获取当前分钟数(0-59)
  var seconds = date.getSeconds();
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = "0" + strDate;
  }
  var currentdate = year + seperator1 + month + seperator1 + strDate + " " + hours + ":" + minutes + ":" + seconds;
  return currentdate;
}