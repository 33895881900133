"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-manage/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.exportFeedbackRecodList = exportFeedbackRecodList;
exports.getFeedbackRecodFirst = getFeedbackRecodFirst;
exports.getFeedbackRecodList = getFeedbackRecodList;
exports.getFeedbackRecodSecond = getFeedbackRecodSecond;
exports.getFeedbackRecodThird = getFeedbackRecodThird;
var _request = _interopRequireDefault(require("@/utils/request"));
//查询问题反馈记录
function getFeedbackRecodList(data) {
  return (0, _request.default)({
    url: 'system/business/feedback/record/query',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//查询一级问题类型
function getFeedbackRecodFirst(data) {
  return (0, _request.default)({
    url: 'system/business/feedback/type/query/layered',
    method: 'post',
    data: data,
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//查询二级问题类型
function getFeedbackRecodSecond(data) {
  return (0, _request.default)({
    url: 'system/business/feedback/type/query/layered',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    method: 'post',
    data: data
  });
}

//查询三级问题类型
function getFeedbackRecodThird(data) {
  return (0, _request.default)({
    url: 'system/business/feedback/type/query/layered',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    method: 'post',
    data: data
  });
}

//导出问题反馈记录
function exportFeedbackRecodList(data) {
  return (0, _request.default)({
    url: 'system/business/feedback/record/export',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    responseType: 'blob',
    data: data
  });
}