"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-manage/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addGray = addGray;
exports.appAapply = appAapply;
exports.audit = audit;
exports.dataManipulation = dataManipulation;
exports.delGray = delGray;
exports.deleteApp = deleteApp;
exports.deploy = deploy;
exports.download = download;
exports.getCheckUpload = getCheckUpload;
exports.getUpload = getUpload;
exports.querGray = querGray;
exports.searchAppVersion = searchAppVersion;
exports.undo = undo;
var _request = _interopRequireDefault(require("@/utils/request"));
// 根据参数查询设备日志信息接口
function searchAppVersion(data) {
  return (0, _request.default)({
    url: 'system/business/app/version/query',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

// 下载
function download(id, data) {
  return (0, _request.default)({
    url: 'system/business/app/version/download/url/' + id,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//删除
function deleteApp(id, data) {
  return (0, _request.default)({
    url: 'system/business/app/version/delete/' + id,
    method: 'delete',
    data: data,
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//撤销
function undo(id, data) {
  return (0, _request.default)({
    url: 'system/business/app/version/undo/' + id,
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
//审核
function audit(data) {
  return (0, _request.default)({
    url: 'system/business/app/version/audit',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
//发布
function deploy(id, data) {
  return (0, _request.default)({
    url: 'system/business/app/version/deploy/' + id,
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
//上传检查
function getCheckUpload(data) {
  return (0, _request.default)({
    url: 'system/business/app/version/upload/check',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data,
    timeout: 600000
  });
}

//上传
function getUpload(data) {
  return (0, _request.default)({
    url: 'system/business/app/version/upload',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data,
    timeout: 1200000
  });
}
function appAapply(data) {
  return (0, _request.default)({
    url: 'base/app/upload/apply',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//添加灰度
function addGray(data) {
  return (0, _request.default)({
    url: 'system/business/app/version/gray',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token'),
      "Content-Type": 'application/json'
    },
    data: data
  });
}

//根据app查询灰度信息
function querGray(appId) {
  return (0, _request.default)({
    url: 'system/business/app/version/gray/info/' + appId,
    method: 'GET',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//根据app查询灰度信息
function delGray(id) {
  return (0, _request.default)({
    url: 'system/business/app/version/gray/delete/' + id,
    method: 'delete',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

/**
 * 前端刷新公共方法
 */
function dataManipulation(tableData, rowData, auditingFlag, deployFlag, opTime) {
  tableData.map(function (element) {
    //如果是单选的直接用if判断id是否相等就可以了,如果是复选框的就需要嵌套for循环判断
    if (element.id == rowData.id) {
      if (auditingFlag != null && auditingFlag != "") {
        element.auditingFlag = auditingFlag;
      }
      if (deployFlag != null && deployFlag != "") {
        element.deployFlag = deployFlag;
      }
      element.opUser = window.localStorage.getItem("username");
      if (opTime != null && opTime != "") {
        element.opTime = opTime;
      }
    }
  });
  return tableData;
}