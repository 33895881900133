var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "inoutrecord" }, [
    _c("div", { staticClass: "inoutrecord-head" }, [
      _c("ul", [
        _c(
          "li",
          [
            _c("h5", [_vm._v("版本号")]),
            _vm._v(" "),
            _c("el-input", {
              model: {
                value: _vm.form.version,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "version", $$v)
                },
                expression: "form.version",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.search } },
              [_vm._v("查询")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.empty } },
              [_vm._v("清空")]
            ),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "tab" },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticStyle: { width: "100%" },
            attrs: { data: _vm.tableData, border: "" },
          },
          [
            _c("el-table-column", {
              attrs: { type: "index", index: _vm.indexMethod },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "version", label: "版本号" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "counts", label: "更新数量" },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "page" },
      [
        _c("el-pagination", {
          attrs: {
            background: "",
            layout: "total, prev, pager, next",
            "page-size": 10,
            total: _vm.total,
            "current-page": _vm.currentPage,
          },
          on: { "current-change": _vm.changepage },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }