"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-manage/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addRemark = addRemark;
exports.deleteEquipmentLog = deleteEquipmentLog;
exports.download = download;
exports.getEquipmentLog = getEquipmentLog;
exports.passed = passed;
exports.searchEquipmentLogList = searchEquipmentLogList;
exports.updateIsDownload = updateIsDownload;
var _request = _interopRequireDefault(require("@/utils/request"));
// 根据参数查询设备日志信息接口
function searchEquipmentLogList(data) {
  return (0, _request.default)({
    url: 'equipment/log',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

// 删除日志信息
function deleteEquipmentLog(data) {
  return (0, _request.default)({
    url: 'equipment/log/delete',
    method: 'delete',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

// 下载请求地址
function download(data) {
  return (0, _request.default)({
    url: 'equipment/log/download',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
// 下载完成后更新掉下载状态
function updateIsDownload(data) {
  return (0, _request.default)({
    url: 'equipment/log/update/status',
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

// 根据id获取日志信息
function getEquipmentLog(id) {
  return (0, _request.default)({
    url: 'equipment/log/' + id,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

// 添加备注信息
function addRemark(data) {
  return (0, _request.default)({
    url: 'equipment/log/add/remark',
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

// 审阅
function passed(data) {
  return (0, _request.default)({
    url: 'equipment/log/passed',
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}