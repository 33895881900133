var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "firwareupload" },
    [
      _c("div", { staticClass: "firwareupload-head" }, [
        _c("ul", [
          _c(
            "li",
            [
              _c("h5", [_vm._v("版本号")]),
              _vm._v(" "),
              _c("el-input", {
                model: {
                  value: _vm.form.firmwareVersion,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "firmwareVersion", $$v)
                  },
                  expression: "form.firmwareVersion",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("状态")]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status",
                  },
                },
                _vm._l(_vm.options, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("跳过")]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.isStride,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "isStride", $$v)
                    },
                    expression: "form.isStride",
                  },
                },
                _vm._l(_vm.isStrides, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("li", [
            _c("h5", [_vm._v("起始时间")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "block" },
              [
                _c("el-date-picker", {
                  attrs: {
                    type: "date",
                    "value-format": "yyyy-MM-dd",
                    placeholder: "选择日期",
                  },
                  model: {
                    value: _vm.form.beginTime,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "beginTime", $$v)
                    },
                    expression: "form.beginTime",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("h5", [_vm._v("截止时间")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "block" },
              [
                _c("el-date-picker", {
                  attrs: {
                    type: "date",
                    "value-format": "yyyy-MM-dd",
                    placeholder: "选择日期",
                  },
                  model: {
                    value: _vm.form.endTime,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "endTime", $$v)
                    },
                    expression: "form.endTime",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "function" }, [
        _c("ol", [
          _c("li", { on: { click: _vm.search } }, [
            _c("img", {
              attrs: {
                src: require("../../assets/iconfont/find.png"),
                alt: "",
              },
            }),
            _vm._v(" "),
            _c("span", [_vm._v("查询")]),
          ]),
          _vm._v(" "),
          _c("li", { on: { click: _vm.empty } }, [
            _c("img", {
              attrs: {
                src: require("../../assets/iconfont/empty.png"),
                alt: "",
              },
            }),
            _vm._v(" "),
            _c("span", [_vm._v("清空")]),
          ]),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/firmware/upload",
                  expression: "'/firmware/upload'",
                },
              ],
              on: { click: _vm.upload },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/upload.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("上传")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/firmware/stride",
                  expression: "'/firmware/stride'",
                },
              ],
              on: { click: _vm.stride },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/log.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("跳过")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/firmware/delete",
                  expression: "'/firmware/delete'",
                },
              ],
              on: { click: _vm.dele },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/delete.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("删除")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/firmware/update/firmEquip",
                  expression: "'/firmware/update/firmEquip'",
                },
              ],
              on: { click: _vm.change },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/amend.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("修改固件设备")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/firmware/auditTest",
                  expression: "'/firmware/auditTest'",
                },
              ],
              on: { click: _vm.checkTest },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/audit.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("审核测试")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/firmware/audit",
                  expression: "'/firmware/audit'",
                },
              ],
              on: { click: _vm.check },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/check.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("审核")]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tab" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                border: "",
                "highlight-current-row": "",
              },
              on: {
                "expand-change": _vm.rowExpand,
                "row-click": _vm.handleClick,
              },
            },
            [
              _c("el-table-column", {
                attrs: { type: "index", index: _vm.indexMethod },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { type: "expand" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _c(
                          "el-table",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { data: props.row.children, border: "" },
                          },
                          [
                            _c("el-table-column", {
                              attrs: { type: "index", index: _vm.indexMethod },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { prop: "type", label: "文件名" },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { prop: "version", label: "版本号" },
                            }),
                            _vm._v(" "),
                            _c("el-table-column", {
                              attrs: { label: "操作" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "div",
                                          { staticClass: "function-icon" },
                                          [
                                            _c(
                                              "el-tooltip",
                                              {
                                                directives: [
                                                  {
                                                    name: "permit",
                                                    rawName: "v-permit",
                                                    value: "/firmware/detail",
                                                    expression:
                                                      "'/firmware/detail'",
                                                  },
                                                ],
                                                staticClass: "item",
                                                attrs: {
                                                  effect: "dark",
                                                  content: "版本信息",
                                                  placement: "bottom",
                                                },
                                              },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: require("../../assets/iconfont/mess.png"),
                                                    alt: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.childrenDetailed(
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", { attrs: { label: "区域", prop: "area" } }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "定制", prop: "custom", width: "100px" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "配置", prop: "configuration", width: "100px" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "版本号",
                  width: "250px",
                  prop: "firmwareVersion",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "跳过",
                  prop: "isStride",
                  formatter: _vm.isStrideFormat,
                  width: "60px",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "状态", prop: "status", width: "100px" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "审核标识",
                  prop: "auditFlag",
                  formatter: _vm.stateFormat,
                  width: "100px",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "审核信息",
                  prop: "auditInfo",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "审核人", prop: "auditUser" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "审核时间", prop: "auditTime" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "上传时间", prop: "opTime" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "上传人员", prop: "opUser" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", width: "100px" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "function-icon" },
                          [
                            _c(
                              "el-tooltip",
                              {
                                directives: [
                                  {
                                    name: "permit",
                                    rawName: "v-permit",
                                    value: "/firmware/detailLine",
                                    expression: "'/firmware/detailLine'",
                                  },
                                ],
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "版本信息",
                                  placement: "bottom",
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("../../assets/iconfont/mess.png"),
                                    alt: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.detailed(scope.row)
                                    },
                                  },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, prev, pager, next",
              "page-size": 10,
              total: _vm.total,
              "current-page": _vm.currentPage,
            },
            on: { "current-change": _vm.changepage },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "版本信息",
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { staticClass: "form", attrs: { model: _vm.form } },
            [
              _c(
                "el-form-item",
                {
                  attrs: { label: "版本号", "label-width": _vm.formLabelWidth },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", readonly: true },
                    model: {
                      value: _vm.info.firmwareVersion,
                      callback: function ($$v) {
                        _vm.$set(_vm.info, "firmwareVersion", $$v)
                      },
                      expression: "info.firmwareVersion",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: { label: "修改点", "label-width": _vm.formLabelWidth },
                },
                [
                  _c("p", { staticClass: "exchange" }, [
                    _vm._v(_vm._s(_vm.info.modifyPoint)),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "影响范围",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", readonly: true },
                    model: {
                      value: _vm.info.impactScope,
                      callback: function ($$v) {
                        _vm.$set(_vm.info, "impactScope", $$v)
                      },
                      expression: "info.impactScope",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "版本目的",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", readonly: true },
                    model: {
                      value: _vm.info.versionPurpose,
                      callback: function ($$v) {
                        _vm.$set(_vm.info, "versionPurpose", $$v)
                      },
                      expression: "info.versionPurpose",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "升级范围",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", readonly: true },
                    model: {
                      value: _vm.info.upgrageScope,
                      callback: function ($$v) {
                        _vm.$set(_vm.info, "upgrageScope", $$v)
                      },
                      expression: "info.upgrageScope",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "合入版本",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("div", { staticClass: "version-content" }, [
                    _c("div", { staticClass: "version-title" }, [
                      _c("div", [_vm._v("模块名字")]),
                      _vm._v(" "),
                      _c("div", [_vm._v("版本号")]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "version" }, [
                      _c("div", { staticClass: "version-list" }, [
                        _c("div", [_vm._v("app")]),
                        _vm._v(" "),
                        _c("div", [_vm._v(_vm._s(_vm.app))]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "version-list" }, [
                        _c("div", [_vm._v("app_lib")]),
                        _vm._v(" "),
                        _c("div", [_vm._v(_vm._s(_vm.app_lib))]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "version-list" }, [
                        _c("div", [_vm._v("system")]),
                        _vm._v(" "),
                        _c("div", [_vm._v(_vm._s(_vm.system))]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "version-list" }, [
                        _c("div", [_vm._v("system_lib")]),
                        _vm._v(" "),
                        _c("div", [_vm._v(_vm._s(_vm.system_lib))]),
                      ]),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "打包时间",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", readonly: true },
                    model: {
                      value: _vm.info.packagingTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.info, "packagingTime", $$v)
                      },
                      expression: "info.packagingTime",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "版本信息",
            visible: _vm.dialogVisibles,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisibles = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { staticClass: "form", attrs: { model: _vm.form } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "版本号：",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", readonly: true },
                    model: {
                      value: _vm.info.version,
                      callback: function ($$v) {
                        _vm.$set(_vm.info, "version", $$v)
                      },
                      expression: "info.version",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "修改点：",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("p", { staticClass: "exchange" }, [
                    _vm._v(_vm._s(_vm.info.modifyPoint)),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "影响范围：",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", readonly: true },
                    model: {
                      value: _vm.info.impactScope,
                      callback: function ($$v) {
                        _vm.$set(_vm.info, "impactScope", $$v)
                      },
                      expression: "info.impactScope",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "版本目的：",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", readonly: true },
                    model: {
                      value: _vm.info.versionPurpose,
                      callback: function ($$v) {
                        _vm.$set(_vm.info, "versionPurpose", $$v)
                      },
                      expression: "info.versionPurpose",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "升级范围：",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", readonly: true },
                    model: {
                      value: _vm.info.upgrageScope,
                      callback: function ($$v) {
                        _vm.$set(_vm.info, "upgrageScope", $$v)
                      },
                      expression: "info.upgrageScope",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "打包时间：",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", readonly: true },
                    model: {
                      value: _vm.info.packagingTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.info, "packagingTime", $$v)
                      },
                      expression: "info.packagingTime",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisibles = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "上传固件包",
            visible: _vm.dialogFormVisible,
            "close-on-click-modal": false,
            width: "32%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
            close: _vm.uploadClose,
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "uploads",
              attrs: {
                model: _vm.uploads,
                rules: _vm.uploadRules,
                "status-icon": true,
                "show-message": false,
                "hide-required-asterisk": "",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    label: "请输入密码：",
                    "label-width": _vm.formLabelWidth,
                    prop: "password",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      autocomplete: "off",
                      type: "password",
                      disabled: _vm.passdis,
                    },
                    model: {
                      value: _vm.uploads.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.uploads, "password", $$v)
                      },
                      expression: "uploads.password",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: { label: "跳过：", "label-width": _vm.formLabelWidth },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择", prop: "isStride" },
                      model: {
                        value: _vm.uploads.isStride,
                        callback: function ($$v) {
                          _vm.$set(_vm.uploads, "isStride", $$v)
                        },
                        expression: "uploads.isStride",
                      },
                    },
                    _vm._l(_vm.isStrides, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "设备型号：",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      attrs: { width: "50%" },
                      on: { change: this.typeChange },
                      model: {
                        value: _vm.checkList,
                        callback: function ($$v) {
                          _vm.checkList = $$v
                        },
                        expression: "checkList",
                      },
                    },
                    _vm._l(_vm.type, function (item, i) {
                      return _c(
                        "el-checkbox",
                        {
                          key: i,
                          staticStyle: { width: "200px", height: "30px" },
                          attrs: { label: item.value, name: "type" },
                        },
                        [_vm._v(_vm._s(item.valueCn))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "上传固件包：",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "upload-wrapper" },
                    [
                      _c("input", {
                        ref: "uploadFile",
                        staticClass: "upload-file",
                        attrs: {
                          id: "file",
                          type: "file",
                          name: "file",
                          multiple: "",
                        },
                        on: { change: _vm.submitUpload },
                      }),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "exprot-btn",
                          attrs: {
                            size: "small",
                            type: "primary",
                            disabled: this.disab,
                          },
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.buttonUpload))])]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.savedisab },
                  on: { click: _vm.save },
                },
                [_vm._v(_vm._s(_vm.saveButton))]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.uploadClose } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "跳过",
            visible: _vm.dialogStride,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogStride = $event
            },
            close: _vm.strideClose,
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.form } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "版本号：",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", disabled: true },
                    model: {
                      value: _vm.strideForm.firmwareVersion,
                      callback: function ($$v) {
                        _vm.$set(_vm.strideForm, "firmwareVersion", $$v)
                      },
                      expression: "strideForm.firmwareVersion",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "是否跳过：",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.strideForm.isStride,
                        callback: function ($$v) {
                          _vm.$set(_vm.strideForm, "isStride", $$v)
                        },
                        expression: "strideForm.isStride",
                      },
                    },
                    _vm._l(_vm.isStrides, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.saveButtonDisable },
                  on: { click: _vm.strideSave },
                },
                [_vm._v("保存")]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.strideClose } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改固件设备",
            visible: _vm.dialogFromsVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFromsVisible = $event
            },
            close: _vm.infoClose,
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.form } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "版本号：",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", disabled: true },
                    model: {
                      value: _vm.info.version,
                      callback: function ($$v) {
                        _vm.$set(_vm.info, "version", $$v)
                      },
                      expression: "info.version",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "设备型号：",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                _vm._l(_vm.type, function (item, i) {
                  return _c(
                    "el-checkbox-group",
                    {
                      key: i,
                      on: { change: _vm.typeChange },
                      model: {
                        value: _vm.checkList,
                        callback: function ($$v) {
                          _vm.checkList = $$v
                        },
                        expression: "checkList",
                      },
                    },
                    [
                      _c(
                        "el-checkbox",
                        {
                          attrs: {
                            label: item.value,
                            name: "type",
                            disabled: !!(
                              _vm.oldCheckList.indexOf(item.value) !== -1
                            ),
                          },
                        },
                        [_vm._v(_vm._s(item.valueCn))]
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: { click: _vm.exchangeSave },
                },
                [_vm._v("保存")]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.infoClose } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "test",
          attrs: {
            title: "审核测试",
            visible: _vm.dialogTest,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogTest = $event
            },
            close: _vm.testClose,
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.testloading,
                  expression: "testloading",
                },
              ],
            },
            [
              _c("div", { staticClass: "test-head" }, [
                _c("ul", [
                  _c(
                    "li",
                    [
                      _c("h5", [_vm._v("设备型号")]),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请选择", filterable: "" },
                          on: {
                            focus: function ($event) {
                              return _vm.getFirst()
                            },
                            change: _vm.handleFirst,
                          },
                          model: {
                            value: _vm.test.equipmentModel,
                            callback: function ($$v) {
                              _vm.$set(_vm.test, "equipmentModel", $$v)
                            },
                            expression: "test.equipmentModel",
                          },
                        },
                        _vm._l(_vm.model, function (item) {
                          return _c("el-option", {
                            key: item.equipmentModel,
                            attrs: {
                              label: item.equipmentModelName,
                              value: item.equipmentModel,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _vm.test.equipmentModel != ""
                        ? _c("h5", [_vm._v("设备用途")])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.test.equipmentModel != ""
                        ? _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请选择" },
                              on: {
                                focus: function ($event) {
                                  return _vm.getSecond()
                                },
                              },
                              model: {
                                value: _vm.test.equipmentPurpose,
                                callback: function ($$v) {
                                  _vm.$set(_vm.test, "equipmentPurpose", $$v)
                                },
                                expression: "test.equipmentPurpose",
                              },
                            },
                            _vm._l(_vm.purpose, function (item) {
                              return _c("el-option", {
                                key: item.equipmentPurposeValue,
                                attrs: {
                                  label: item.equipmentPurposeName,
                                  value: item.equipmentPurposeValue,
                                },
                              })
                            }),
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c("h5", [_vm._v("设备号")]),
                      _vm._v(" "),
                      _c("el-input", {
                        model: {
                          value: _vm.test.equipmentNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.test, "equipmentNumber", $$v)
                          },
                          expression: "test.equipmentNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.testSearch },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.testEmpty },
                        },
                        [_vm._v("清空")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.addTest },
                        },
                        [_vm._v("添加")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "overflow-y": "auto", height: "505px" } },
                [
                  _c(
                    "div",
                    { staticClass: "tab" },
                    [
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: _vm.testData,
                            border: "",
                            "highlight-current-row": "",
                          },
                          on: { "selection-change": _vm.handleSelectionChange },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { type: "index", index: _vm.indexMethod },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { type: "selection", width: "55" },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "equipmentNumber",
                              width: "230",
                              label: "设备号",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "equipmentModel",
                              label: "设备型号",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "equipmentPurpose",
                              label: "设备用途",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "table" },
                    [
                      _c("h5", [_vm._v("审核任务列表")]),
                      _vm._v(" "),
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { data: _vm.testTable, border: "" },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { type: "index", index: _vm.indexMethod },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { prop: "equipmentNumber", label: "设备号" },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "firmwareVersion",
                              label: "当前版本",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "updateStatus",
                              label: "更新固件标识",
                              formatter: _vm.statusData,
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "操作" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    scope.row["updateStatus"] != 2
                                      ? _c(
                                          "el-tooltip",
                                          {
                                            directives: [
                                              {
                                                name: "permit",
                                                rawName: "v-permit",
                                                value:
                                                  "/firmware/auditTest/delete",
                                                expression:
                                                  "'/firmware/auditTest/delete'",
                                              },
                                            ],
                                            staticClass: "item",
                                            attrs: {
                                              effect: "dark",
                                              content: "删除",
                                              placement: "bottom",
                                            },
                                          },
                                          [
                                            _c("img", {
                                              staticStyle: {
                                                width: "15px",
                                                "vertical-align": "middle",
                                                cursor: "pointer",
                                              },
                                              attrs: {
                                                src: require("../../assets/iconfont/delete.png"),
                                                alt: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.testDele(scope.row)
                                                },
                                              },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.testClose } },
                [_vm._v("完 成")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "审核",
            visible: _vm.testDialog,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.testDialog = $event
            },
            close: _vm.close,
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.form } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "审核标识：",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      model: {
                        value: _vm.tests.auditFlag,
                        callback: function ($$v) {
                          _vm.$set(_vm.tests, "auditFlag", $$v)
                        },
                        expression: "tests.auditFlag",
                      },
                    },
                    _vm._l(_vm.testOption, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: { label: "备注：", "label-width": _vm.formLabelWidth },
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: "100", autocomplete: "off" },
                    model: {
                      value: _vm.tests.auditInfo,
                      callback: function ($$v) {
                        _vm.$set(_vm.tests, "auditInfo", $$v)
                      },
                      expression: "tests.auditInfo",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: { click: _vm.testSave },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }