var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "equimentVersion" },
    [
      _c("div", { staticClass: "equimentVersion-head" }, [
        _c("ul", [
          _c(
            "li",
            [
              _c("h5", [_vm._v("依赖的系统版本")]),
              _vm._v(" "),
              _c("el-input", {
                model: {
                  value: _vm.form.dependencySystem,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "dependencySystem", $$v)
                  },
                  expression: "form.dependencySystem",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("版本状态")]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status",
                  },
                },
                _vm._l(_vm.isstatus, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("版本号")]),
              _vm._v(" "),
              _c("el-input", {
                model: {
                  value: _vm.form.version,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "version", $$v)
                  },
                  expression: "form.version",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("类型")]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "type", $$v)
                    },
                    expression: "form.type",
                  },
                },
                _vm._l(_vm.types, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "function" }, [
        _c("ol", [
          _c("li", { on: { click: _vm.search } }, [
            _c("img", {
              attrs: {
                src: require("../../assets/iconfont/find.png"),
                alt: "",
              },
            }),
            _vm._v(" "),
            _c("span", [_vm._v("查询")]),
          ]),
          _vm._v(" "),
          _c("li", { on: { click: _vm.empty } }, [
            _c("img", {
              attrs: {
                src: require("../../assets/iconfont/empty.png"),
                alt: "",
              },
            }),
            _vm._v(" "),
            _c("span", [_vm._v("清空")]),
          ]),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/android/info/upload",
                  expression: "'/android/info/upload'",
                },
              ],
              on: { click: _vm.upload },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/upload.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("上传")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/android/info/audit",
                  expression: "'/android/info/audit'",
                },
              ],
              on: { click: _vm.check },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/check.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("审核")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/android/info/delete",
                  expression: "'/android/info/delete'",
                },
              ],
              on: { click: _vm.dele },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/delete.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("删除")]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tab" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                border: "",
                "highlight-current-row": "",
              },
              on: { "current-change": _vm.handleClick },
            },
            [
              _c("el-table-column", {
                attrs: { type: "index", index: _vm.indexMethod },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "type",
                  formatter: _vm.equimentType,
                  label: "类型",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", { attrs: { prop: "area", label: "区域" } }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "custom", label: "定制" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "platform", label: "平台" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "status",
                  formatter: _vm.statusInfo,
                  label: "版本状态",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "fileName",
                  width: "200px",
                  "show-overflow-tooltip": "",
                  label: "文件名称",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "version",
                  width: "310px",
                  "show-overflow-tooltip": "",
                  label: "版本号",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "dependencySystem",
                  width: "260px",
                  "show-overflow-tooltip": "",
                  label: "依赖的系统版本",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "name", label: "应用名称" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "packageName",
                  width: "200px",
                  "show-overflow-tooltip": "",
                  label: "包名",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "size", width: "100px", label: "大小" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "versionNo",
                  "show-overflow-tooltip": "",
                  width: "100px",
                  label: "内部版本号",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "auditFlag",
                  "show-overflow-tooltip": "",
                  formatter: _vm.audit,
                  label: "审核结果",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "auditInfo", label: "审核内容" },
              }),
              _vm._v("、\n      "),
              _c("el-table-column", {
                attrs: { prop: "auditUser", label: "审核人" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "auditTime", width: "200px", label: "审核时间" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "opUser", label: "上传人员" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "opTime", width: "200px", label: "上传时间" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, prev, pager, next",
              "page-size": 10,
              total: _vm.total,
              "current-page": _vm.currentPage,
            },
            on: { "current-change": _vm.changepage },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "审核",
            visible: _vm.testDialog,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.testDialog = $event
            },
            close: _vm.close,
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.form } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "审核结果：",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      model: {
                        value: _vm.checks.auditFlag,
                        callback: function ($$v) {
                          _vm.$set(_vm.checks, "auditFlag", $$v)
                        },
                        expression: "checks.auditFlag",
                      },
                    },
                    _vm._l(_vm.testOption, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "审核内容",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: "100", autocomplete: "off" },
                    model: {
                      value: _vm.checks.auditInfo,
                      callback: function ($$v) {
                        _vm.$set(_vm.checks, "auditInfo", $$v)
                      },
                      expression: "checks.auditInfo",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: { click: _vm.testSave },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "上传",
            visible: _vm.dialogFormVisible,
            "close-on-click-modal": false,
            width: "32%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
            close: _vm.uploadClose,
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "uploads",
              attrs: {
                model: _vm.form,
                "status-icon": true,
                "show-message": false,
                "hide-required-asterisk": "",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: { label: "上传：", "label-width": _vm.formLabelWidth },
                },
                [
                  _c(
                    "div",
                    { staticClass: "upload-wrapper" },
                    [
                      _c("input", {
                        ref: "uploadFile",
                        staticClass: "upload-file",
                        attrs: {
                          id: "file",
                          type: "file",
                          name: "file",
                          multiple: "",
                        },
                        on: { change: _vm.submitUpload },
                      }),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "exprot-btn",
                          attrs: {
                            size: "small",
                            type: "primary",
                            disabled: this.disab,
                          },
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.buttonUpload))])]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.useflag
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "应用名称",
                            "label-width": _vm.formLabelWidth,
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.uploads.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.uploads, "name", $$v)
                              },
                              expression: "uploads.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "版本号",
                            "label-width": _vm.formLabelWidth,
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.uploads.version,
                              callback: function ($$v) {
                                _vm.$set(_vm.uploads, "version", $$v)
                              },
                              expression: "uploads.version",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "内部版本号",
                            "label-width": _vm.formLabelWidth,
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.uploads.versionNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.uploads, "versionNo", $$v)
                              },
                              expression: "uploads.versionNo",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "包名",
                            "label-width": _vm.formLabelWidth,
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.uploads.packageName,
                              callback: function ($$v) {
                                _vm.$set(_vm.uploads, "packageName", $$v)
                              },
                              expression: "uploads.packageName",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "系统",
                            "label-width": _vm.formLabelWidth,
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              model: {
                                value: _vm.dependencySystem,
                                callback: function ($$v) {
                                  _vm.dependencySystem = $$v
                                },
                                expression: "dependencySystem",
                              },
                            },
                            _vm._l(_vm.versions, function (item) {
                              return _c("el-option", {
                                key: item.version,
                                attrs: {
                                  label: item.version,
                                  value: item.version,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("el-progress", {
                attrs: {
                  "text-inside": true,
                  "stroke-width": 15,
                  percentage: _vm.progress,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.savedisab },
                  on: { click: _vm.save },
                },
                [_vm._v(_vm._s(_vm.saveButton))]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.uploadClose } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }