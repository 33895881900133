var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("h5", [_vm._v("角色名称")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "head" },
        [
          _c("el-input", {
            staticClass: "name",
            model: {
              value: _vm.param.roleName,
              callback: function ($$v) {
                _vm.$set(_vm.param, "roleName", $$v)
              },
              expression: "param.roleName",
            },
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticStyle: { "margin-left": "20px" },
              attrs: { type: "primary" },
              on: { click: _vm.search },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.search($event)
                },
              },
            },
            [_vm._v("查询")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.empty } },
            [_vm._v("清空")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/role/add",
                  expression: "'/role/add'",
                },
              ],
              attrs: { type: "primary" },
              on: { click: _vm.handleAddRole },
            },
            [_vm._v("新增角色")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%", "margin-top": "30px" },
          attrs: { data: _vm.rolesLists, border: "" },
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              "show-overflow-tooltip": "",
              label: "角色名称",
              width: "220",
              prop: "name",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              "show-overflow-tooltip": "",
              label: "拥有资源",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(
                    scope.row.resourceVOList,
                    function (item, index) {
                      return _c(
                        "span",
                        { key: index, staticClass: "resource" },
                        [_vm._v(_vm._s(item.name) + ",")]
                      )
                    }
                  )
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "所属角色",
              prop: "createIdentity",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "function-icon" },
                      [
                        _c(
                          "el-tooltip",
                          {
                            directives: [
                              {
                                name: "permit",
                                rawName: "v-permit",
                                value: "/role/update",
                                expression: "'/role/update'",
                              },
                            ],
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "修改",
                              placement: "bottom",
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("../../assets/iconfont/amend.png"),
                                alt: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.change(scope.row)
                                },
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-tooltip",
                          {
                            directives: [
                              {
                                name: "permit",
                                rawName: "v-permit",
                                value: "/role/delete",
                                expression: "'/role/delete'",
                              },
                            ],
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "删除",
                              placement: "bottom",
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("../../assets/iconfont/delete.png"),
                                alt: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.dele(scope.row)
                                },
                              },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, prev, pager, next",
              "page-size": 10,
              total: _vm.total,
              "current-page": _vm.currentPage,
            },
            on: { "current-change": _vm.changepage },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            title: _vm.dialogType === "edit" ? "Edit Role" : "新增角色",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.close,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "role",
              attrs: {
                model: _vm.role,
                "label-width": "80px",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "角色名称" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "25", type: "text" },
                    model: {
                      value: _vm.role.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.role, "name", $$v)
                      },
                      expression: "role.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "所属角色",
                    "label-width": "80px",
                    "label-position": "left",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择" },
                      on: {
                        change: function ($event) {
                          return _vm.getSub()
                        },
                      },
                      model: {
                        value: _vm.role.pid,
                        callback: function ($$v) {
                          _vm.$set(_vm.role, "pid", $$v)
                        },
                        expression: "role.pid",
                      },
                    },
                    _vm._l(_vm.roleList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "备注" } },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "100",
                      autosize: { minRows: 2, maxRows: 4 },
                      type: "textarea",
                    },
                    model: {
                      value: _vm.role.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.role, "remark", $$v)
                      },
                      expression: "role.remark",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "overflow-y": "auto", height: "450px" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "权限" } },
                    [
                      _c("el-tree", {
                        ref: "tree",
                        staticClass: "permission-tree",
                        attrs: {
                          data: _vm.routesData,
                          props: _vm.defaultProps,
                          "show-checkbox": "",
                          "node-key": "id",
                        },
                        on: { "check-change": _vm.handleCheckChange },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "text-align": "right" } },
            [
              _c(
                "el-button",
                { attrs: { type: "danger" }, on: { click: _vm.close } },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: { click: _vm.confirmRole },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改角色",
            visible: _vm.dialogFormVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
            close: _vm.close,
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.form } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "角色名称",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: "25", autocomplete: "off" },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "所属角色",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { disabled: "", placeholder: "请选择" },
                      model: {
                        value: _vm.form.pid,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "pid", $$v)
                        },
                        expression: "form.pid",
                      },
                    },
                    _vm._l(_vm.roleList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "备注", "label-width": _vm.formLabelWidth } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "100", autocomplete: "off" },
                    model: {
                      value: _vm.form.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remark", $$v)
                      },
                      expression: "form.remark",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "权限", "label-width": _vm.formLabelWidth } },
                [
                  _c(
                    "div",
                    { staticStyle: { "overflow-y": "auto", height: "440px" } },
                    [
                      _c("el-tree", {
                        ref: "tree",
                        staticClass: "permission-tree",
                        attrs: {
                          data: _vm.routesData,
                          props: _vm.defaultProps,
                          "show-checkbox": "",
                          "node-key": "id",
                          "default-expanded-keys": _vm.idList,
                          "default-checked-keys": _vm.idList,
                        },
                        on: { "check-change": _vm.getChecked },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: { click: _vm.sure },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "授权",
            visible: _vm.dialogFormsVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormsVisible = $event
            },
            close: _vm.close,
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "overflow-y": "auto", height: "500px" } },
            [
              _c(
                "el-form",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "所属角色", "label-width": "70px" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请选择" },
                          on: {
                            change: function ($event) {
                              return _vm.getSub2()
                            },
                          },
                          model: {
                            value: _vm.form.pid,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "pid", $$v)
                            },
                            expression: "form.pid",
                          },
                        },
                        _vm._l(_vm.roleList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-tree", {
                    ref: "accreditTree",
                    staticClass: "permission-tree",
                    attrs: {
                      data: _vm.routesData,
                      props: _vm.defaultProps,
                      "show-checkbox": "",
                      "node-key": "id",
                      "default-expanded-keys": _vm.idList2,
                      "default-checked-keys": _vm.idList2,
                    },
                    on: { "check-change": _vm.accreditCheck },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: { click: _vm.keep },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }