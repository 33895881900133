"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-manage/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addFeedvackType = addFeedvackType;
exports.dataManipulation = dataManipulation;
exports.deletFeedbackType = deletFeedbackType;
exports.getFeedbackTypeById = getFeedbackTypeById;
exports.getFeedbackTypeList = getFeedbackTypeList;
exports.updateFeedbackType = updateFeedbackType;
var _request = _interopRequireDefault(require("@/utils/request"));
//查询问题反馈类型
function getFeedbackTypeList(data) {
  return (0, _request.default)({
    url: 'system/business/feedback/type/query',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//添加问题反馈类型
function addFeedvackType(data) {
  return (0, _request.default)({
    url: 'system/business/feedback/type/save',
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//根据id查询问题反馈类型
function getFeedbackTypeById(typeId) {
  return (0, _request.default)({
    url: 'system/business/feedback/type/query/' + typeId,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//修改问题反馈类型
function updateFeedbackType(data) {
  return (0, _request.default)({
    url: 'system/business/feedback/type/update',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//修改问题反馈类型
function deletFeedbackType(typeId) {
  return (0, _request.default)({
    url: 'system/business/feedback/type/delete/' + typeId,
    method: 'delete',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

/**
 * 前端刷新公共方法
 */
function dataManipulation(tableData, rowData, valueCn, valueEn, valueJa, opTime) {
  tableData.map(function (element) {
    //如果是单选的直接用if判断id是否相等就可以了,如果是复选框的就需要嵌套for循环判断
    if (element.typeId == rowData.typeId) {
      if (valueCn != null && valueCn != "") {
        element.valueCn = valueCn;
      }
      if (valueEn != null && valueEn != "") {
        element.valueEn = valueEn;
      }
      if (valueJa != null && valueJa != "") {
        element.valueJa = valueJa;
      }
      element.opUser = window.localStorage.getItem("username");
      if (opTime != null && opTime != "") {
        element.opTime = opTime;
      }
    }
  });
  return tableData;
}