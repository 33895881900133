var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dealer" },
    [
      _c("div", { staticClass: "dealer-head" }, [
        _c("ul", [
          _c(
            "li",
            [
              _c("h5", [_vm._v("经销商名称")]),
              _vm._v(" "),
              _c("el-input", {
                model: {
                  value: _vm.tab.dealerName,
                  callback: function ($$v) {
                    _vm.$set(_vm.tab, "dealerName", $$v)
                  },
                  expression: "tab.dealerName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("联系人")]),
              _vm._v(" "),
              _c("el-input", {
                model: {
                  value: _vm.tab.contact,
                  callback: function ($$v) {
                    _vm.$set(_vm.tab, "contact", $$v)
                  },
                  expression: "tab.contact",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("联系电话")]),
              _vm._v(" "),
              _c("el-input", {
                model: {
                  value: _vm.tab.phone,
                  callback: function ($$v) {
                    _vm.$set(_vm.tab, "phone", $$v)
                  },
                  expression: "tab.phone",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("经销商类型")]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.tab.dealerType,
                    callback: function ($$v) {
                      _vm.$set(_vm.tab, "dealerType", $$v)
                    },
                    expression: "tab.dealerType",
                  },
                },
                _vm._l(_vm.options, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.valueCn, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("li", [
            _c("h5", [_vm._v("起始时间")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "block" },
              [
                _c("el-date-picker", {
                  attrs: {
                    format: "yyyy/MM/dd",
                    "value-format": "yyyy-MM-dd",
                    type: "date",
                    placeholder: "选择日期",
                  },
                  model: {
                    value: _vm.tab.applicationTimeStart,
                    callback: function ($$v) {
                      _vm.$set(_vm.tab, "applicationTimeStart", $$v)
                    },
                    expression: "tab.applicationTimeStart",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("h5", [_vm._v("截止时间")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "block" },
              [
                _c("el-date-picker", {
                  attrs: {
                    type: "date",
                    format: "yyyy/MM/dd",
                    "value-format": "yyyy-MM-dd",
                    placeholder: "选择日期",
                    editable: false,
                  },
                  model: {
                    value: _vm.tab.applicationTimeEnd,
                    callback: function ($$v) {
                      _vm.$set(_vm.tab, "applicationTimeEnd", $$v)
                    },
                    expression: "tab.applicationTimeEnd",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "function" }, [
        _c("ol", [
          _c("li", { on: { click: _vm.search } }, [
            _c("img", {
              attrs: {
                src: require("../../assets/iconfont/find.png"),
                alt: "",
              },
            }),
            _vm._v(" "),
            _c("span", [_vm._v("查询")]),
          ]),
          _vm._v(" "),
          _c("li", { on: { click: _vm.empty } }, [
            _c("img", {
              attrs: {
                src: require("../../assets/iconfont/empty.png"),
                alt: "",
              },
            }),
            _vm._v(" "),
            _c("span", [_vm._v("清空")]),
          ]),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/dealer/add",
                  expression: "'/dealer/add'",
                },
              ],
              on: { click: _vm.add },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/add.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("添加")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/dealer/update",
                  expression: "'/dealer/update'",
                },
              ],
              on: { click: _vm.change },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/amend.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("修改")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/dealer/delete",
                  expression: "'/dealer/delete'",
                },
              ],
              on: { click: _vm.dele },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/delete.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("删除")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/dealer/model/download",
                  expression: "'/dealer/model/download'",
                },
              ],
              on: { click: _vm.download },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/down.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("模板下载")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/dealer/import",
                  expression: "'/dealer/import'",
                },
              ],
            },
            [
              _c("div", { staticClass: "upload-wrapper" }, [
                _c("img", {
                  attrs: {
                    src: require("../../assets/iconfont/to.png"),
                    alt: "",
                  },
                }),
                _vm._v(" "),
                _c("input", {
                  ref: "uploadFile",
                  staticClass: "upload-file",
                  attrs: {
                    id: "file",
                    type: "file",
                    name: "file",
                    multiple: "",
                  },
                  on: { change: _vm.submitUpload },
                }),
                _vm._v(" "),
                _c("span", [_vm._v("导入")]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/dealer/export",
                  expression: "'/dealer/export'",
                },
              ],
              on: { click: _vm.derives },
            },
            [_vm._m(0)]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tab" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                border: "",
                "highlight-current-row": "",
              },
              on: { "current-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "index", index: _vm.indexMethod },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "dealerName", label: "经销商名称" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "dealerType", label: "经销商类型" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "contact", label: "联系人", width: "150px" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "phone", label: "联系电话", width: "160px" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "address", label: "仓库地址" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "applicationTime",
                  label: "申请时间",
                  width: "160px",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "salesman", label: "业务员", width: "150px" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, prev, pager, next",
              "page-size": 10,
              total: _vm.total,
              "current-page": _vm.currentPage,
            },
            on: { "current-change": _vm.changepage },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加用户",
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.close,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "status-icon": true,
                "show-message": false,
                "hide-required-asterisk": "",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "经销商类别",
                    "label-width": _vm.formLabelWidth,
                    prop: "dealerType",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.dealerType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "dealerType", $$v)
                        },
                        expression: "form.dealerType",
                      },
                    },
                    _vm._l(_vm.options, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "经销商名称",
                    "label-width": _vm.formLabelWidth,
                    prop: "dealerName",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", maxlength: "64" },
                    model: {
                      value: _vm.form.dealerName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "dealerName", $$v)
                      },
                      expression: "form.dealerName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "联系人",
                    "label-width": _vm.formLabelWidth,
                    prop: "contact",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", maxlength: "10" },
                    model: {
                      value: _vm.form.contact,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "contact", $$v)
                      },
                      expression: "form.contact",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "联系电话",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", maxlength: "15" },
                    model: {
                      value: _vm.form.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "phone", $$v)
                      },
                      expression: "form.phone",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "仓库地址",
                    "label-width": _vm.formLabelWidth,
                    prop: "address",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", maxlength: "50" },
                    model: {
                      value: _vm.form.address,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "address", $$v)
                      },
                      expression: "form.address",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "业务员",
                    "label-width": _vm.formLabelWidth,
                    prop: "salesman",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", maxlength: "10" },
                    model: {
                      value: _vm.form.salesman,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "salesman", $$v)
                      },
                      expression: "form.salesman",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "申请时间",
                    "label-width": _vm.formLabelWidth,
                    prop: "applicationTime",
                  },
                },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      format: "yyyy/MM/dd",
                      "value-format": "yyyy-MM-dd",
                      type: "date",
                      placeholder: "选择日期",
                      editable: false,
                    },
                    model: {
                      value: _vm.form.applicationTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "applicationTime", $$v)
                      },
                      expression: "form.applicationTime",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "备注", "label-width": _vm.formLabelWidth } },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", maxlength: "100" },
                    model: {
                      value: _vm.form.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remark", $$v)
                      },
                      expression: "form.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: { click: _vm.save },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改用户",
            visible: _vm.dialogVisibles,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisibles = $event
            },
            close: _vm.close,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "status-icon": true,
                "show-message": false,
                "hide-required-asterisk": "",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "经销商类别",
                    "label-width": _vm.formLabelWidth,
                    prop: "dealerType",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.dealerType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "dealerType", $$v)
                        },
                        expression: "form.dealerType",
                      },
                    },
                    _vm._l(_vm.options, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "经销商名称",
                    "label-width": _vm.formLabelWidth,
                    prop: "dealerName",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", maxlength: "64" },
                    model: {
                      value: _vm.form.dealerName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "dealerName", $$v)
                      },
                      expression: "form.dealerName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "联系人",
                    "label-width": _vm.formLabelWidth,
                    prop: "contact",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", maxlength: "10" },
                    model: {
                      value: _vm.form.contact,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "contact", $$v)
                      },
                      expression: "form.contact",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "联系电话",
                    "label-width": _vm.formLabelWidth,
                    prop: "phone",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", maxlength: "15" },
                    model: {
                      value: _vm.form.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "phone", $$v)
                      },
                      expression: "form.phone",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "仓库地址",
                    "label-width": _vm.formLabelWidth,
                    prop: "address",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", maxlength: "50" },
                    model: {
                      value: _vm.form.address,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "address", $$v)
                      },
                      expression: "form.address",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "业务员",
                    "label-width": _vm.formLabelWidth,
                    prop: "salesman",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", maxlength: "10" },
                    model: {
                      value: _vm.form.salesman,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "salesman", $$v)
                      },
                      expression: "form.salesman",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "申请时间",
                    "label-width": _vm.formLabelWidth,
                    prop: "applicationTime",
                  },
                },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      format: "yyyy/MM/dd",
                      "value-format": "yyyy-MM-dd",
                      type: "date",
                      placeholder: "选择日期",
                      editable: false,
                    },
                    model: {
                      value: _vm.form.applicationTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "applicationTime", $$v)
                      },
                      expression: "form.applicationTime",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "备注", "label-width": _vm.formLabelWidth } },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", maxlength: "255" },
                    model: {
                      value: _vm.form.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remark", $$v)
                      },
                      expression: "form.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: { click: _vm.keep },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "upload-wrapper" }, [
      _c("img", {
        attrs: { src: require("../../assets/iconfont/derive.png"), alt: "" },
      }),
      _vm._v(" "),
      _c("span", [_vm._v("导出")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }