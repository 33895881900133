"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-manage/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.dataManipulation = dataManipulation;
exports.deployAndroidTask = deployAndroidTask;
exports.getAndroidTask = getAndroidTask;
exports.searchapkversion = searchapkversion;
exports.searchsystem = searchsystem;
exports.undoAndroidTask = undoAndroidTask;
var _request = _interopRequireDefault(require("@/utils/request"));
//查询系统任务发布
function getAndroidTask(data) {
  return (0, _request.default)({
    url: 'system/business/android/task/query',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//撤销
function undoAndroidTask(data) {
  return (0, _request.default)({
    url: 'system/business/android/task/undo',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//发布
function deployAndroidTask(data) {
  return (0, _request.default)({
    url: 'system/business/android/task/deploy',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//根据区域、定制、平台、任务类型查询依赖系统版本
function searchsystem(data) {
  return (0, _request.default)({
    url: 'system/business/android/info/dependency/system/version',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//根据依赖系统版本、任务类型查询所有的应用及系统
function searchapkversion(data) {
  return (0, _request.default)({
    url: 'system/business/android/info/system/apk/version',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
/**
 * 前端刷新公共方法
 */
function dataManipulation(tableData, rowData, status, opTime) {
  tableData.map(function (element) {
    //如果是单选的直接用if判断id是否相等就可以了,如果是复选框的就需要嵌套for循环判断!
    if (element.id == rowData.id) {
      if (status != null && status != "") {
        element.status = status;
      }
      element.revokeUser = window.localStorage.getItem("username");
      if (opTime != null && opTime != "") {
        element.revokeTime = opTime;
      }
    }
  });
  return tableData;
}