"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-manage/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getinout = getinout;
var _request = _interopRequireDefault(require("@/utils/request"));
//出入库记录
function getinout(data) {
  return (0, _request.default)({
    url: 'system/business/inout/record/query',
    method: 'post',
    headers: {
      "Content-Type": 'application/json',
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}