"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-manage/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.auditAndroidInfo = auditAndroidInfo;
exports.beforeupload = beforeupload;
exports.dataManipulation = dataManipulation;
exports.deleteAndroidInfo = deleteAndroidInfo;
exports.getAndroidInfo = getAndroidInfo;
exports.getUpload = getUpload;
exports.searchapk = searchapk;
exports.upload = upload;
var _request = _interopRequireDefault(require("@/utils/request"));
//查询所有经销商信息
function getAndroidInfo(data) {
  return (0, _request.default)({
    url: 'system/business/android/info/query',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//删除
function deleteAndroidInfo(id) {
  return (0, _request.default)({
    url: 'system/business/android/info/delete/' + id,
    method: 'DELETE',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//审核
function auditAndroidInfo(data) {
  return (0, _request.default)({
    url: 'system/business/android/info/audit',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//上传检查
function beforeupload(data) {
  return (0, _request.default)({
    url: 'system/business/android/info/upload/check',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data,
    timeout: 1200000
  });
}
//根据区域和定制查询所有系统版本
function searchapk(data) {
  return (0, _request.default)({
    url: 'system/business/android/info/system/version',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
//上传
function upload(data) {
  return (0, _request.default)({
    url: 'system/business/android/info/upload',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data,
    timeout: 1200000
  });
}

//上传固件包
function getUpload(data) {
  return (0, _request.default)({
    url: 'base/upload/apply',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

/**
 * 前端刷新公共方法
 *
 */
function dataManipulation(tableData, rowData, status, auditFlag, auditInfo, isStride, auditTime) {
  // console.log(isStride)
  tableData.map(function (element) {
    //如果是单选的直接用if判断id是否相等就可以了,如果是复选框的就需要嵌套for循环 判断
    if (element.id == rowData.id) {
      if (auditInfo != null && auditInfo != "") {
        element.auditInfo = auditInfo;
      }
      if (status != null && status != "") {
        element.status = status;
      }
      if (auditFlag != null && auditFlag != "") {
        element.auditFlag = auditFlag;
      }
      // element.isStride = "0";
      // if (isStride != null && isStride != "") {
      //     if (isStride == 0 || isStride == "0") {
      //         element.isStride = "0";
      //     }
      //     if (isStride == 1 || isStride == "1") {
      //         element.isStride = "1";
      //     }
      // }
      if (auditTime != null && auditTime != "") {
        element.auditUser = window.localStorage.getItem("username");
        element.auditTime = auditTime;
      }
    }
  });
  return tableData;
}