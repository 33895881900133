var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-container" },
    [
      _c(
        "div",
        { staticClass: "login" },
        [
          _c(
            "el-form",
            {
              ref: "loginForm",
              staticClass: "login-form",
              attrs: {
                model: _vm.loginForm,
                rules: _vm.loginRules,
                "auto-complete": "on",
                "label-position": "left",
              },
            },
            [
              _c("div", { staticClass: "logo" }, [
                _c("img", {
                  attrs: {
                    src: require("../../assets/img/logo1.png"),
                    alt: "",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "title-container" }, [
                _c("h3", { staticClass: "title" }, [
                  _vm._v("Supernote 管理平台"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "username" } },
                [
                  _c(
                    "span",
                    { staticClass: "svg-container" },
                    [_c("svg-icon", { attrs: { "icon-class": "user" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-input", {
                    ref: "username",
                    attrs: {
                      placeholder: "用户名",
                      name: "username",
                      type: "text",
                      tabindex: "1",
                      "auto-complete": "off",
                    },
                    model: {
                      value: _vm.loginForm.username,
                      callback: function ($$v) {
                        _vm.$set(_vm.loginForm, "username", $$v)
                      },
                      expression: "loginForm.username",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "password" } },
                [
                  _c(
                    "span",
                    { staticClass: "svg-container" },
                    [_c("svg-icon", { attrs: { "icon-class": "password" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-input", {
                    key: _vm.passwordType,
                    ref: "password",
                    attrs: {
                      type: _vm.passwordType,
                      placeholder: "密码",
                      name: "password",
                      tabindex: "2",
                      "auto-complete": "on",
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleLogin($event)
                      },
                    },
                    model: {
                      value: _vm.loginForm.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.loginForm, "password", $$v)
                      },
                      expression: "loginForm.password",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "show-pwd", on: { click: _vm.showPwd } },
                    [
                      _c("svg-icon", {
                        attrs: {
                          "icon-class":
                            _vm.passwordType === "password"
                              ? "eye"
                              : "eye-open",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticStyle: {
                    width: "100%",
                    "margin-bottom": "30px",
                    "font-size": "18px",
                  },
                  attrs: { loading: _vm.loading, type: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.handleLogin($event)
                    },
                  },
                },
                [_vm._v("登录")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改密码",
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.close,
          },
        },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form } },
            [
              _c(
                "el-form-item",
                {
                  attrs: { label: "登录名", "label-width": _vm.formLabelWidth },
                },
                [
                  _c("el-input", {
                    attrs: { disabled: true },
                    model: {
                      value: _vm.form.username,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "username", $$v)
                      },
                      expression: "form.username",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "原密码",
                    "label-width": _vm.formLabelWidth,
                    prop: "password1",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", type: "password" },
                    model: {
                      value: _vm.form.oldPassword,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "oldPassword", $$v)
                      },
                      expression: "form.oldPassword",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "新密码",
                    "label-width": _vm.formLabelWidth,
                    prop: "newPassword",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", type: "password" },
                    model: {
                      value: _vm.form.newPassword,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "newPassword", $$v)
                      },
                      expression: "form.newPassword",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "确认密码",
                    "label-width": _vm.formLabelWidth,
                    prop: "repeatPassword",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", type: "password" },
                    model: {
                      value: _vm.form.repeatPassword,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "repeatPassword", $$v)
                      },
                      expression: "form.repeatPassword",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("修改")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }