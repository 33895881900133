"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-manage/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.backout = backout;
exports.dataManipulation = dataManipulation;
exports.equipmentPurpostate = equipmentPurpostate;
exports.getAllinfo = getAllinfo;
exports.getEquipment = getEquipment;
exports.getStockInfo = getStockInfo;
exports.headlthystate = headlthystate;
exports.inModeldownload = inModeldownload;
exports.outModeldownload = outModeldownload;
exports.stock = stock;
exports.stockAll = stockAll;
exports.stockExport = stockExport;
exports.toLead = toLead;
exports.toLeadall = toLeadall;
exports.undoAlldownload = undoAlldownload;
exports.undoModeldownload = undoModeldownload;
var _request = _interopRequireDefault(require("@/utils/request"));
//根据条件查询用户设备
function getEquipment(data) {
  return (0, _request.default)({
    url: 'equipment/query/user/equipment',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//查询所有库存信息
function getAllinfo(data) {
  return (0, _request.default)({
    url: 'system/business/stock/query',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//查询所有库存信息
function getStockInfo(data) {
  return (0, _request.default)({
    url: 'system/business/stock/query/no/task',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//单台入库
function toLead(data) {
  return (0, _request.default)({
    url: 'system/business/stock/in',
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//批量入库
function toLeadall(equipmentNumber, data) {
  return (0, _request.default)({
    url: 'system/business/stock/in/import/' + equipmentNumber,
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token'),
      "Content-Type": 'multipart/form-data'
    },
    data: data
  });
}

//单台出库
function stock(data) {
  return (0, _request.default)({
    url: 'system/business/stock/out',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//批量出库
function stockAll(data) {
  return (0, _request.default)({
    url: 'system/business/stock/out/import',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//撤销
function backout(equipmentNumber) {
  return (0, _request.default)({
    url: 'system/business/stock/undo/' + equipmentNumber,
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//导出
function stockExport(data) {
  return (0, _request.default)({
    url: 'system/business/stock/export',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    responseType: 'blob',
    data: data
  });
}

//修改设备健康状态
function headlthystate(data) {
  return (0, _request.default)({
    url: 'system/business/stock/update/healthystate',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
function equipmentPurpostate(data) {
  return (0, _request.default)({
    url: 'system/business/stock/update/purpose',
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//批入模板下载
function inModeldownload() {
  return (0, _request.default)({
    url: 'system/business/stock/download/in/xlsx',
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    responseType: 'blob'
  });
}

//批出模板下载
function outModeldownload() {
  return (0, _request.default)({
    url: 'system/business/stock/download/out/xlsx',
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    responseType: 'blob'
  });
}

//批撤模板下载
function undoModeldownload() {
  return (0, _request.default)({
    url: 'system/business/stock/download/undo/xlsx',
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    responseType: 'blob'
  });
}
//批量撤销
function undoAlldownload(data) {
  return (0, _request.default)({
    url: 'system/business/stock/undo/import',
    method: 'post',
    headers: {
      "Content-Type": 'multipart/form-data',
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

/**
 * 前端刷新公共方法
 */
function dataManipulation(tableData, rowData, healthyState, equipmentPurpose, opTime) {
  tableData.map(function (element) {
    //如果是单选的直接用if判断id是否相等就 可以了,如果是复选框的就需要嵌套for循环判断
    if (element.equipmentNumber == rowData.equipmentNumber) {
      if (healthyState != null && healthyState != "") {
        if (healthyState == 0 && healthyState == "0") {
          element.healthyState = "正常";
        }
        if (healthyState == 1 && healthyState == "1") {
          element.healthyState = "维修中";
        }
        if (healthyState == 2 && healthyState == "2") {
          element.healthyState = "报废";
        }
      }
      if (equipmentPurpose != null && equipmentPurpose != "") {
        if (equipmentPurpose == 0 && equipmentPurpose == "0") {
          element.equipmentPurpose = "测试设备";
        }
        if (equipmentPurpose == 1 && equipmentPurpose == "1") {
          element.equipmentPurpose = "售卖设备";
        }
      }
      element.opUser = window.localStorage.getItem("username");
      if (opTime != null && opTime != "") {
        element.opTime = opTime;
      }
    }
  });
  return tableData;
}