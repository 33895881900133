"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.number.constructor");
var _vueCron = require("vue-cron");
var _dispatcher = require("../../api/dispatcher.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    cron: _vueCron.cron
  },
  data: function data() {
    return {
      isDisable: false,
      isFlag: true,
      startValue: "",
      endValue: "",
      options: [{
        label: "启用",
        value: 0
      }, {
        label: "停用",
        value: 1
      }],
      tableData: [],
      gridData: [],
      dialogTableVisible: false,
      dialogVisible: false,
      loading: false,
      isRepeat: false,
      form: {
        name: "",
        pageNo: 1,
        pageSize: 10,
        sortField: "",
        sortRules: "",
        status: ""
      },
      cronPopover: false,
      cron: "",
      username: "",
      task: "",
      describe: "",
      multipleSelection: [],
      formLabelWidth: "120px",
      saveid: "",
      total: 0,
      currentPage: 1,
      totals: 0,
      pageNo: 1,
      localId: ""
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    changeCron: function changeCron(val) {
      this.task = val;
    },
    getList: function getList() {
      var _this = this;
      this.loading = true;
      setTimeout(function () {
        (0, _dispatcher.getDislist)(_this.form).then(function (res) {
          _this.tableData = res.voList;
          _this.total = Number(res.total);
          _this.loading = false;
          _this.isRepeat = false;
        });
      }, 500);
    },
    stateFormat: function stateFormat(row) {
      if (row.status === "0") {
        return "启用";
      } else {
        return "停用";
      }
    },
    //执行结果
    resultState: function resultState(row) {
      if (row.result === "0") {
        return "失败";
      } else {
        return "成功";
      }
    },
    //分页
    changepage: function changepage(currentPage) {
      this.currentPage = currentPage;
      this.form.pageNo = currentPage;
      this.getList();
    },
    changepages: function changepages(currentPage) {
      var _this2 = this;
      this.pageNo = currentPage;
      (0, _dispatcher.searchDislist)({
        jsrq: this.endValue,
        ksrq: this.startValue,
        pageNo: this.pageNo,
        pageSize: 10,
        sortField: "",
        sortRules: "",
        taskId: this.localId
      }).then(function (res) {
        _this2.gridData = res.voList;
        _this2.totals = res.total;
      });
    },
    //查询
    search: function search() {
      var _this3 = this;
      this.loading = true;
      setTimeout(function () {
        (0, _dispatcher.getDislist)({
          name: _this3.form.name,
          pageNo: 1,
          pageSize: 10,
          sortField: "",
          sortRules: "",
          status: _this3.form.status
        }).then(function (res) {
          _this3.form.pageNo = 1;
          _this3.tableData = res.voList;
          _this3.total = Number(res.total);
          _this3.currentPage = 1;
          _this3.loading = false;
        });
      }, 500);
    },
    //查看日志
    see: function see(v) {
      var _this4 = this;
      this.dialogTableVisible = true;
      this.localId = v.id;
      (0, _dispatcher.searchDislist)({
        jsrq: "",
        ksrq: "",
        pageNo: this.pageNo,
        pageSize: 10,
        sortField: "",
        sortRules: "",
        taskId: this.localId
      }).then(function (res) {
        _this4.gridData = res.voList;
        _this4.totals = res.total;
      });
    },
    //查看日志里面进行查询
    searchLog: function searchLog() {
      var _this5 = this;
      (0, _dispatcher.searchDislist)({
        jsrq: this.endValue,
        ksrq: this.startValue,
        pageNo: 1,
        pageSize: 10,
        sortField: "",
        sortRules: "",
        taskId: this.localId
      }).then(function (res) {
        _this5.gridData = res.voList;
        _this5.currentPage = 1;
        _this5.totals = res.total;
      });
    },
    //清空
    empty: function empty() {
      this.form.name = "";
      this.form.status = "";
      this.getList();
    },
    //清空查看日志中的日期
    emptys: function emptys() {
      this.startValue = "";
      this.endValue = "";
      this.searchLog();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 查找有没有选中数据，没有则返回false
    findSelectData: function findSelectData() {
      if (!this.multipleSelection.length) {
        // 无选中的数据
        return false;
      } else {
        // 有选中的数据
        return true;
      }
    },
    //信息提示框
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    },
    //启用
    start: function start() {
      var _this6 = this;
      if (!this.findSelectData()) {
        this.msgTip("error", "至少选择一条数据");
      } else {
        if (this.isFlag) {
          this.isFlag = false;
          var arr = [];
          this.multipleSelection.map(function (res) {
            arr.push(JSON.stringify(res.id));
          });
          this.isRepeat = true;
          (0, _dispatcher.startDislist)({
            idList: arr,
            status: 0
          }).then(function (res) {
            if (res.success) {
              _this6.$message({
                type: "success",
                message: "启用成功",
                center: true
              });
              (0, _dispatcher.dataManipulation)(_this6.tableData, _this6.multipleSelection, "0");
              _this6.isFlag = true;
              //this.getList();
            } else {
              _this6.$message({
                type: "warning",
                message: res.errorMsg,
                center: true
              });
              _this6.isRepeat = false;
              _this6.isFlag = true;
            }
          });
        }
      }
    },
    // 停用
    disableItem: function disableItem() {
      var _this7 = this;
      if (!this.findSelectData()) {
        this.msgTip("error", "至少选择一条数据");
      } else {
        if (this.isFlag) {
          this.isFlag = false;
          var arr = [];
          this.multipleSelection.map(function (res) {
            arr.push(res.id);
          });
          this.isRepeat = true;
          (0, _dispatcher.startDislist)({
            idList: arr,
            status: 1
          }).then(function (res) {
            if (res.success) {
              _this7.$message({
                type: "success",
                message: "停用成功",
                center: true
              });
              (0, _dispatcher.dataManipulation)(_this7.tableData, _this7.multipleSelection, "1");
              _this7.isFlag = true;
              //this.getList();
            } else {
              _this7.$message({
                type: "warning",
                message: res.errorMsg,
                center: true
              });
              _this7.isRepeat = false;
              _this7.isFlag = true;
            }
          });
        }
      }
    },
    //修改
    change: function change(v) {
      var _this8 = this;
      this.dialogVisible = true;
      this.isDisable = false;
      (0, _dispatcher.amendsDislist)(v.id).then(function (res) {
        _this8.username = res.name;
        _this8.task = res.cron;
        _this8.describe = res.remark;
      });
      this.saveid = v.id;
    },
    save: function save() {
      var _this9 = this;
      this.isRepeat = true;
      this.isDisable = true;
      (0, _dispatcher.amendDislist)({
        cron: this.task,
        id: this.saveid,
        remark: this.describe
      }).then(function (res) {
        if (res.success) {
          _this9.$message({
            type: "success",
            message: "修改成功",
            center: true
          });
          _this9.dialogVisible = false;
          _this9.getList();
        } else {
          _this9.$message({
            type: "warning",
            message: res.errorMsg,
            center: true
          });
          _this9.isRepeat = false;
          _this9.isDisable = false;
        }
      });
    },
    //执行
    execute: function execute(v) {
      var _this10 = this;
      if (this.isFlag) {
        this.isFlag = false;
        this.isRepeat = true;
        (0, _dispatcher.executeTime)(v.id).then(function (res) {
          if (res.success) {
            _this10.$message({
              type: "success",
              message: "已执行定时任务",
              center: true
            });
            _this10.isFlag = true;
          } else {
            _this10.$message({
              type: "warning",
              message: res.errorMsg,
              center: true
            });
            _this10.isRepeat = false;
            _this10.isFlag = true;
          }
        });
      }
    },
    indexMethod: function indexMethod(index) {
      return index + 1;
    },
    handleClick: function handleClick(row) {
      console.log(row);
    }
  }
};