"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-manage/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addList = addList;
exports.addUser = addUser;
exports.amendList = amendList;
exports.amendUser = amendUser;
exports.authorUser = authorUser;
exports.deleteList = deleteList;
exports.deleteUser = deleteUser;
exports.getAllList = getAllList;
exports.getAlluser = getAlluser;
exports.getList = getList;
exports.getResourcePid = getResourcePid;
exports.getRole = getRole;
exports.getSearch = getSearch;
exports.getSubRole = getSubRole;
exports.idList = idList;
exports.idUser = idUser;
exports.queryRole = queryRole;
exports.querySelectResource = querySelectResource;
var _request = _interopRequireDefault(require("@/utils/request"));
//角色管理

//获取列表树
function getList(id) {
  return (0, _request.default)({
    url: 'system/base/resource/role/' + id,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}
//获取所有列表树
function getAllList() {
  return (0, _request.default)({
    url: 'system/base/resource/all',
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//查询资源
// export function searchList() {
//     return request({
//         url: 'system/base/resource/{id}',
//         method: 'get'
//     })
// }

//添加资源
function addList(data) {
  return (0, _request.default)({
    url: 'system/base/resource',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//修改资源
function amendList(id, data) {
  return (0, _request.default)({
    url: 'system/base/resource/' + id,
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
//根据id查找资源
function idList(id) {
  return (0, _request.default)({
    url: 'system/base/resource/' + id,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//删除资源
function deleteList(id) {
  return (0, _request.default)({
    url: 'system/base/resource/' + id,
    method: 'DELETE',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//获取所有角色
function getRole(data) {
  return (0, _request.default)({
    url: 'system/base/role/resource',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//获取所有角色
function getAlluser() {
  return (0, _request.default)({
    url: 'system/base/role',
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//通过角色id查询子角色
function getSubRole() {
  return (0, _request.default)({
    url: 'system/base/role/subRole',
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}
//查询角色
function getSearch(data) {
  return (0, _request.default)({
    url: 'system/base/role/resource',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//添加角色
function addUser(data) {
  return (0, _request.default)({
    url: 'system/base/role',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data,
    timeout: 20000
  });
}

//根据角色ID查询角色信息接口
function idUser(id) {
  return (0, _request.default)({
    url: 'system/base/role/' + id,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//修改角色
function amendUser(id, data) {
  return (0, _request.default)({
    url: 'system/base/role/' + id,
    method: 'put',
    data: data,
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    timeout: 20000
  });
}

//根据角色ID获取选中资源
function querySelectResource(id) {
  return (0, _request.default)({
    url: 'system/base/role/resource/' + id,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}
function getResourcePid() {
  return (0, _request.default)({
    url: 'system/base/resource/pid',
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    timeout: 600000
  });
}

//授权
function authorUser(id, data) {
  return (0, _request.default)({
    url: 'system/base/role/resource/' + id,
    method: 'put',
    data: data,
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//删除
function deleteUser(id) {
  return (0, _request.default)({
    url: 'system/base/role/' + id,
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    method: 'delete'
  });
}
function queryRole() {
  return (0, _request.default)({
    url: 'system/base/role/query',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    method: 'get'
  });
}