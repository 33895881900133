var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "type" },
    [
      _c("div", { staticClass: "type-head" }, [
        _c("ul", [
          _c(
            "li",
            [
              _c("h5", [_vm._v("类型编号")]),
              _vm._v(" "),
              _c("el-input", {
                model: {
                  value: _vm.form.typeId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "typeId", $$v)
                  },
                  expression: "form.typeId",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.search } },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.empty } },
                [_vm._v("清空")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/feedback/type/add",
                  expression: "'/feedback/type/add'",
                },
              ],
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.add } },
                [_vm._v("添加")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/feedback/type/update",
                  expression: "'/feedback/type/update'",
                },
              ],
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.edit } },
                [_vm._v("修改")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/feedback/type/delete",
                  expression: "'/feedback/type/delete'",
                },
              ],
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.delet } },
                [_vm._v("删除")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加",
            visible: _vm.dialogFormVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
            close: function ($event) {
              return _vm.cancel("data")
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "data",
              attrs: {
                model: _vm.data,
                rules: _vm.rulesadd,
                "status-icon": true,
                "show-message": false,
                "hide-required-asterisk": "",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "类型编号",
                    "label-width": _vm.formLabelWidth,
                    required: "true",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "text",
                      autocomplete: "off",
                      maxlength: "20",
                      "show-word-limit": "true",
                    },
                    model: {
                      value: _vm.data.typeId,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "typeId", $$v)
                      },
                      expression: "data.typeId",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "中文描述",
                    "label-width": _vm.formLabelWidth,
                    required: "true",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      autocomplete: "off",
                      maxlength: "50",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.data.valueCn,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "valueCn", $$v)
                      },
                      expression: "data.valueCn",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "英文描述",
                    "label-width": _vm.formLabelWidth,
                    required: "true",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      autocomplete: "off",
                      maxlength: "50",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.data.valueEn,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "valueEn", $$v)
                      },
                      expression: "data.valueEn",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "日文描述",
                    "label-width": _vm.formLabelWidth,
                    prop: "valueJa",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      autocomplete: "off",
                      maxlength: "50",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.data.valueJa,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "valueJa", $$v)
                      },
                      expression: "data.valueJa",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.cancel("data")
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: {
                    click: function ($event) {
                      return _vm.save("data")
                    },
                  },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改",
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: function ($event) {
              return _vm.cancel("updateData")
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "updateData",
              attrs: {
                model: _vm.updateData,
                rules: _vm.rulesupdate,
                "status-icon": true,
                "show-message": false,
                "hide-required-asterisk": "",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "类型编号",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    attrs: { disabled: true, autocomplete: "off" },
                    model: {
                      value: _vm.updateData.typeId,
                      callback: function ($$v) {
                        _vm.$set(_vm.updateData, "typeId", $$v)
                      },
                      expression: "updateData.typeId",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "中文描述",
                    "label-width": _vm.formLabelWidth,
                    prop: "valueCn",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      autocomplete: "off",
                      maxlength: "50",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.updateData.valueCn,
                      callback: function ($$v) {
                        _vm.$set(_vm.updateData, "valueCn", $$v)
                      },
                      expression: "updateData.valueCn",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "英文描述",
                    "label-width": _vm.formLabelWidth,
                    prop: "valueEn",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      autocomplete: "off",
                      maxlength: "50",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.updateData.valueEn,
                      callback: function ($$v) {
                        _vm.$set(_vm.updateData, "valueEn", $$v)
                      },
                      expression: "updateData.valueEn",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "日文描述",
                    "label-width": _vm.formLabelWidth,
                    prop: "valueJa",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      autocomplete: "off",
                      maxlength: "50",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.updateData.valueJa,
                      callback: function ($$v) {
                        _vm.$set(_vm.updateData, "valueJa", $$v)
                      },
                      expression: "updateData.valueJa",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.cancel("updateData")
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: {
                    click: function ($event) {
                      return _vm.update("updateData")
                    },
                  },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tab" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                border: "",
                "highlight-current-row": "",
              },
              on: {
                "current-change": _vm.currentChange,
                "row-click": _vm.getRow,
              },
            },
            [
              _c("el-table-column", {
                attrs: { type: "index", index: _vm.indexMethod },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "typeId", label: "类型编号", width: "150px" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "valueCn", label: "中文描述" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "valueEn", label: "英文描述" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "valueJa", label: "日文描述" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "opUser", label: "操作员", width: "200px" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "opTime", label: "操作时间", width: "200px" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page" },
        [
          _c("el-pagination", {
            attrs: {
              "hide-on-single-page": _vm.value,
              "current-page": _vm.currentPage,
              "page-size": 10,
              layout: "total, prev, pager, next",
              total: _vm.total,
              background: "",
            },
            on: { "current-change": _vm.handleCurrentChange },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }