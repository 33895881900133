"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-manage/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/data/www/prod-cn-snote-manage/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _Breadcrumb = _interopRequireDefault(require("@/components/Breadcrumb"));
var _Hamburger = _interopRequireDefault(require("@/components/Hamburger"));
var _auth = require("@/utils/auth");
var _systemuser = require("../../api/systemuser.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      dialogVisible: false,
      form: {
        username: "",
        oldPassword: "",
        newPassword: "",
        repeatPassword: ""
      },
      formLabelWidth: '100px',
      pasdform: {
        oldPassword: [{
          required: true,
          trigger: "blur"
        }],
        newPassword: [{
          required: true,
          trigger: "blur"
        }],
        repeatPassword: [{
          required: true,
          trigger: "blur"
        }]
      }
    };
  },
  components: {
    Breadcrumb: _Breadcrumb.default,
    Hamburger: _Hamburger.default
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["sidebar", "avatar"])),
  methods: {
    toggleSideBar: function toggleSideBar() {
      this.$store.dispatch("app/toggleSideBar");
    },
    logout: function logout() {
      // console.log("22222222");
      // await this.$store.dispatch('user/logout')
      (0, _auth.removeToken)();
      this.$router.push("/login");
      location.reload();
    },
    //修改密码
    close: function close() {
      this.dialogVisible = false;
      this.$refs.form.resetFields();
    },
    pasd: function pasd() {
      this.dialogVisible = true;
      this.form.username = window.localStorage.getItem('username');
    },
    save: function save() {
      var _this = this;
      (0, _systemuser.checkOldPwd)({
        oldPwd: this.form.oldPassword,
        password: "1"
      }).then(function (res) {
        if (!res.success) {
          _this.$message({
            type: "error",
            message: "原密码不一致",
            center: true
          });
          return;
        } else {
          if (!/^(?![A-Z]+$)(?![a-z]+$)(?!\d+$)(?![\W_]+$)\S{6,20}$/.test(_this.form.newPassword)) {
            _this.$message({
              type: "error",
              message: "请输入密码为6到20位且由数字大小写字母组成",
              center: true
            });
            return;
          }
          if (_this.form.newPassword != _this.form.repeatPassword) {
            _this.$message({
              type: "error",
              message: "确认密码与新密码不一样",
              center: true
            });
            return;
          }
          (0, _systemuser.amendPasw)({
            oldPwd: _this.form.oldPassword,
            password: _this.form.newPassword
          }).then(function (res) {
            if (res.success) {
              _this.$message({
                type: "success",
                message: "修改成功",
                center: true
              });
              _this.dialogVisible = false;
            } else {
              _this.$message({
                type: "error",
                message: res.errorMsg,
                center: true
              });
            }
          });
        }
      });
    }
  }
};