"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _firwaretask = require("../../api/firwaretask.js");
var _dictionary = require("../../api/dictionary.js");
var _firmwarepair = require("../../api/firmwarepair.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      isDisable: false,
      options: [{
        value: 0,
        label: "否"
      }, {
        value: 1,
        label: "是"
      }],
      isStrides: [{
        value: 0,
        label: "否"
      }, {
        value: 1,
        label: "是"
      }],
      type: [],
      tableData: [],
      rowData: [],
      form: {
        beginTime: "",
        endTime: "",
        equipmentModel: "",
        firmwareVersion: "",
        isHistory: "",
        isStride: "",
        pageNo: 1,
        pageSize: 10
      },
      total: 0,
      currentPage: 1,
      //发布
      dialogFromVisible: false,
      formLabelWidth: "100px",
      publice: {
        firmwareVersion: "",
        equipmentPurpose: "",
        equipmentModel: ""
      },
      version: [],
      model: [],
      purpose: [],
      publiceRules: {
        firmwareVersion: [{
          required: true,
          trigger: "blur"
        }],
        equipmentModel: [{
          required: true,
          trigger: "blur"
        }],
        equipmentPurpose: [{
          required: true,
          trigger: "blur"
        }]
      },
      alonePublice: {
        firmwareVersion: "",
        equipmentNumber: ""
      },
      aloneRules: {
        firmwareVersion: [{
          required: true,
          trigger: "blur"
        }],
        equipmentNumber: [{
          required: true,
          trigger: "blur"
        }]
      },
      //刷机
      dialogFromsVisible: false,
      radio: "1",
      isFlag: true,
      flag: false,
      loading: false,
      isRepeat: false
    };
  },
  created: function created() {
    var _this = this;
    this.getList();
    (0, _firmwarepair.getLanList)({
      name: "EQUIPMENT_MODEL",
      value: ""
    }).then(function (res) {
      _this.type = res.dictionaryVOList;
    });
  },
  methods: {
    getList: function getList() {
      var _this2 = this;
      this.loading = true;
      setTimeout(function () {
        (0, _firwaretask.getTask)(_this2.form).then(function (res) {
          _this2.tableData = res.voList;
          _this2.total = res.total;
          _this2.loading = false;
          _this2.isRepeat = false;
        });
      }, 500);
    },
    getRow: function getRow(val) {
      this.rowData = val;
      this.current = val;
    },
    changepage: function changepage(currentPage) {
      this.currentPage = currentPage;
      this.form.pageNo = currentPage;
      this.getList();
    },
    stateFormat: function stateFormat(row) {
      if (row.type === "1") {
        return "审核测试";
      } else if (row.type === "2") {
        return "发布";
      } else {
        return "刷机";
      }
    },
    statusFormat: function statusFormat(row) {
      if (row.status === "0") {
        return "正常";
      } else if (row.status === "1") {
        return "撤销";
      }
    },
    historyFormat: function historyFormat(row) {
      if (row.isHistory === "0") {
        return "否";
      } else if (row.isHistory === "1") {
        return "是";
      }
    },
    isStrideFormat: function isStrideFormat(row) {
      if (row.isStride === "0") {
        return "否";
      } else if (row.isStride === "1") {
        return "是";
      }
    },
    //查询
    search: function search() {
      var _this3 = this;
      this.loading = true;
      setTimeout(function () {
        (0, _firwaretask.getTask)({
          beginTime: _this3.form.beginTime,
          endTime: _this3.form.endTime,
          equipmentModel: _this3.form.equipmentModel,
          firmwareVersion: _this3.form.firmwareVersion,
          isHistory: _this3.form.isHistory,
          isStride: _this3.form.isStride,
          pageNo: 1,
          pageSize: 10
        }).then(function (res) {
          _this3.tableData = res.voList;
          _this3.total = res.total;
          _this3.currentPage = 1;
          _this3.loading = false;
        });
      }, 500);
    },
    //清空
    empty: function empty() {
      this.form.beginTime = "";
      this.form.endTime = "";
      this.form.equipmentModel = "";
      this.form.firmwareVersion = "";
      this.form.isHistory = "";
      this.form.isStride = "";
      this.getList();
    },
    //弹框关闭
    close: function close() {
      this.dialogFromVisible = false;
      this.$refs.publice.resetFields();
    },
    flagclose: function flagclose() {
      this.dialogFromsVisible = false;
      this.$refs.publice.resetFields();
      this.$refs.alonePublice.resetFields();
    },
    //发布
    issue: function issue() {
      this.dialogFromVisible = true;
      this.isDisable = false;
    },
    getFirst: function getFirst() {
      var _this4 = this;
      (0, _firwaretask.getVersion)({
        status: 2
      }).then(function (res) {
        _this4.model = [];
        _this4.purpose = [];
        _this4.publice.equipmentModel = "";
        _this4.publice.equipmentPurpose = "";
        _this4.version = res.voList;
      });
    },
    handleFirst: function handleFirst(val) {
      this.publice.firmwareVersion = val;
    },
    getSecond: function getSecond() {
      var _this5 = this;
      (0, _firwaretask.getModel)({
        firmwareVersion: this.publice.firmwareVersion
      }).then(function (res) {
        _this5.purpose = [];
        _this5.publice.equipmentPurpose = "";
        _this5.model = res.voList;
      });
    },
    handleSecond: function handleSecond(val) {
      this.publice.equipmentModel = val;
    },
    getThree: function getThree() {
      var _this6 = this;
      (0, _firwaretask.getPurpose)(this.publice.equipmentModel).then(function (res) {
        _this6.purpose = res.voList;
      });
    },
    handleTree: function handleTree(val) {
      this.publice.equipmentPurpose = val;
    },
    release: function release() {
      var _this7 = this;
      this.$refs.publice.validate(function (valid) {
        if (valid) {
          // 表单验证通过之后的操作
          _this7.isRepeat = true;
          _this7.isDisable = true;
          (0, _firwaretask.getDeploy)(_this7.publice).then(function (res) {
            if (res.success) {
              _this7.msgTip("success", "发布成功");
              _this7.dialogFromVisible = false;
              _this7.getList();
            } else {
              _this7.msgTip("error", res.errorMsg);
              _this7.isRepeat = false;
              _this7.isDisable = false;
            }
          });
        } else {
          _this7.msgTip("warning", "请填写完整");
          return false;
        }
      });
    },
    //撤销
    repeal: function repeal() {
      var _this8 = this;
      if (this.current == undefined || this.current == null) {
        this.msgTip("warning", "请选择需要撤销的数据");
      } else {
        if (this.current.status == "1") {
          this.msgTip("warning", "请选择未撤销的数据！");
        } else {
          this.$confirm("您是否要撤销当前选中的记录？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }).then(function () {
            _this8.isRepeat = true;
            (0, _firwaretask.getRepeal)(_this8.current.id).then(function (res) {
              if (res.success) {
                _this8.msgTip("success", "撤销成功");
                // 获取当前日期时间
                (0, _firwaretask.dataManipulation)(_this8.tableData, _this8.rowData, "1", getDate(), "1");
              } else {
                _this8.msgTip("error", res.errorMsg);
                _this8.isRepeat = false;
              }
            });
          });
        }
      }
    },
    //刷机
    roots: function roots() {
      this.dialogFromsVisible = true;
      this.isDisable = false;
    },
    changeRadio: function changeRadio(val) {
      if (val == "1") {
        this.isFlag = true;
        this.flag = false;
      } else {
        this.isFlag = false;
        this.flag = true;
        this.publice.firmwareVersion = "";
      }
    },
    aloneRoot: function aloneRoot() {
      var _this9 = this;
      this.$refs.alonePublice.validate(function (valid) {
        if (valid) {
          _this9.isDisable = true;
          // 表单验证通过之后的操作
          (0, _firwaretask.getFlash)(_this9.alonePublice).then(function (res) {
            if (res.success) {
              _this9.msgTip("success", "刷机成功");
              _this9.dialogFromsVisible = false;
              _this9.getList();
            } else {
              _this9.msgTip("error", res.errorMsg);
              _this9.isDisable = false;
            }
          });
        } else {
          _this9.msgTip("warning", "请填写完整");
          return false;
        }
      });
    },
    root: function root() {
      var _this10 = this;
      this.$refs.publice.validate(function (valid) {
        if (valid) {
          // 表单验证通过之后的操作
          (0, _firwaretask.getFlashAll)(_this10.publice).then(function (res) {
            if (res.success) {
              _this10.msgTip("success", "刷机成功");
              _this10.dialogFromsVisible = false;
              _this10.getList();
            } else {
              _this10.msgTip("error", res.errorMsg);
            }
          });
        } else {
          _this10.msgTip("warning", "请填写完整");
          return false;
        }
      });
    },
    //信息提示框
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    },
    indexMethod: function indexMethod(index) {
      return index + 1;
    },
    handleClick: function handleClick(row) {
      console.log(row);
    }
  }
};
/**
 * 获取当前时间
 */
function getDate() {
  var date = new Date();
  var seperator1 = "-";
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var strDate = date.getDate();
  var hours = date.getHours(); //获取当前小时数(0-23)
  var minutes = date.getMinutes(); //获取当前分钟数(0-59)
  var seconds = date.getSeconds();
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = "0" + strDate;
  }
  var currentdate = year + seperator1 + month + seperator1 + strDate + " " + hours + ":" + minutes + ":" + seconds;
  return currentdate;
}