"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-manage/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getBarter = getBarter;
exports.getExchange = getExchange;
exports.getReturn = getReturn;
var _request = _interopRequireDefault(require("@/utils/request"));
//查询所有退换货记录信息
function getExchange(data) {
  return (0, _request.default)({
    url: 'system/business/return/query',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//换货
function getBarter(data) {
  return (0, _request.default)({
    url: 'system/business/return/exchangeStock',
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//退货
function getReturn(data) {
  return (0, _request.default)({
    url: 'system/business/return/returnStock',
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}