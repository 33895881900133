"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-manage/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.amendEmail = amendEmail;
exports.amendPhone = amendPhone;
exports.dataManipulation = dataManipulation;
exports.exportRecord = exportRecord;
exports.freezes = freezes;
exports.getIduser = getIduser;
exports.getLogin = getLogin;
exports.getUsercontrol = getUsercontrol;
exports.getUserid = getUserid;
var _request = _interopRequireDefault(require("@/utils/request"));
//按条件查询所有用户登录记录
function getLogin(data) {
  return (0, _request.default)({
    url: 'user/query/loginRecord',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//根据用户id查询用户信息
function getIduser(id) {
  return (0, _request.default)({
    url: 'user/query',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token'),
      "User-ID": id
    }
  });
}

//按条件查询所有用户
function getUsercontrol(data) {
  return (0, _request.default)({
    url: 'user/query/all',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//根据用户id查询用户信息(内部调用)
function getUserid(userId) {
  return (0, _request.default)({
    url: 'user/query/' + userId,
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    method: 'get'
  });
}

//修改手机号
function amendPhone(id, data) {
  return (0, _request.default)({
    url: 'user/telephone',
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token'),
      "User-ID": id,
      "from": "manage"
    },
    data: data
  });
}

//修改邮箱
function amendEmail(id, data) {
  return (0, _request.default)({
    url: 'user/email',
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token'),
      "User-ID": id,
      "from": "manage"
    },
    data: data
  });
}

//冻结或解冻用户
function freezes(data) {
  return (0, _request.default)({
    url: 'user/freeze',
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//登录设备统计报表
function exportRecord(data) {
  return (0, _request.default)({
    url: 'user/export/login/record',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    responseType: 'blob',
    timeout: 360000,
    data: data
  });
}

/**
 * 前端刷新公共方法
 */
function dataManipulation(tableData, rowData, telephone, email, isNormal) {
  tableData.map(function (element) {
    rowData.map(function (row) {
      //如果是单选的直接用if判断id是否相等就可以了,如果是复选框的就需要嵌套for循环判断
      if (element.userId == row.userId) {
        if (telephone != null && telephone != "") {
          element.telephone = telephone;
        }
        if (email != null && email != "") {
          element.email = email;
        }
        if (isNormal != null && isNormal != "") {
          element.isNormal = isNormal;
        }
      }
    });
  });
  return tableData;
}