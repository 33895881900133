var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "appupload" },
    [
      _c("div", { staticClass: "appupload-head" }, [
        _c("ul", [
          _c(
            "li",
            [
              _c("h5", [_vm._v("应用名称")]),
              _vm._v(" "),
              _c("el-input", {
                model: {
                  value: _vm.param.appName,
                  callback: function ($$v) {
                    _vm.$set(_vm.param, "appName", $$v)
                  },
                  expression: "param.appName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("版本号")]),
              _vm._v(" "),
              _c("el-input", {
                model: {
                  value: _vm.param.appVersion,
                  callback: function ($$v) {
                    _vm.$set(_vm.param, "appVersion", $$v)
                  },
                  expression: "param.appVersion",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("连接环境")]),
              _vm._v(" "),
              _c("el-input", {
                model: {
                  value: _vm.param.appEnvironment,
                  callback: function ($$v) {
                    _vm.$set(_vm.param, "appEnvironment", $$v)
                  },
                  expression: "param.appEnvironment",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("是否强制")]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.param.updateFlag,
                    callback: function ($$v) {
                      _vm.$set(_vm.param, "updateFlag", $$v)
                    },
                    expression: "param.updateFlag",
                  },
                },
                _vm._l(_vm.updateFlags, function (item) {
                  return _c("el-option", {
                    key: item.updateFlag,
                    attrs: { label: item.label, value: item.updateFlag },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("审核标识")]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.param.auditingFlag,
                    callback: function ($$v) {
                      _vm.$set(_vm.param, "auditingFlag", $$v)
                    },
                    expression: "param.auditingFlag",
                  },
                },
                _vm._l(_vm.auditingFlags, function (item) {
                  return _c("el-option", {
                    key: item.auditingFlag,
                    attrs: { label: item.label, value: item.auditingFlag },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("发布标识")]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.param.deployFlag,
                    callback: function ($$v) {
                      _vm.$set(_vm.param, "deployFlag", $$v)
                    },
                    expression: "param.deployFlag",
                  },
                },
                _vm._l(_vm.deployFlags, function (item) {
                  return _c("el-option", {
                    key: item.deployFlag,
                    attrs: { label: item.label, value: item.deployFlag },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "function" }, [
        _c("ol", [
          _c("li", { on: { click: _vm.search } }, [
            _c("img", {
              attrs: {
                src: require("../../assets/iconfont/find.png"),
                alt: "",
              },
            }),
            _vm._v(" "),
            _c("span", [_vm._v("查询")]),
          ]),
          _vm._v(" "),
          _c("li", { on: { click: _vm.empty } }, [
            _c("img", {
              attrs: {
                src: require("../../assets/iconfont/empty.png"),
                alt: "",
              },
            }),
            _vm._v(" "),
            _c("span", [_vm._v("清空")]),
          ]),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/app/version/upload",
                  expression: "'/app/version/upload'",
                },
              ],
              on: { click: _vm.appupload },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/upload.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("版本上传")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/app/version/download",
                  expression: "'/app/version/download'",
                },
              ],
              on: { click: _vm.download },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/down.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("下载")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/app/version/undo",
                  expression: "'/app/version/undo'",
                },
              ],
              on: { click: _vm.undo },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/repeal.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("撤销")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/app/version/auditing",
                  expression: "'/app/version/auditing'",
                },
              ],
              on: { click: _vm.audit },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/audit.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("审核")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/app/version/gray",
                  expression: "'/app/version/gray'",
                },
              ],
              on: { click: _vm.grays },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/gray.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("灰度")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/app/version/deploy",
                  expression: "'/app/version/deploy'",
                },
              ],
              on: { click: _vm.deploy },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/log.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("发布")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/app/version/delete",
                  expression: "'/app/version/delete'",
                },
              ],
              on: { click: _vm.del },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/delete.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("删除")]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tab" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                border: "",
                "highlight-current-row": "",
              },
              on: { "row-click": _vm.getRow },
            },
            [
              _c("el-table-column", {
                attrs: { type: "index", index: _vm.indexMethod },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "appName", label: "应用名称" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "appVersion", label: "版本号", width: "120px" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "versionNo",
                  label: "内部版本号",
                  width: "100px",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "packageName", label: "应用包名" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "updateFlag",
                  label: "是否强制",
                  formatter: _vm.updateFlagFormat,
                  width: "100px",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "auditingFlag",
                  label: "审核标识",
                  formatter: _vm.auditingFlagFormat,
                  width: "120px",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "deployFlag",
                  label: "发布标识",
                  formatter: _vm.deployFlagFormat,
                  width: "120px",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "fileName", label: "文件名", width: "350px" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "appEnvironment",
                  label: "连接环境",
                  width: "150px",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "opUser", label: "操作员", width: "120px" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "opTime",
                  label: "上传时间",
                  "show-overflow-tooltip": "",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page" },
        [
          _c("el-pagination", {
            attrs: {
              "hide-on-single-page": _vm.value,
              "current-page": _vm.currentPage,
              "page-size": 10,
              layout: "total, prev, pager, next",
              total: _vm.total,
              background: "",
            },
            on: { "current-change": _vm.changepage },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "审核",
            visible: _vm.dialogFormVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-select",
            {
              staticStyle: { width: "100%" },
              attrs: { placeholder: "请选择" },
              model: {
                value: _vm.param.auditingFlag,
                callback: function ($$v) {
                  _vm.$set(_vm.param, "auditingFlag", $$v)
                },
                expression: "param.auditingFlag",
              },
            },
            _vm._l(_vm.auditingFlagStatus, function (item) {
              return _c("el-option", {
                key: item.auditingFlag,
                attrs: { label: item.label, value: item.auditingFlag },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: { click: _vm.save },
                },
                [_vm._v("保存")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "版本信息",
            visible: _vm.dialogFormsVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormsVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { staticClass: "form", attrs: { model: _vm.upload } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "是否强制",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.upload.updateFlag,
                        callback: function ($$v) {
                          _vm.$set(_vm.upload, "updateFlag", $$v)
                        },
                        expression: "upload.updateFlag",
                      },
                    },
                    _vm._l(_vm.update, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  attrs: {
                    label: "版本上传",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("div", { staticClass: "footer-container" }, [
                    _c(
                      "div",
                      { staticClass: "upload-wrapper" },
                      [
                        _c("input", {
                          ref: "uploadFile",
                          staticClass: "upload-file",
                          attrs: {
                            id: "file",
                            type: "file",
                            name: "file",
                            multiple: "",
                          },
                          on: { change: _vm.submitUpload },
                        }),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "exprot-btn",
                            attrs: { size: "small", type: "primary" },
                          },
                          [_c("span", [_vm._v(_vm._s(_vm.uplaodApp))])]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "progress" },
                      [
                        _c("el-progress", {
                          attrs: {
                            "text-inside": true,
                            "stroke-width": 15,
                            percentage: _vm.progress,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: { label: "文件名", "label-width": _vm.formLabelWidth },
                },
                [
                  _c("el-input", {
                    attrs: { disabled: true },
                    model: {
                      value: _vm.upload.fileName,
                      callback: function ($$v) {
                        _vm.$set(_vm.upload, "fileName", $$v)
                      },
                      expression: "upload.fileName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "应用名称",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: "30" },
                    model: {
                      value: _vm.upload.appName,
                      callback: function ($$v) {
                        _vm.$set(_vm.upload, "appName", $$v)
                      },
                      expression: "upload.appName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: { label: "版本号", "label-width": _vm.formLabelWidth },
                },
                [
                  _c("el-input", {
                    attrs: { disabled: true },
                    model: {
                      value: _vm.upload.appVersion,
                      callback: function ($$v) {
                        _vm.$set(_vm.upload, "appVersion", $$v)
                      },
                      expression: "upload.appVersion",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "应用包名",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    attrs: { disabled: true },
                    model: {
                      value: _vm.upload.packageName,
                      callback: function ($$v) {
                        _vm.$set(_vm.upload, "packageName", $$v)
                      },
                      expression: "upload.packageName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.disabledKeep },
                  on: { click: _vm.keep },
                },
                [_vm._v("保存")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormsVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "test",
          attrs: {
            title: "灰度",
            visible: _vm.dialogGray,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogGray = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { height: "510px", "overflow-y": "auto" } },
            [
              _c("div", { staticClass: "gray-head" }, [
                _c("ul", [
                  _c(
                    "li",
                    [
                      _c("h5", [_vm._v("手机号")]),
                      _vm._v(" "),
                      _c("el-input", {
                        on: { input: _vm.getInputGray },
                        model: {
                          value: _vm.gray.telephone,
                          callback: function ($$v) {
                            _vm.$set(_vm.gray, "telephone", $$v)
                          },
                          expression: "gray.telephone",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c("h5", [_vm._v("邮箱")]),
                      _vm._v(" "),
                      _c("el-input", {
                        on: { input: _vm.getInputGray },
                        model: {
                          value: _vm.gray.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.gray, "email", $$v)
                          },
                          expression: "gray.email",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            disabled: _vm.graySearchDisabled,
                          },
                          on: { click: _vm.graySearch },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.grayEmpty },
                        },
                        [_vm._v("清空")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.addGray },
                        },
                        [_vm._v("添加")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "tab" },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.grayData,
                        border: "",
                        "highlight-current-row": "",
                      },
                      on: { "selection-change": _vm.handleSelectionChange },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "index", index: _vm.indexMethod },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { type: "selection", width: "55" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { prop: "telephone", label: "手机号" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { prop: "email", label: "电子邮箱" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "table" },
                [
                  _c("h5", [_vm._v("灰度任务列表")]),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.grayTable, border: "" },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "index", index: _vm.indexMethod },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { prop: "telephone", label: "手机号" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { prop: "email", label: "电子邮箱" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "操作" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: "删除",
                                      placement: "bottom",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: {
                                        width: "15px",
                                        "vertical-align": "middle",
                                      },
                                      attrs: {
                                        src: require("../../assets/iconfont/delete.png"),
                                        alt: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.dele(scope.row)
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogGray = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }