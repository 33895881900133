"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-manage/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getequimentList = getequimentList;
var _request = _interopRequireDefault(require("@/utils/request"));
//设备版本
function getequimentList(data) {
  return (0, _request.default)({
    url: 'system/business/equipment/version/query',
    method: 'post',
    headers: {
      "Content-Type": 'application/json',
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data,
    timeout: 600000
  });
}

//