var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "androidtask" },
    [
      _c("div", { staticClass: "androidtask-head" }, [
        _c("ul", [
          _c(
            "li",
            [
              _c("h5", [_vm._v("设备用途")]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.equipmentPurpose,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "equipmentPurpose", $$v)
                    },
                    expression: "form.equipmentPurpose",
                  },
                },
                _vm._l(_vm.equipmentPurposes, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.valueCn, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("逻辑版本号")]),
              _vm._v(" "),
              _c("el-input", {
                model: {
                  value: _vm.form.logicVersion,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "logicVersion", $$v)
                  },
                  expression: "form.logicVersion",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("状态")]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status",
                  },
                },
                _vm._l(_vm.Isstatus, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("版本号")]),
              _vm._v(" "),
              _c("el-input", {
                model: {
                  value: _vm.form.version,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "version", $$v)
                  },
                  expression: "form.version",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("类型")]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "type", $$v)
                    },
                    expression: "form.type",
                  },
                },
                _vm._l(_vm.types, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "function" }, [
        _c("ol", [
          _c("li", { on: { click: _vm.search } }, [
            _c("img", {
              attrs: {
                src: require("../../assets/iconfont/find.png"),
                alt: "",
              },
            }),
            _vm._v(" "),
            _c("span", [_vm._v("查询")]),
          ]),
          _vm._v(" "),
          _c("li", { on: { click: _vm.empty } }, [
            _c("img", {
              attrs: {
                src: require("../../assets/iconfont/empty.png"),
                alt: "",
              },
            }),
            _vm._v(" "),
            _c("span", [_vm._v("清空")]),
          ]),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/android/task/test",
                  expression: "'/android/task/test'",
                },
              ],
              on: { click: _vm.test },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/audit.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("测试")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/android/task/deploy",
                  expression: "'/android/task/deploy'",
                },
              ],
              on: { click: _vm.issue },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/issue.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("发布")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/android/task/undo",
                  expression: "'/android/task/undo'",
                },
              ],
              on: { click: _vm.repeal },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/repeal.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("撤销")]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page", staticStyle: { "margin-top": "10px" } },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, prev, pager, next",
              "page-size": 20,
              total: _vm.total,
              "current-page": _vm.currentPage,
            },
            on: { "current-change": _vm.changepage },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tab" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: {
                width: "100%",
                "margin-top": "10px",
                "margin-bottom": "30px",
              },
              attrs: {
                data: _vm.tableData,
                border: "",
                "highlight-current-row": "",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "index", index: _vm.indexMethod },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "type",
                  formatter: _vm.equimentType,
                  label: "类型",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", { attrs: { prop: "area", label: "区域" } }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "custom", label: "定制" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "platform", label: "平台" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "equipmentPurpose", label: "设备用途" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "logicVersion",
                  width: "200px",
                  "show-overflow-tooltip": "",
                  label: "逻辑版本号",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "status",
                  formatter: _vm.statusInfo,
                  label: "版本状态",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "version",
                  width: "200px",
                  "show-overflow-tooltip": "",
                  label: "版本号",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "dependencySystem",
                  width: "200px",
                  "show-overflow-tooltip": "",
                  label: "依赖的系统版本",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "name", label: "应用名称" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "packageName",
                  width: "200px",
                  "show-overflow-tooltip": "",
                  label: "包名",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "versionNo",
                  width: "100px",
                  label: "内部版本号",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "createUser", label: "发布人" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  width: "200px",
                  label: "发布时间",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "revokeUser", label: "撤销人员" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "revokeTime",
                  width: "200px",
                  label: "撤销时间",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.titletype,
            visible: _vm.dialogFromVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFromVisible = $event
            },
            close: _vm.close,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "searchform",
              attrs: {
                model: _vm.searchform,
                rules: _vm.searchformRules,
                "status-icon": true,
                "show-message": false,
                "hide-required-asterisk": "",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "区域",
                    "label-width": _vm.formLabelWidth,
                    prop: "area",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择" },
                      on: { focus: _vm.area, change: _vm.handlearea },
                      model: {
                        value: _vm.searchform.area,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchform, "area", $$v)
                        },
                        expression: "searchform.area",
                      },
                    },
                    _vm._l(_vm.areas, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "定制",
                    "label-width": _vm.formLabelWidth,
                    prop: "custom",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择" },
                      on: { focus: _vm.custom, change: _vm.hanledcustom },
                      model: {
                        value: _vm.searchform.custom,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchform, "custom", $$v)
                        },
                        expression: "searchform.custom",
                      },
                    },
                    _vm._l(_vm.customs, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "平台",
                    "label-width": _vm.formLabelWidth,
                    prop: "platform",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择" },
                      on: { focus: _vm.platform, change: _vm.hanledplatform },
                      model: {
                        value: _vm.searchform.platform,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchform, "platform", $$v)
                        },
                        expression: "searchform.platform",
                      },
                    },
                    _vm._l(_vm.platforms, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "系统版本",
                    "label-width": _vm.formLabelWidth,
                    prop: "dependencySystem",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择" },
                      on: {
                        focus: _vm.isdependencySystem,
                        change: _vm.hanleddependencySystems,
                      },
                      model: {
                        value: _vm.dependencySystem,
                        callback: function ($$v) {
                          _vm.dependencySystem = $$v
                        },
                        expression: "dependencySystem",
                      },
                    },
                    _vm._l(_vm.dependencySystems, function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.sysflag
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "发布版本",
                        "label-width": _vm.formLabelWidth,
                      },
                    },
                    [
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { data: _vm.tableDatas },
                          on: { "selection-change": _vm.handleSelectionChange },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              type: "selection",
                              width: "55",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "类型",
                              formatter: _vm.equimentType,
                              prop: "type",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "应用名称",
                              prop: "name",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "包名",
                              prop: "packageName",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "版本号",
                              prop: "version",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "内部版本号",
                              prop: "versionNo",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "设备用途",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择", multiple: "" },
                      on: {
                        focus: _vm.isequipmentPurpose,
                        change: _vm.hanledequipmentPurposes,
                      },
                      model: {
                        value: _vm.equipmentPurpose,
                        callback: function ($$v) {
                          _vm.equipmentPurpose = $$v
                        },
                        expression: "equipmentPurpose",
                      },
                    },
                    _vm._l(_vm.equipmentPurposes, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "逻辑版本号",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    attrs: { type: "text" },
                    model: {
                      value: _vm.logicVersion,
                      callback: function ($$v) {
                        _vm.logicVersion = $$v
                      },
                      expression: "logicVersion",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: { click: _vm.release },
                },
                [_vm._v(_vm._s(_vm.buttontext))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }