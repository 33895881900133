"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-manage/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.awsUpload = awsUpload;
exports.ufileUpload = ufileUpload;
exports.upload = upload;
var _request = _interopRequireDefault(require("@/utils/request"));
function upload(data) {
  return (0, _request.default)({
    url: 'cms/picture/upload',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data,
    timeout: 3600000
  });
}
//aws上传
function awsUpload(data) {
  return (0, _request.default)({
    url: 'base/picture/aws/upload',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data,
    timeout: 3600000
  });
}
//ufile图片上传接口
function ufileUpload(data) {
  return (0, _request.default)({
    url: 'base/picture/upload',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data,
    timeout: 3600000
  });
}