"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-manage/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addDealer = addDealer;
exports.dataManipulation = dataManipulation;
exports.deleteDealer = deleteDealer;
exports.derive = derive;
exports.exchangeDealer = exchangeDealer;
exports.getDealer = getDealer;
exports.getDealerAll = getDealerAll;
exports.getDealerAlone = getDealerAlone;
exports.templateDownload = templateDownload;
exports.toLead = toLead;
var _request = _interopRequireDefault(require("@/utils/request"));
//查询经销商信息
function getDealer(data) {
  return (0, _request.default)({
    url: 'system/business/dealer/query',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
//查询所有经销商信息
function getDealerAll() {
  return (0, _request.default)({
    url: 'system/business/dealer/query/all',
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}
//查询单个经销商信息
function getDealerAlone(id) {
  return (0, _request.default)({
    url: 'system/business/dealer/query/' + id,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//添加
function addDealer(data) {
  return (0, _request.default)({
    url: 'system/business/dealer/save',
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//修改
function exchangeDealer(id, data) {
  return (0, _request.default)({
    url: 'system/business/dealer/update/' + id,
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//删除
function deleteDealer(id) {
  return (0, _request.default)({
    url: 'system/business/dealer/delete/' + id,
    method: 'DELETE',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//模板下载
function templateDownload() {
  return (0, _request.default)({
    url: 'system/business/dealer/download/xlsx',
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    responseType: 'blob'
  });
}

//导入
function toLead(data) {
  return (0, _request.default)({
    url: 'system/business/dealer/import',
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token'),
      "Content-Type": 'multipart/form-data'
    },
    data: data
  });
}

//导出
function derive(data) {
  return (0, _request.default)({
    url: 'system/business/dealer/export',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    responseType: 'blob',
    data: data
  });
}

/**
 * 前端刷新公共方法
 */
function dataManipulation(tableData, rowData, dealerType, dealerName, contact, phone, address, salesman, applicationTime, remark) {
  tableData.map(function (element) {
    //如果是单选的直接用if判断id是否相等就可以了,如果是复选框的就需要嵌套for循环判断
    if (element.id == rowData.id) {
      if (dealerType != null && dealerType != "") {
        if (dealerType == "1") {
          element.dealerType = "线上代理";
        } else if (dealerType == "2") {
          element.dealerType = "线下代理";
        } else if (dealerType == "3") {
          element.dealerType = "海外代理";
        }
      }
      if (dealerName != null && dealerName != "") {
        element.dealerName = dealerName;
      }
      if (contact != null && contact != "") {
        element.contact = contact;
      }
      if (phone != null && phone != "") {
        element.phone = phone;
      }
      if (address != null && address != "") {
        element.address = address;
      }
      if (salesman != null && salesman != "") {
        element.salesman = salesman;
      }
      if (applicationTime != null && applicationTime != "") {
        element.applicationTime = applicationTime;
      }
      if (remark != null && remark != "") {
        element.remark = remark;
      }
    }
  });
  return tableData;
}