"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _feedback = require("../../api/feedback.js");
var _fs = require("fs");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      isDisable: false,
      value: "",
      value1: "",
      value2: "",
      rowid: "",
      valueCn: "",
      valueEn: "",
      valueJa: "",
      currentPage: 0,
      total: 0,
      tableData: [],
      rowData: [],
      loading: false,
      isRepeat: false,
      form: {
        pageNo: 1,
        pageSize: 10,
        typeId: ""
      },
      data: {
        typeId: "",
        valueCn: "",
        valueEn: "",
        valueJa: ""
      },
      updateData: {
        typeId: "",
        valueCn: "",
        valueEn: "",
        valueJa: ""
      },
      updateRowData: {
        typeId: "",
        valueCn: "",
        valueEn: "",
        valueJa: ""
      },
      Ctor: "",
      dialogFormVisible: false,
      dialogVisible: false,
      formLabelWidth: "120px",
      rulesadd: {
        typeId: [{
          required: true,
          trigger: "blur"
        }],
        valueCn: [{
          required: true,
          trigger: "blur"
        }],
        valueEn: [{
          required: true,
          trigger: "blur"
        }],
        valueJa: [{
          required: true,
          trigger: "blur"
        }]
      },
      rulesupdate: {
        valueCn: [{
          required: true,
          trigger: "blur"
        }],
        valueEn: [{
          required: true,
          trigger: "blur"
        }],
        valueJa: [{
          required: true,
          trigger: "blur"
        }]
      }
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.loading = true;
      setTimeout(function () {
        (0, _feedback.getFeedbackTypeList)(_this.form).then(function (res) {
          _this.tableData = res.voList;
          _this.total = res.total;
          _this.loading = false;
          _this.isRepeat = false;
        });
      }, 500);
    },
    //添加
    add: function add() {
      this.dialogFormVisible = true;
      this.isDisable = false;
    },
    save: function save(formName) {
      var _this2 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this2.isRepeat = true;
          _this2.isDisable = true;
          (0, _feedback.addFeedvackType)(_this2.data).then(function (res) {
            if (res.success) {
              _this2.$message({
                type: "success",
                message: "添加成功",
                center: true
              });
              _this2.$refs[formName].resetFields();
              _this2.empty();
              _this2.dialogFormVisible = false;
            } else {
              _this2.$message({
                type: "warning",
                message: res.errorMsg,
                center: true
              });
              _this2.isRepeat = false;
              _this2.isDisable = false;
            }
          });
        } else {
          // console.log("error submit!!");
          // return false;
        }
      });
    },
    //修改
    edit: function edit() {
      if (this.currentRow == null) {
        this.$message({
          type: "warning",
          message: "请选择一条数据进行修改",
          center: true
        });
        return;
      }
      this.dialogVisible = true;
      this.isDisable = false;
      this.updateData.typeId = this.updateRowData.typeId;
      this.updateData.valueCn = this.updateRowData.valueCn;
      this.updateData.valueEn = this.updateRowData.valueEn;
      this.updateData.valueJa = this.updateRowData.valueJa;
    },
    update: function update(formName) {
      var _this3 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this3.isRepeat = true;
          _this3.isDisable = true;
          (0, _feedback.updateFeedbackType)({
            typeId: _this3.updateData.typeId,
            valueCn: _this3.updateData.valueCn,
            valueEn: _this3.updateData.valueEn,
            valueJa: _this3.updateData.valueJa
          }).then(function (res) {
            _this3.dialogVisible = false;
            if (res.success) {
              _this3.$message({
                type: "success",
                message: "修改成功",
                center: true
              });
              _this3.updateRowData.typeId = _this3.updateData.typeId;
              _this3.updateRowData.valueCn = _this3.updateData.valueCn;
              _this3.updateRowData.valueEn = _this3.updateData.valueEn;
              _this3.updateRowData.valueJa = _this3.updateData.valueJa;
              // 获取当前日期时间
              (0, _feedback.dataManipulation)(_this3.tableData, _this3.rowData, _this3.updateData.valueCn, _this3.updateData.valueEn, _this3.updateData.valueJa, getDate());
            } else {
              _this3.$message({
                type: "success",
                message: res.errorMsg,
                center: true
              });
              _this3.isRepeat = false;
              _this3.isDisable = false;
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //查询
    search: function search() {
      var _this4 = this;
      this.loading = true;
      setTimeout(function () {
        (0, _feedback.getFeedbackTypeList)({
          pageNo: 1,
          pageSize: 10,
          typeId: _this4.form.typeId
        }).then(function (res) {
          _this4.tableData = res.voList;
          _this4.total = res.total;
          _this4.currentPage = 1;
          _this4.loading = false;
        });
      }, 500);
    },
    //清空
    empty: function empty() {
      this.form.typeId = "";
      this.getList();
    },
    //取消
    cancel: function cancel(formName) {
      this.$refs[formName].resetFields();
      this.dialogFormVisible = false;
      this.dialogVisible = false;
    },
    //获取页码
    handleCurrentChange: function handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.form.pageNo = currentPage;
      this.getList();
    },
    //行选择
    currentChange: function currentChange(val) {
      this.currentRow = val;
    },
    //点击获取这行数据
    getRow: function getRow(row) {
      this.rowData = row;
      this.rowid = row.typeId;
      this.updateRowData.typeId = row.typeId;
      this.updateRowData.valueCn = row.valueCn;
      this.updateRowData.valueEn = row.valueEn;
      this.updateRowData.valueJa = row.valueJa;
    },
    //删除
    delet: function delet() {
      var _this5 = this;
      if (this.currentRow == null) {
        this.$message({
          type: "warning",
          message: "请选择一条数据进行删除",
          center: true
        });
        return;
      }
      this.$confirm("此操作将永久删除该数据, 是否继续?", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        _this5.isRepeat = true;
        (0, _feedback.deletFeedbackType)(_this5.rowid).then(function (res) {
          _this5.$message({
            type: "success",
            message: "删除成功!"
          });
          _this5.getList();
        });
      }).catch(function () {
        _this5.$message({
          type: "info",
          message: "已取消删除"
        });
        _this5.isRepeat = false;
      });
    },
    indexMethod: function indexMethod(index) {
      return index + 1;
    },
    handleClick: function handleClick(row) {
      console.log(row);
    }
  }
};
/**
 * 获取当前时间
 */
function getDate() {
  var date = new Date();
  var seperator1 = "-";
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var strDate = date.getDate();
  var hours = date.getHours(); //获取当前小时数(0-23)
  var minutes = date.getMinutes(); //获取当前分钟数(0-59)
  var seconds = date.getSeconds();
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = "0" + strDate;
  }
  var currentdate = year + seperator1 + month + seperator1 + strDate + " " + hours + ":" + minutes + ":" + seconds;
  return currentdate;
}