var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "appupload" },
    [
      _c("div", { staticClass: "appupload-head" }, [
        _c("ul", [
          _c(
            "li",
            [
              _c("h5", [_vm._v("上下线")]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.param.line,
                    callback: function ($$v) {
                      _vm.$set(_vm.param, "line", $$v)
                    },
                    expression: "param.line",
                  },
                },
                _vm._l(_vm.lines, function (item) {
                  return _c("el-option", {
                    key: item.line,
                    attrs: { label: item.label, value: item.line },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("版本号")]),
              _vm._v(" "),
              _c("el-input", {
                model: {
                  value: _vm.param.version,
                  callback: function ($$v) {
                    _vm.$set(_vm.param, "version", $$v)
                  },
                  expression: "param.version",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.search } },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.empty } },
                [_vm._v("清空")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "function" }, [
        _c("ol", [
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/android/store/upload",
                  expression: "'/android/store/upload'",
                },
              ],
              on: { click: _vm.upload },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/upload.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("上传")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/android/store/delete",
                  expression: "'/android/store/delete'",
                },
              ],
              on: { click: _vm.del },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/delete.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("删除")]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tab" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                border: "",
                "highlight-current-row": "",
              },
              on: {
                "row-click": _vm.getRow,
                "current-change": _vm.handleClick,
              },
            },
            [
              _c("el-table-column", {
                attrs: { type: "index", index: _vm.indexMethod },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "name", label: "应用名称" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "equipmentModel",
                  label: "设备型号",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "equipmentPurpose",
                  label: "设备用途",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "firmwareVersion",
                  label: "固件版本号",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "packageName", label: "包名", width: "200px" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "versionNo", label: "内部版本号" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "version", label: "版本号", width: "120px" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "language", label: "适配语言", width: "120px" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "line",
                  label: "上/下线",
                  formatter: _vm.line,
                  width: "100px",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", { attrs: { prop: "size", label: "大小" } }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "url",
                  label: "存储地址",
                  "show-overflow-tooltip": "",
                  width: "120px",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "opUser", label: "操作员", width: "120px" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "opTime",
                  label: "操作时间",
                  "show-overflow-tooltip": "",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "function-icon" },
                          [
                            _c(
                              "el-tooltip",
                              {
                                directives: [
                                  {
                                    name: "permit",
                                    rawName: "v-permit",
                                    value: "/android/store/update",
                                    expression: "'/android/store/update'",
                                  },
                                ],
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "修改",
                                  placement: "bottom",
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("../../assets/iconfont/amend.png"),
                                    alt: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.change(scope.row)
                                    },
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-tooltip",
                              {
                                directives: [
                                  {
                                    name: "permit",
                                    rawName: "v-permit",
                                    value: "/android/store/online",
                                    expression: "'/android/store/online'",
                                  },
                                ],
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "上线",
                                  placement: "bottom",
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("../../assets/iconfont/online.png"),
                                    alt: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.online(scope.row)
                                    },
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-tooltip",
                              {
                                directives: [
                                  {
                                    name: "permit",
                                    rawName: "v-permit",
                                    value: "/android/store/unline",
                                    expression: "'/android/store/unline'",
                                  },
                                ],
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "下线",
                                  placement: "bottom",
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("../../assets/iconfont/offline.png"),
                                    alt: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.offline(scope.row)
                                    },
                                  },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, prev, pager, next",
              "page-size": 10,
              total: _vm.total,
              "current-page": _vm.currentPage,
            },
            on: { "current-change": _vm.changepage },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            title: "上传",
            visible: _vm.dialogFormVisible,
            "close-on-click-modal": false,
            "status-icon": true,
            "show-message": false,
            "hide-required-asterisk": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
            close: _vm.uploadClose,
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "uploads",
              attrs: {
                "status-icon": true,
                "show-message": false,
                "hide-required-asterisk": "",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "语言", "label-width": _vm.formLabelWidth } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      attrs: { width: "50%" },
                      model: {
                        value: _vm.form.languageList,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "languageList", $$v)
                        },
                        expression: "form.languageList",
                      },
                    },
                    _vm._l(_vm.type, function (item, i) {
                      return _c(
                        "el-checkbox",
                        {
                          key: i,
                          staticStyle: { width: "200px", height: "30px" },
                          attrs: { label: item.valueCn, name: "type" },
                        },
                        [_vm._v(_vm._s(item.valueCn))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "设备型号",
                    "label-width": _vm.formLabelWidth,
                    prop: "equipmentModelList",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        filterable: "",
                        multiple: "",
                        placeholder: "请选择",
                      },
                      model: {
                        value: _vm.form.equipmentModelList,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "equipmentModelList", $$v)
                        },
                        expression: "form.equipmentModelList",
                      },
                    },
                    _vm._l(_vm.equipmentModelList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "设备用途",
                    "label-width": _vm.formLabelWidth,
                    prop: "equipmentPurposeList",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        filterable: "",
                        multiple: "",
                        placeholder: "请选择",
                      },
                      model: {
                        value: _vm.form.equipmentPurposeList,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "equipmentPurposeList", $$v)
                        },
                        expression: "form.equipmentPurposeList",
                      },
                    },
                    _vm._l(_vm.equipmentPurposeList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "固件版本号",
                    "label-width": _vm.formLabelWidth,
                    prop: "firmwareVersion",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        filterable: "",
                        multiple: "",
                        placeholder: "请选择",
                      },
                      model: {
                        value: _vm.form.firmwareVersion,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "firmwareVersion", $$v)
                        },
                        expression: "form.firmwareVersion",
                      },
                    },
                    _vm._l(_vm.firmwareVersion, function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "上传icon",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "el-upload",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.uploadloading,
                          expression: "uploadloading",
                        },
                      ],
                      staticClass: "avatar-uploader",
                      attrs: {
                        action: "https://jsonplaceholder.typicode.com/posts/",
                        "show-file-list": false,
                        "before-upload": _vm.beforeUploadPicture1,
                      },
                    },
                    [
                      _vm.imageUrl1
                        ? _c("img", {
                            staticClass: "avatar",
                            attrs: { src: _vm.imageUrl1 },
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon",
                          }),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "上传", "label-width": _vm.formLabelWidth } },
                [
                  _c(
                    "div",
                    { staticClass: "upload-wrapper" },
                    [
                      _c("input", {
                        ref: "uploadFile",
                        staticClass: "upload-file",
                        attrs: {
                          id: "file",
                          type: "file",
                          name: "file",
                          multiple: "",
                        },
                        on: { change: _vm.submitUpload },
                      }),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticClass: "exprot-btn",
                          attrs: {
                            size: "small",
                            type: "primary",
                            disabled: _vm.disab,
                          },
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.buttonUpload))])]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.show
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "应用名称",
                            "label-width": _vm.formLabelWidth,
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { autocomplete: "off" },
                            model: {
                              value: _vm.form.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "name", $$v)
                              },
                              expression: "form.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "包名",
                            "label-width": _vm.formLabelWidth,
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { autocomplete: "off" },
                            model: {
                              value: _vm.form.packageName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "packageName", $$v)
                              },
                              expression: "form.packageName",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "版本号",
                            "label-width": _vm.formLabelWidth,
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { autocomplete: "off" },
                            model: {
                              value: _vm.form.version,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "version", $$v)
                              },
                              expression: "form.version",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "内部比对版本号",
                            "label-width": _vm.formLabelWidth,
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { autocomplete: "off" },
                            model: {
                              value: _vm.form.versionNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "versionNo", $$v)
                              },
                              expression: "form.versionNo",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "footer-container" }, [
            _c(
              "div",
              {
                staticClass: "progress",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c("el-progress", {
                  attrs: {
                    "text-inside": true,
                    "stroke-width": 15,
                    percentage: _vm.progress,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "progress-button",
                    attrs: { type: "primary", disabled: _vm.savedisab },
                    on: { click: _vm.save },
                  },
                  [
                    _vm._v(
                      "\n          " + _vm._s(_vm.saveButton) + "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("el-button", { on: { click: _vm.uploadClose } }, [
                  _vm._v("取 消"),
                ]),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          ref: "changeform",
          attrs: {
            model: _vm.changeform,
            title: "修改",
            visible: _vm.dialogFormVisibles,
            "close-on-click-modal": false,
            "status-icon": true,
            "show-message": false,
            "hide-required-asterisk": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisibles = $event
            },
            close: _vm.changeClose,
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "uploads",
              attrs: {
                "status-icon": true,
                "show-message": false,
                "hide-required-asterisk": "",
              },
            },
            [
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "40%" },
                      attrs: {
                        label: "应用",
                        "label-width": _vm.formLabelWidth,
                      },
                    },
                    [_vm._v("\n          " + _vm._s(_vm.name) + "\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "40%" },
                      attrs: {
                        label: "版本号",
                        "label-width": _vm.formLabelWidth,
                      },
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(_vm.version) + "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "语言", "label-width": _vm.formLabelWidth } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      attrs: { width: "50%" },
                      model: {
                        value: _vm.changeform.language,
                        callback: function ($$v) {
                          _vm.$set(_vm.changeform, "language", $$v)
                        },
                        expression: "changeform.language",
                      },
                    },
                    _vm._l(_vm.type, function (item, i) {
                      return _c(
                        "el-checkbox",
                        {
                          key: i,
                          staticStyle: { width: "200px", height: "30px" },
                          attrs: { label: item.valueCn, name: "type" },
                        },
                        [_vm._v(_vm._s(item.valueCn))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "设备型号",
                    "label-width": _vm.formLabelWidth,
                    prop: "equipmentModelList",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        filterable: "",
                        multiple: "",
                        placeholder: "请选择",
                      },
                      model: {
                        value: _vm.changeform.equipmentModel,
                        callback: function ($$v) {
                          _vm.$set(_vm.changeform, "equipmentModel", $$v)
                        },
                        expression: "changeform.equipmentModel",
                      },
                    },
                    _vm._l(_vm.equipmentModelList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "设备用途",
                    "label-width": _vm.formLabelWidth,
                    prop: "equipmentPurposeList",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        filterable: "",
                        multiple: "",
                        placeholder: "请选择",
                      },
                      model: {
                        value: _vm.changeform.equipmentPurpose,
                        callback: function ($$v) {
                          _vm.$set(_vm.changeform, "equipmentPurpose", $$v)
                        },
                        expression: "changeform.equipmentPurpose",
                      },
                    },
                    _vm._l(_vm.equipmentPurposeList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "固件版本号",
                    "label-width": _vm.formLabelWidth,
                    prop: "firmwareVersion",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        filterable: "",
                        multiple: "",
                        placeholder: "请选择",
                      },
                      model: {
                        value: _vm.changeform.firmwareVersion,
                        callback: function ($$v) {
                          _vm.$set(_vm.changeform, "firmwareVersion", $$v)
                        },
                        expression: "changeform.firmwareVersion",
                      },
                    },
                    _vm._l(_vm.firmwareVersion, function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.changesave } },
                [_vm._v("保存")]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.changeClose } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }