"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _return = require("../../api/return.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      isDisable: false,
      value: "",
      value1: "",
      value2: "",
      loading: false,
      isRepeat: false,
      health: [{
        value: "0",
        label: "正常"
      }, {
        value: "1",
        label: "维修中"
      }, {
        value: "2",
        label: "报废"
      }],
      option: [{
        value: 1,
        label: "退货"
      }, {
        value: 2,
        label: "换货"
      }],
      tableData: [],
      multipleSelection: [],
      form: {
        endTime: "",
        equipmentNumber: "",
        healthyState: "",
        pageNo: 1,
        pageSize: 10,
        startTime: "",
        type: ""
      },
      formLabelWidth: "100px",
      //退货
      dialogFormsVisible: false,
      returns: {
        equipmentNumber: "",
        healthyState: "",
        businessHandler: "",
        remark: "",
        returnTime: "",
        serrialNumber: ""
      },
      returnRules: {
        equipmentNumber: [{
          required: true,
          trigger: "blur"
        }],
        healthyState: [{
          required: true,
          trigger: "blur"
        }],
        businessHandler: [{
          required: true,
          trigger: "blur"
        }],
        returnTime: [{
          required: true,
          trigger: "blur"
        }],
        serrialNumber: [{
          required: true,
          trigger: "blur"
        }]
      },
      //换货
      dialogFormVisible: false,
      exchange: {
        businessHandler: "",
        equipmentNumber: "",
        exchangeEquipmentNumber: "",
        healthyState: "",
        remark: "",
        returnTime: "",
        serrialNumber: ""
      },
      exchangeRules: {
        equipmentNumber: [{
          required: true,
          trigger: "blur"
        }],
        healthyState: [{
          required: true,
          trigger: "blur"
        }],
        businessHandler: [{
          required: true,
          trigger: "blur"
        }],
        returnTime: [{
          required: true,
          trigger: "blur"
        }],
        serrialNumber: [{
          required: true,
          trigger: "blur"
        }],
        exchangeEquipmentNumber: [{
          required: true,
          trigger: "blur"
        }]
      },
      total: 0,
      currentPage: 1
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.loading = true;
      setTimeout(function () {
        (0, _return.getExchange)(_this.form).then(function (res) {
          _this.tableData = res.voList;
          _this.total = res.total;
          _this.loading = false;
          _this.isRepeat = false;
        });
      }, 500);
    },
    //分页
    changepage: function changepage(currentPage) {
      this.currentPage = currentPage;
      this.form.pageNo = currentPage;
      this.getList();
    },
    handleClick: function handleClick(val) {
      this.currentrow = val;
    },
    stateFormat: function stateFormat(row) {
      if (row.type == "1") {
        return "退货";
      } else if (row.type == "2") {
        return "换货";
      }
    },
    //信息提示框
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    },
    //查询
    search: function search() {
      var _this2 = this;
      this.loading = true;
      setTimeout(function () {
        (0, _return.getExchange)({
          endTime: _this2.form.endTime,
          equipmentNumber: _this2.form.equipmentNumber,
          healthyState: _this2.form.healthyState,
          pageNo: 1,
          pageSize: 10,
          startTime: _this2.form.startTime,
          type: _this2.form.type
        }).then(function (res) {
          _this2.tableData = res.voList;
          _this2.total = Number(res.total);
          _this2.currentPage = 1;
          _this2.loading = false;
        });
      }, 500);
    },
    //清空
    empty: function empty() {
      this.form.equipmentNumber = "";
      this.form.healthyState = "";
      this.form.type = "";
      this.form.startTime = "";
      this.form.endTime = "";
      this.getList();
    },
    //退货
    salesReturn: function salesReturn() {
      this.returns.equipmentNumber = "";
      this.returns.healthyState = "";
      this.returns.businessHandler = "";
      this.returns.remark = "";
      this.returns.returnTime = "";
      this.returns.serrialNumber = "";
      this.dialogFormsVisible = true;
      this.isDisable = false;
      //this.returns.equipmentNumber = this.currentrow.equipmentNumber;
    },
    save: function save() {
      var _this3 = this;
      this.$refs.returns.validate(function (valid) {
        if (valid) {
          // 表单验证通过之后的操作
          _this3.isRepeat = true;
          _this3.isDisable = true;
          (0, _return.getReturn)(_this3.returns).then(function (res) {
            if (res.success) {
              _this3.msgTip("success", "退货成功");
              _this3.dialogFormsVisible = false;
              _this3.empty();
              _this3.$refs.returns.resetFields();
            } else {
              _this3.msgTip("error", res.errorMsg);
              _this3.isRepeat = false;
              _this3.isDisable = false;
            }
          });
        } else {
          _this3.msgTip("warning", "请填写完整");
          return false;
        }
      });
    },
    close: function close() {
      this.dialogFormsVisible = false;
      this.dialogFormVisible = false;
      this.$refs.returns.resetFields();
    },
    //换货
    barter: function barter() {
      this.exchange.businessHandler = "";
      this.exchange.equipmentNumber = "";
      this.exchange.exchangeEquipmentNumber = "";
      this.exchange.healthyState = "";
      this.exchange.remark = "";
      this.exchange.returnTime = "";
      this.exchange.serrialNumber = "";
      this.dialogFormVisible = true;
      this.isDisable = false;
      this.returns.equipmentNumber = this.currentrow.equipmentNumber;
    },
    keep: function keep() {
      var _this4 = this;
      this.$refs.exchange.validate(function (valid) {
        if (valid) {
          // 表单验证通过之后的操作
          _this4.isRepeat = true;
          _this4.isDisable = true;
          (0, _return.getBarter)(_this4.exchange).then(function (res) {
            if (res.success) {
              _this4.msgTip("success", "换货成功");
              _this4.dialogFormVisible = false;
              _this4.empty();
              _this4.$refs.returns.resetFields();
            } else {
              _this4.msgTip("error", res.errorMsg);
              _this4.isRepeat = false;
              _this4.isDisable = false;
            }
          });
        } else {
          _this4.msgTip("warning", "请填写完整");
          return false;
        }
      });
    },
    indexMethod: function indexMethod(index) {
      return index + 1;
    }
  }
};