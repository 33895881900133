"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _inoutrecord = require("../../api/inoutrecord.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      isDisable: false,
      value: "",
      loading: false,
      tableData: [],
      form: {
        equipmentNumber: "",
        pageNo: 1,
        pageSize: 10,
        type: ""
      },
      types: [{
        value: "1",
        label: "入库"
      }, {
        value: "2",
        label: "出库"
      }, {
        value: "3",
        label: "撤销"
      }, {
        value: "4",
        label: "退货"
      }],
      currentPage: 1,
      total: 0
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.loading = true;
      setTimeout(function () {
        (0, _inoutrecord.getinout)(_this.form).then(function (res) {
          _this.tableData = res.voList;
          _this.total = res.total;
          _this.loading = false;
          _this.isRepeat = false;
        });
      }, 500);
    },
    //分页
    changepage: function changepage(currentPage) {
      this.currentPage = currentPage;
      this.form.pageNo = currentPage;
      this.getList();
    },
    //查询
    search: function search() {
      var _this2 = this;
      this.loading = true;
      setTimeout(function () {
        (0, _inoutrecord.getinout)({
          equipmentNumber: _this2.form.equipmentNumber,
          pageNo: 1,
          pageSize: 10,
          type: _this2.form.type
        }).then(function (res) {
          _this2.tableData = res.voList;
          _this2.total = res.total;
          _this2.currentPage = 1;
          _this2.loading = false;
          _this2.isRepeat = false;
        });
      }, 500);
    },
    //清空
    empty: function empty() {
      this.form.equipmentNumber = "";
      this.form.type = "";
      this.currentPage = 1;
      this.getList();
    },
    //判断类型
    equimentType: function equimentType(row) {
      if (row.type == "1") {
        return '入库';
      } else if (row.type == "2") {
        return '出库';
      } else if (row.type == '3') {
        return '撤销';
      } else if (row.type == '4') {
        return '退货';
      }
    },
    indexMethod: function indexMethod(index) {
      return index + 1;
    },
    handleClick: function handleClick(row) {
      console.log(row);
    }
  }
};