"use strict";

var _interopRequireWildcard = require("/data/www/prod-cn-snote-manage/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
var Quill = _interopRequireWildcard(require("quill"));
var _apprepair = require("../../api/apprepair.js");
var _uploads = require("../../api/uploads.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      updateData: {
        appName: "",
        appVersion: "",
        fixPoint: "",
        lan: ""
      },
      id: '',
      formLabelWidth: '120px',
      rulesadd: {
        lan: [{
          required: true,
          trigger: "change"
        }],
        appName: [{
          required: true,
          trigger: "change"
        }],
        appVersion: [{
          required: true,
          trigger: "change"
        }],
        fixPoint: [{
          required: true,
          trigger: "blur"
        }]
      },
      editorOption: {
        placeholder: "请输入文本信息...",
        theme: "snow",
        modules: {
          toolbar: [["bold", "italic", "underline", "strike"],
          //加粗，斜体，下划线，删除线
          ["blockquote", "code-block"],
          //引用，代码块
          [{
            header: 1
          }, {
            header: 2
          }],
          // 标题，键值对的形式；1、2表示字体大小
          [{
            list: "ordered"
          }, {
            list: "bullet"
          }],
          //列表
          [{
            script: "sub"
          }, {
            script: "super"
          }],
          // 上下标
          [{
            indent: "-1"
          }, {
            indent: "+1"
          }],
          // 缩进
          [{
            direction: "rtl"
          }],
          // 文本方向
          [{
            size: ["small", false, "large", "huge"]
          }],
          // 字体大小
          [{
            header: [1, 2, 3, 4, 5, 6, false]
          }],
          //几级标题
          [{
            color: []
          }, {
            background: []
          }],
          // 字体颜色，字体背景颜色
          [{
            font: []
          }],
          //字体
          [{
            align: []
          }],
          //对齐方式
          ["image"] //清除字体样式 //['image','video']        //上传图片、上传视频
          ]
        }
      },
      addRange: [],
      Rangeflag: false,
      isDisable: false,
      isRepeat: false
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.id = this.$route.query.id;
    (0, _apprepair.searchAppFixpoint)(this.id).then(function (res) {
      _this.updateData = res.voT;
    });
    this.$refs.myQuillEditor.quill.getModule("toolbar").addHandler("image", this.imgHandler);
  },
  methods: {
    imgHandler: function imgHandler(state) {
      this.addRange = this.$refs.myQuillEditor.quill.getSelection();
      if (this.addRange != "" && this.addRange != null) {
        this.Rangeflag = true;
      }
      if (state) {
        var fileInput = document.getElementById("imgInput");
        fileInput.click(); // 加一个触发事件
      }
    },
    to_upload_img: function to_upload_img(formdata) {
      var _this2 = this;
      return new Promise(function (resolve, reject) {
        var fd = new FormData();
        fd.append("file", formdata.file);
        (0, _uploads.uploads)(fd).then(function (res) {
          if (_this2.Rangeflag) {
            _this2.addRange = _this2.$refs.myQuillEditor.quill.getSelection();
            _this2.$refs.myQuillEditor.quill.insertEmbed(_this2.addRange !== null ? _this2.addRange.index : 0, "image", res.voT.downloadUrl, Quill.sources.USER); // 调用编辑器的 insertEmbed 方法，插入URL
            _this2.Rangeflag = false;
          }
        });
      });
    },
    submit: function submit() {
      var _this3 = this;
      if (this.updateData.fixPoint == "" || this.updateData.fixPoint == null) {
        this.updateData.fixPoint = '<p><strong style="color: rgb(230, 0, 0);"><em>请输入修复点信息(不可为空)</em></strong></p>';
        return false;
      }
      this.isRepeat = true;
      this.isDisable = true;
      (0, _apprepair.updateAppFixpoint)(this.updateData, this.id).then(function (res) {
        if (res.success) {
          _this3.$message({
            type: "success",
            message: "修改成功",
            center: true
          });
          _this3.$store.dispatch("tagsView/delView", _this3.$route).then(function () {
            _this3.$nextTick(function () {
              _this3.$router.replace({
                path: "/apply/apprepair"
              });
            });
          });
        } else {
          _this3.$message({
            type: "warning",
            message: res.errorMsg,
            center: true
          });
          _this3.isRepeat = false;
          _this3.isDisable = false;
        }
      });
    }
  }
};