"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-manage/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.directive = directive;
require("core-js/modules/es6.array.find-index");
var _vue = _interopRequireDefault(require("vue"));
var _store = _interopRequireDefault(require("../store"));
/**
 * @export 自定义指令
 */
function directive() {
  _vue.default.directive('permit', {
    bind: function bind(el, binding) {
      _store.default.state.user.permission.findIndex(function (v) {
        return v.attributes.url === binding.value;
      }) !== -1 ? {} : el.style.display = 'none';
    }
  });
}